import AddIcon from '@mui/icons-material/Add';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box, Stack } from '@mui/system';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomDatePicker from '../../components/CustomDatePicker';
import { SelectBoxLabel } from '../../components/InputTypeText/SelectBoxLabel';
import TextFieldInput from '../../components/InputTypeText/TextField';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import TextAreaBox from '../../components/universal/InputTypeText/TextareaBox';
import Share from '../../components/universal/Share';
import { CVBuilderDropDown, globalWindow, notifyMessages } from '../../core/constant';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import { ErrorMessage, removeEmptyFields, replaceNullWithEmptyString } from './helperFunctions';
import { validationSchema } from './validationCVschema';
import DynamicTable from '../../components/CvBuilder/CvBuilderTable';
// Mock dropdown options - you can replace this with actual options from your API

const awardColumns = [
  { field: 'awardName', label: 'Award/Grant Name', type: 'text' },
  { field: 'awardOrganisation', label: 'Organisation', type: 'text' },
  { field: 'awardYear', label: 'Year Received', type: 'text' },
];
const pressColumn = [
  { field: 'pressTitle', label: 'Title', type: 'text' },
  { field: 'pressPublisher', label: 'Publisher', type: 'text' },
  { field: 'pressYear', label: 'Year', type: 'text' },
  { field: 'pressPublicationLink', label: 'Publication Link', type: 'text' },
];
const educationColumn = [
  { field: 'educationInstitution', label: 'Institution', type: 'text' },
  { field: 'educationLevelOfStudy', label: 'Level of Study', type: 'dropdown' },
  { field: 'educationYear', label: 'Year Completed', type: 'text' },
];
const commissionColumn = [
  { field: 'commissionProjectTitle', label: 'Project Title', type: 'text' },
  { field: 'commissionLocation', label: 'Location', type: 'text' },
  { field: 'commissionYear', label: 'Year', type: 'text' },
  { field: 'commissionLink', label: 'Commisson Link', type: 'text' },
];
const memberAffiliatesColumn = [
  { field: 'memberAffiliateOrganisationName', label: 'Organisation Name', type: 'text' },
  { field: 'memberAffiliateRole', label: 'Role (e.g., Member, Fellow, Advisory Board)', type: 'text' },
  { field: 'memberAffiliateYear', label: 'Year Joined', type: 'text' },
];
const exhibitionColumn = [
  { field: 'exhibitionTitle', label: 'Exhibition Title', type: 'text' },
  { field: 'exhibitionType', label: 'Exhibition Type', type: 'dropdown' },
  { field: 'exhibitionVenue', label: 'Venue', type: 'text' },
  { field: 'exhibitionLocation', label: 'Location', type: 'text' },
  { field: 'exhibitionYear', label: 'Year', type: 'text' },
  { field: 'exhibitionLink', label: 'Exhibition Link', type: 'text' },
];
const otherExperiencesColumn = [
  { field: 'otherExperienceTitle', label: 'Title of Experience', type: 'text' },
  { field: 'otherExperienceDescription', label: 'Experience Description ', type: 'text' },
  { field: 'otherExperienceOrganisation', label: 'Organisation ', type: 'text' },
  { field: 'otherExperienceYear', label: 'Year', type: 'text' },
  { field: 'otherExperienceLink', label: 'Link', type: 'text' },
];
const residencyColumn = [
  { field: 'residencyName', label: 'Residency Name', type: 'text' },
  { field: 'residencyOrganisation', label: 'Organisation', type: 'text' },
  { field: 'residencyYear', label: 'Year', type: 'text' },
];
const NotableCollectorColumn = [
  { field: 'notableCollectorName', label: 'Collector Name', type: 'text' },
  { field: 'notableDateAcquired', label: 'Date Acquired', type: 'text' },
  { field: 'notableCollectionLink', label: 'Collection Link', type: 'text' },
];

const CvBuilder = () => {
  const [loading, setLoading] = useState({ initial: false, submit: false });
  const {
    userDetails: { token, user },
  } = useSelector((state) => state.user);
  const artistName = user?.v_artist_name || `${user?.v_fname}-${user?.v_lname}`;

  const [CVDetails, setCVDetails] = useState({});
  const [dropdownValues, setDropdownValues] = useState(CVBuilderDropDown);

  const [isAddingNew, setIsAddingNew] = useState(false); // Track add new mode

  const getCvBuilderData = async () => {
    try {
      setLoading({ initial: true, submit: false });
      const res = await request({
        url: '/artist/cvBuilder',
        method: 'GET',
        token,
      });
      if (res?.statusCode === 200) {
        // Transform education data before setting CVDetails
        const transformedData = {
          ...res.data,
          educations: res.data.educations?.map((edu) => ({
            ...edu,
            educationInstitution: edu.educationInstitution || '',
            educationLevelOfStudy: edu.educationLevelOfStudy || '',
            educationYear: edu.educationYear || '',
          })),
        };
        setCVDetails(replaceNullWithEmptyString(transformedData));
      } else {
        notify(res.message, 'error');
      }
    } catch (error) {
      notify(error.message, 'error');

      console.log('ðŸš€ ~ file: CvBuilder.js:16 ~ getCvBuilderData ~ error:', error);
    } finally {
      setLoading({ initial: false, submit: false });
    }
  };
  const getDropDownValues = async (data) => {
    try {
      setLoading({ initial: true, submit: false });

      const res = await request({
        url: '/artist/getCalcValues',
        method: 'GET',
        data,
        token,
      });
      if (res?.statusCode === 200) {
        setDropdownValues(res?.data);
      } else {
        notify(res.message, 'error');
      }
    } catch (error) {
      notify(error.message, 'error');

      console.log('ðŸš€ ~ file: CvBuilder.js:65 ~ getDropDownValues ~ error:', error);
    } finally {
      setLoading({ initial: false, submit: false });
    }
  };
  useEffect(() => {
    const init = async () => {
      await getCvBuilderData();
      await getDropDownValues();
    };
    init();
  }, []);
  const onPressDownload = () => {
    if (CVDetails?.csv_download_link) {
      window.open(CVDetails?.csv_download_link, '_blank');
    }
  };
  const onPreviewPress = () => {
    const sanitizedArtistName = artistName.replace(/\s+/g, '-');
    const url = `${process.env.REACT_APP_USER_APP_URL}artist/cv/${sanitizedArtistName}/${user?.id}`;

    globalWindow.open(url, '_blank');
  };

  // Helper function to create empty row based on column definition
  const createEmptyRow = (columns) => {
    const emptyRow = {};
    columns.forEach((col) => {
      emptyRow[col.field] = '';
    });
    emptyRow.isEdit = true;
    return emptyRow;
  };

  function getExperienceFromDOB(dateOfBirth) {
    const dob = new Date(dateOfBirth); // Parse the date_of_birth string into a Date object
    const currentDate = new Date(); // Get the current date

    // Calculate the current age based on the birth date and current date
    let age = currentDate.getFullYear() - dob.getFullYear();

    // Check if the current month/day is before the birthday this year
    const isBirthdayPassedThisYear =
      currentDate.getMonth() > dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() >= dob.getDate());

    // If the birthday hasn't passed this year, subtract 1 from the age
    if (!isBirthdayPassedThisYear) {
      age -= 1;
    }

    // Subtract 18 years to get the experience
    const experience = age - 18;

    // Ensure experience is never less than 1
    return experience > 0 ? experience : 1;
  }

  const totalExperience = user?.date_of_birth ? getExperienceFromDOB(user?.date_of_birth) : 1;

  const initialValues = {
    whenDidYouStart: totalExperience,
    areYouAFullTime: CVDetails?.areYouAFullTime?.id || '',
    howManySalesPerYear: CVDetails?.howManySalesPerYear?.id || '',

    education: CVDetails?.educations?.length
      ? CVDetails.educations.map((edu) => ({
          educationInstitution: edu.educationInstitution || '',
          educationLevelOfStudy: edu.educationLevelOfStudy || '',
          educationYear: edu.educationYear || '',
          id: edu.id,
          isEdit: false,
        }))
      : [createEmptyRow(educationColumn)],

    // Update all table initialValues to match column definitions
    awards: CVDetails?.awards?.length
      ? CVDetails.awards.map((award) => ({
          awardName: award.awardName || '',
          awardOrganisation: award.awardOrganisation || '',
          awardYear: award.awardYear || '',
          isEdit: false,
        }))
      : [createEmptyRow(awardColumns)],

    press: CVDetails?.presses?.length
      ? CVDetails.presses.map((press) => ({
          pressTitle: press.pressTitle || '',
          pressPublisher: press.pressPublisher || '',
          pressYear: press.pressYear || '',
          pressPublicationLink: press.pressPublicationLink || '',
          isEdit: false,
        }))
      : [createEmptyRow(pressColumn)],

    commissions: CVDetails?.commissions?.length
      ? CVDetails.commissions.map((commission) => ({
          commissionProjectTitle: commission.commissionProjectTitle || '',
          commissionLocation: commission.commissionLocation || '',
          commissionYear: commission.commissionYear || '',
          commissionLink: commission.commissionLink || '',
          isEdit: false,
        }))
      : [createEmptyRow(commissionColumn)],

    memberAffiliates: CVDetails?.memberAffiliates?.length
      ? CVDetails.memberAffiliates.map((member) => ({
          memberAffiliateOrganisationName: member.memberAffiliateOrganisationName || '',
          memberAffiliateRole: member.memberAffiliateRole || '',
          memberAffiliateYear: member.memberAffiliateYear || '',
          isEdit: false,
        }))
      : [createEmptyRow(memberAffiliatesColumn)],

    exhibitions: CVDetails?.exhibitions?.length
      ? CVDetails.exhibitions.map((exhibition) => ({
          exhibitionTitle: exhibition.exhibitionTitle || '',
          exhibitionVenue: exhibition.exhibitionVenue || '',
          exhibitionType: exhibition.exhibitionType || '', // Set default here
          exhibitionLocation: exhibition.exhibitionLocation || '',
          exhibitionYear: exhibition.exhibitionYear || '',
          exhibitionLink: exhibition.exhibitionLink || '',
          isEdit: false,
        }))
      : [createEmptyRow(exhibitionColumn)],

    otherExperiences: CVDetails?.otherExperiences?.length
      ? CVDetails.otherExperiences.map((exp) => ({
          otherExperienceTitle: exp.otherExperienceTitle || '',
          otherExperienceDescription: exp.otherExperienceDescription || '',
          otherExperienceOrganisation: exp.otherExperienceOrganisation || '',
          otherExperienceYear: exp.otherExperienceYear || '',
          otherExperienceLink: exp.otherExperienceLink || '',
          isEdit: false,
        }))
      : [createEmptyRow(otherExperiencesColumn)],

    residencies: CVDetails?.residencies?.length
      ? CVDetails.residencies.map((residency) => ({
          residencyName: residency.residencyName || '',
          residencyOrganisation: residency.residencyOrganisation || '',
          residencyYear: residency.residencyYear || '',
          isEdit: false,
        }))
      : [createEmptyRow(residencyColumn)],

    notableCollectors: CVDetails?.notableCollectors?.length
      ? CVDetails.notableCollectors.map((collector) => ({
          notableCollectorName: collector.notableCollectorName || '',
          notableDateAcquired: collector.notableDateAcquired || '',
          notableCollectionLink: collector.notableCollectionLink || '',
          isEdit: false,
        }))
      : [createEmptyRow(NotableCollectorColumn)],
  };

  const onSubmit = async (body) => {
    try {
      setLoading({ initial: false, submit: true });

      const transformedData = {
        whenDidYouStart: body.whenDidYouStart,
        areYouAFullTime: body.areYouAFullTime,
        howManySalesPerYear: body.howManySalesPerYear,

        // Transform all table data according to column definitions
        awards: values.awards?.map((row) => mapRowToFields(row, awardColumns)),
        educations: values.education?.map((row) => ({
          educationInstitution: row.educationInstitution,
          educationLevelOfStudy: row.educationLevelOfStudy,
          educationYear: row.educationYear,
        })),

        presses: values.press?.map((row) => mapRowToFields(row, pressColumn)),

        commissions: values.commissions?.map((row) => mapRowToFields(row, commissionColumn)),

        memberAffiliates: values.memberAffiliates?.map((row) => mapRowToFields(row, memberAffiliatesColumn)),

        exhibitions: values.exhibitions?.map((row) => mapRowToFields(row, exhibitionColumn)),

        otherExperiences: values.otherExperiences?.map((row) => mapRowToFields(row, otherExperiencesColumn)),

        residencies: values.residencies?.map((row) => mapRowToFields(row, residencyColumn)),

        notableCollectors: values.notableCollectors?.map((row) => mapRowToFields(row, NotableCollectorColumn)),
      };

      const res = await request({
        url: '/artist/cvBuilder',
        method: 'POST',
        token,
        data: removeEmptyFields(transformedData),
      });

      if (res?.statusCode === 200) {
        await getCvBuilderData();
        notify(res?.message, 'success');
      } else {
        notify(res.message, 'error');
      }
    } catch (error) {
      notify(error?.message, 'error');
    } finally {
      setLoading({ initial: false, submit: false });
    }
  };

  // Helper function to map row data to fields based on column definition
  const mapRowToFields = (row, columns) => {
    const mappedData = {};
    columns.forEach((col) => {
      mappedData[col.field] = row[col.field];
    });
    return mappedData;
  };

  const { values, errors, handleChange, handleSubmit, touched, setFieldValue, dirty, handleBlur, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    });
  const handleChangeTable = (index, field, value) => {
    setFieldValue(`awards[${index}].${field}`, value);
  };
  const isFormValid = useMemo(() => {
    // Check if education has at least one valid entry
    const hasValidEducation = values.education?.some(
      (edu) => edu.educationInstitution && edu.educationLevelOfStudy && edu.educationYear,
    );

    // Check if exhibitions has at least one valid entry
    const hasValidExhibition = values.exhibitions?.some(
      (exhibition) => exhibition.exhibitionTitle && exhibition.exhibitionVenue && exhibition.exhibitionType,
    );

    return hasValidEducation && hasValidExhibition;
  }, [values.education, values.exhibitions]);

  // Generalized Edit function
  function handleEdit(index, fieldName) {
    setValues((prevState) => {
      const updatedField = [...prevState[fieldName]];
      updatedField[index] = {
        ...updatedField[index],
        isEdit: !updatedField[index].isEdit,
      };
      return {
        ...prevState,
        [fieldName]: updatedField,
      };
    });
  }

  // Generalized Add New function
  const handleAddNew = (fieldName, defaultValues) => {
    setIsAddingNew(true);

    setValues((prevValues) => {
      // Ensure the field exists in prevValues and is an array
      const currentValues = prevValues[fieldName] || [];

      return {
        ...prevValues,
        [fieldName]: [
          ...currentValues,
          {
            isEdit: true,
            ...defaultValues,
          },
        ],
      };
    });
  };

  const handleDelete = (fieldName, index) => {
    const newValues = { ...values };
    newValues[fieldName] = newValues[fieldName].filter((_, i) => i !== index);
    setValues(newValues);
  };

  const checkLastItemHasValue = (key) => {
    const requiredFieldsMap = {
      press: ['pressArticleName', 'pressPublicationLink'],
      awards: ['awardTitle', 'awardDate', 'awardDetail'],
      residencies: ['residencyName', 'residencyDate', 'residencyLocation'],
      notableCollectors: ['notableCollectorsName', 'notableCollectorsInstitution'],
    };

    const requiredFields = requiredFieldsMap[key];
    if (!requiredFields) return false;

    const lastItem = values?.[key]?.at(-1);

    return requiredFields?.every((field) => lastItem?.[field] !== '');
  };

  const isProfileComplete = useMemo(() => {
    const { areYouAFullTime, whenDidYouStart, howManySalesPerYear, educations, exhibitions } = CVDetails;

    const hasNoEducation = !educations?.length;
    const hasNoExhibitions = !exhibitions?.length;

    return !whenDidYouStart || !areYouAFullTime?.id || !howManySalesPerYear?.id || hasNoEducation || hasNoExhibitions;
  }, [CVDetails]);
  return (
    <div className="statement_dashboard cv-builder">
      {loading?.initial && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading?.initial}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="cv_btn_section">
        <div className="font20px artist_statement_text">Your CV</div>
        <Stack
          className="button_section_new preview_btn_sec card-container gray_theme_update "
          justifyContent="right"
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <DarkBtn
            title="Preview"
            onClick={onPreviewPress}
            styleBtn={isProfileComplete ? 'disable_darkbtn' : ''}
            disabled={isProfileComplete}
          />
          <DarkBtn
            title="Download PDF"
            onClick={onPressDownload}
            styleBtn={isProfileComplete ? 'disable_darkbtn' : ''}
            disabled={isProfileComplete}
          />
          <Share
            url={`${process.env.REACT_APP_USER_APP_URL}artist/cv/${artistName}/${user?.id}`}
            title="Share Link"
            onClick={(handleOpen) => {
              if (!user?.id) {
                notify(notifyMessages?.completeProfileMsg, 'error');
              }
              handleOpen();
            }}
          />
        </Stack>
      </div>
      <div className="font16px short_text">
        After saving each section individually, be sure to click the final "Save" button at the bottom to apply all
        changes
      </div>
      <div className="font12px short_text">Basic Info</div>
      <p className="font10px short_text">
        Please complete all required fields marked with an asterisk (*).
        <br /> These details are not publicly visible but are essential for generating accurate results in tools like
        the Pricing Calculator and for providing tailored insights.
      </p>
      <Stack
        className="button_section_new card-container gray_theme_update"
        justifyContent="flex-start"
        spacing={2}
        gap={2}
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
      >
        {/* <Box className="box_field_width">
          <div className="font12px fulltime_text">When Did You Start Your Practice?</div>
          <TextFieldInput
            label=""
            name="totalexp"
            value={totalExperience}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly
          />
        </Box> */}
        <Box className="box_field_width">
          <div className="font12px fulltime_text">How Would You Describe Yourself?*</div>
          <SelectBoxLabel
            options={dropdownValues?.ft_pt_hobby?.values}
            onChange={handleChange}
            name="areYouAFullTime"
            value={values?.areYouAFullTime}
          />
          <ErrorMessage name="areYouAFullTime" errors={errors} touched={touched} />
        </Box>
        <Box className="box_field_width">
          <div className="font12px fulltime_text">Number of Sales Per Year?*</div>
          <SelectBoxLabel
            options={dropdownValues?.salesyr?.values}
            onChange={handleChange}
            name="howManySalesPerYear"
            value={values?.howManySalesPerYear}
          />
          <ErrorMessage name="howManySalesPerYear" errors={errors} touched={touched} />
        </Box>
      </Stack>
      {/* // Update the return JSX where education section starts */}
      <div className="font12px short_text">Education*</div>
      <DynamicTable
        columns={educationColumn}
        data={values.education}
        onChange={(index, field, value) => setFieldValue(`education[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'education')}
        onAddNew={() => handleAddNew('education', createEmptyRow(educationColumn))}
        onDelete={(index) => handleDelete('education', index)}
        errors={errors.education || []}
        touched={touched.education || []}
        handleBlur={handleBlur}
        keyData="education"
        dropdownOptions={dropdownValues?.education_level?.values}
      />
      <div className="font12px short_text">Exhibitions*</div>{' '}
      <DynamicTable
        columns={exhibitionColumn}
        data={values.exhibitions}
        onChange={(index, field, value) => setFieldValue(`exhibitions[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'exhibitions')}
        keyData="exhibitions"
        handleBlur={handleBlur}
        onDelete={(index) => handleDelete('exhibitions', index)}
        onAddNew={() =>
          handleAddNew('exhibitions', {
            exhibitionTitle: '',
            exhibitionVenue: '',
            exhibitionType: '',
            exhibitionLocation: '',
            exhibitionYear: '',
            exhibitionLink: '',
          })
        }
        dropdownOptions={dropdownValues?.exhibitions?.values}
        errors={errors.exhibitions}
        touched={touched.exhibitions}
      />
      <div className="font12px short_text">Awards & Grants</div>
      <DynamicTable
        columns={awardColumns}
        data={values.awards}
        onChange={handleChangeTable}
        onEdit={(index) => handleEdit(index, 'awards')}
        handleBlur={handleBlur}
        keyData="awards"
        onAddNew={() =>
          handleAddNew('awards', {
            awardName: '',
            awardOrganisation: '',
            awardYear: '',
          })
        }
        onDelete={(index) => handleDelete('awards', index)} // Add this line
        errors={errors.awards}
        touched={touched.awards}
      />
      <div className="font12px short_text">Residencies</div>
      <DynamicTable
        columns={residencyColumn}
        data={values.residencies}
        onChange={(index, field, value) => setFieldValue(`residencies[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'residencies')}
        keyData="residencies"
        handleBlur={handleBlur}
        onAddNew={() =>
          handleAddNew('residencies', {
            residenciesName: '',
            residenciesType: '',
            residenciesHost: '',
            residenciesStartDate: '',
            residenciesLocation: '',
          })
        }
        onDelete={(index) => handleDelete('residencies', index)} // Add this line
        errors={errors.residencies}
        touched={touched.residencies}
      />
      <div className="font12px short_text">Press & Publications</div>
      <DynamicTable
        columns={pressColumn}
        data={values.press}
        onChange={(index, field, value) => setFieldValue(`press[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'press')}
        keyData="press"
        handleBlur={handleBlur}
        onDelete={(index) => handleDelete('press', index)}
        onAddNew={() =>
          handleAddNew('press', {
            pressTitle: '',
            pressPublisher: '',
            pressYear: '',
            pressPublicationLink: '',
          })
        }
        errors={errors.press}
        touched={touched.press}
      />
      <div className="font12px short_text">Notable Collectors</div>
      <DynamicTable
        columns={NotableCollectorColumn}
        data={values.notableCollectors}
        onChange={(index, field, value) => setFieldValue(`notableCollectors[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'notableCollectors')}
        keyData="notableCollectors"
        handleBlur={handleBlur}
        onAddNew={() =>
          handleAddNew('notableCollectors', {
            notableCollectorName: '',
            notableCollectorType: '',
            notableCollectorArtwork: '',
            notableCollectorDateAcq: '',
            notableCollectorLocation: '',
          })
        }
        onDelete={(index) => handleDelete('notableCollectors', index)} // Add this line
        errors={errors.notableCollectors}
        touched={touched.notableCollectors}
      />
      <div className="font12px short_text">Commissions</div>
      <DynamicTable
        columns={commissionColumn}
        data={values.commissions}
        onChange={(index, field, value) => setFieldValue(`commissions[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'commissions')}
        keyData="commissions"
        handleBlur={handleBlur}
        onAddNew={() =>
          handleAddNew('commissions', {
            commissionProjectTitle: '',
            commissionLocation: '',
            commissionYear: '',
            commissionLink: '',
          })
        }
        onDelete={(index) => handleDelete('commissions', index)} // Add this line
        errors={errors.commissions}
        touched={touched.commissions}
      />
      <div className="font12px short_text">Memberships & Affiliations</div>
      <DynamicTable
        columns={memberAffiliatesColumn}
        data={values.memberAffiliates}
        onChange={(index, field, value) => setFieldValue(`memberAffiliates[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'memberAffiliates')}
        keyData="memberAffiliates"
        handleBlur={handleBlur}
        onAddNew={() =>
          handleAddNew('memberAffiliates', {
            memberAffiliateOrganisationName: '',
            memberAffiliateRole: '',
            memberAffiliateYear: '',
          })
        }
        onDelete={(index) => handleDelete('memberAffiliates', index)} // Add this line
        errors={errors.memberAffiliates}
        touched={touched.memberAffiliates}
      />
      <div className="font12px short_text">Other Experiences</div>
      <DynamicTable
        columns={otherExperiencesColumn}
        data={values.otherExperiences}
        onChange={(index, field, value) => setFieldValue(`otherExperiences[${index}].${field}`, value)}
        onEdit={(index) => handleEdit(index, 'otherExperiences')}
        keyData="otherExperiences"
        handleBlur={handleBlur}
        onAddNew={() =>
          handleAddNew('otherExperiences', {
            otherExperienceTitle: '',
            otherExperienceDescription: '',
            otherExperienceOrganisation: '',
            otherExperienceYear: '',
            otherExperienceLink: '',
          })
        }
        onDelete={(index) => handleDelete('otherExperiences', index)} // Add this line
        errors={errors.otherExperiences}
        touched={touched.otherExperiences}
      />
      <Box sx={{ my: 2 }}>
        <p className="font10px short_text">
          Note: You’ll need to fill out Basic Info, Education, and Exhibitions before you can save your progress.
        </p>
        <DarkBtn
          title="Save"
          onClick={handleSubmit}
          styleBtn={!dirty || !isFormValid ? 'disable_darkbtn' : ''}
          loading={loading?.submit}
          disabled={!isFormValid}
        />
      </Box>
    </div>
  );
};

export default CvBuilder;
