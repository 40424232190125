import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { Stack } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextFieldInput from '../../components/InputTypeText/TextField';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import UnderLineBtn from '../../components/universal/Buttons/UnderLineBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { nextStep } from '../dashboard';
import { GreenBtn } from '../../components/universal/Buttons/GreenBtn';
import { Loader } from '../../components/universal/Loader';
import { SimpleSelectBox } from '../../components/InputTypeText/SelectBox';
import OutlineBtn from '../../components/universal/Buttons/OutLineBtn';
import PersonalisedShippingTable from '../../scss/components/PersonalisedShippingTable';
import { request } from '../../services/request';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';

const DEFAULT_RETURNS_POLICY =
  'I will handle returns and refunds in accordance with applicable consumer protection laws. Please read the details below before making a purchase. Returns: If you change your mind, you have 14 days from receiving the artwork to request a return (except for custom-made or personalised pieces). The artwork must be in its original condition, and return shipping costs are the buyer’s responsibility. No Returns: I do not accept returns for custom-made or commissioned artworks unless they arrive damaged or are not as described. Damaged or Incorrect Items: If your artwork arrives damaged or is not as described, please contact me as soon as possible with photos so we can arrange a replacement, repair, or refund. Lost or Delayed Shipments: I will provide tracking details where possible. If your item is delayed, please check with the courier before contacting me. Cancellations: You may cancel your order before dispatch for a full refund. This policy is intended to comply with consumer protection laws in the UK and other applicable jurisdictions. Buyers outside the UK may have additional rights under their local consumer laws. If you have any concerns, please review your statutory rights before purchasing';

export const ShippingAddress = () => {
  const [isDisabled, setIsDisabled] = useState({
    shipping: true,
    mobile: true,
  });
  const [AccountStatusPoint, setAccountStatusPoint] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isAddressListUpdated, setIsAddressListUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveType, setSaveType] = useState('');
  // Add these state variables at the top with other useState declarations
  const [returnsPolicy, setReturnsPolicy] = useState('');
  const [selectedPolicyType, setSelectedPolicyType] = useState('template');
  // Add a new state to store the custom policy text
  const [customPolicyText, setCustomPolicyText] = useState('');
  // Add this state for shipping table rows
  const [shippingRows, setShippingRows] = useState([]);
  const [countries, setCountries] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const tableRef = useRef(null);

  // Add new state to track previous policy data
  const [previousPolicyData, setPreviousPolicyData] = useState({
    type: 'template',
    text: '',
  });

  // Add a state for form error
  const [policyError, setPolicyError] = useState('');

  const [hasUnsavedRow, setHasUnsavedRow] = useState(false);

  const handleAddNewShipping = () => {
    // Check if there's already an unsaved row
    if (hasUnsavedRow) {
      notify('Please save the current shipping option before adding a new one', 'error');
      return;
    }

    const newRow = {
      price: '',
      geography: '',
      editing: true, // Set editing to true by default for new records
    };
    setShippingRows([...shippingRows, newRow]);
    setHasUnsavedRow(true);
  };

  // 1. Add state to track items being deleted
  const [itemsToDelete, setItemsToDelete] = useState([]);

  // 2. Modify handleDelete function
  const handleDelete = () => {
    if (selectedRows && selectedRows.length > 0) {
      // Store the selected rows before opening dialog
      setItemsToDelete([...selectedRows]);
      setOpenDeleteDialog(true);
    } else {
      notify('Please select items to delete', 'error');
    }
  };

  // 3. Update getDeleteDialogMessage function to use itemsToDelete instead of selectedRows
  const getDeleteDialogMessage = () => {
    if (itemsToDelete.length === 1 || itemsToDelete.length === 0) {
      return 'Are you sure you want to delete the selected shipping option?';
    }
    return 'Are you sure you want to delete the selected shipping options?';
  };

  // 4. Update handleDeleteConfirm
  const handleDeleteConfirm = async () => {
    try {
      let hasAnySuccess = false;
      const results = await Promise.all(
        itemsToDelete.map(async (id) => {
          try {
            const result = await request({
              url: `/artist/personalisedShippingPrice/${id}`,
              method: 'DELETE',
              token: userDetails?.token,
            });
            if (result?.statusCode === 200) {
              hasAnySuccess = true;
            }
            return result;
          } catch (error) {
            return { statusCode: 500, error: error?.data?.message };
          }
        }),
      );

      const failed = results.filter((res) => res?.statusCode !== 200);

      // Show appropriate notification
      if (failed.length === itemsToDelete.length) {
        notify('Failed to delete selected items', 'error');
      } else if (failed.length > 0) {
        notify(`${failed.length} item(s) failed to delete`, 'error');
      } else {
        notify('Successfully deleted', 'success');
      }

      // Clear states
      setSelectedRows([]);
      setItemsToDelete([]);
      setOpenDeleteDialog(false);

      // Refresh data if at least one item was deleted successfully
      if (hasAnySuccess) {
        await getPersonaliseShipping();
      }
    } catch (error) {
      notify(error?.data?.message, 'error');
      setOpenDeleteDialog(false);
    }
  };

  // Add this function to fetch template returns policy
  const getTemplateReturnsPolicy = async () => {
    try {
      const res = await AxiosAll('get', 'dropdowns?type[19]=return_policy_content');
      if (res?.data?.statusCode === 200) {
        setReturnsPolicy(res?.data?.data?.return_policy_content || '');
      }
    } catch (error) {
      notify('Failed to fetch template returns policy', 'error');
    }
  };
  const getCountryData = async () => {
    try {
      const res = await AxiosAll('get', 'dropdowns?type[20]=countries');
      if (res?.data?.statusCode === 200) {
        setCountries(res?.data?.data?.countries || []);
      }
    } catch (error) {
      notify('Failed to fetch template returns policy', 'error');
    }
  };
  // Modify getUserProfile to store custom policy text separately
  const getUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });

      const policyType = res?.data?.user_info?.i_return_policy_type === 1 ? 'template' : 'custom';
      setSelectedPolicyType(policyType);

      // Store custom policy text separately
      if (res?.data?.user_info?.i_return_policy_type === 2) {
        setCustomPolicyText(res?.data?.user_info?.l_return_policy_content || '');
      }

      // Set initial text field content
      if (policyType === 'template') {
        getTemplateReturnsPolicy();
      } else {
        setReturnsPolicy(res?.data?.user_info?.l_return_policy_content || '');
      }
    } catch (error) {
      notify(error?.message, 'error');
    }
  };

  const getPersonaliseShipping = async () => {
    try {
      const res = await request({
        url: '/artist/personalisedShippingPrice',
        method: 'GET',
        token: userDetails?.token,
      });

      if (res?.data) {
        // Clear selection state before updating rows
        setSelectedRows([]);
        setShippingRows(res.data);
        setHasUnsavedRow(false);
      }
    } catch (error) {
      notify(error?.message, 'error');
    }
  };

  // Add this helper function after the other function declarations
  const getAppropriateReturnsPolicyText = (type, customText) => {
    if (type === 'template') {
      return returnsPolicy;
    }
    // For custom type, use custom text if it exists, otherwise use default
    return customText || DEFAULT_RETURNS_POLICY;
  };

  // Add this helper function after other function declarations
  const isCustomPolicySaveDisabled = () => {
    return selectedPolicyType === 'custom' && (!returnsPolicy || returnsPolicy.trim() === '');
  };

  // Update the checkbox handlers in the Returns Policy section
  useEffect(() => {
    getPersonaliseShipping();
    getUserProfile();
    getCountryData();
  }, []);

  const saveReturnsPolicy = async () => {
    // Validate custom policy
    if (selectedPolicyType === 'custom' && (!returnsPolicy || returnsPolicy.trim() === '')) {
      setPolicyError('Returns policy is required');
      return; // Prevent form submission
    }

    setPolicyError(''); // Clear error if validation passes

    const data = {
      i_return_policy_type: selectedPolicyType === 'template' ? 1 : 2,
      l_return_policy_content: selectedPolicyType === 'template' ? '' : returnsPolicy,
    };

    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data,
        token: userDetails?.token,
      });

      // Only reset custom policy if template is saved successfully
      if (selectedPolicyType === 'template' && res?.statusCode === 200) {
        setCustomPolicyText('');
        setPreviousPolicyData({
          type: 'template',
          text: '',
        });
      }

      notify(res?.message, 'success');
      getUserProfile();
    } catch (error) {
      notify(error?.message, 'error');
    }
  };

  const { userDetails } = useSelector((state) => state.user);
  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;
  const navigate = useNavigate();

  const initialValues = {
    address: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    postCode: '',
    phone: '',
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string()?.required('Address line 1 is required'),
    address2: Yup.string()?.required('Address line 2 is required'),
    city: Yup.string()?.required('City is required'),
    region: Yup.string()?.required('Region is required'),
    postCode: Yup.string()?.required('Post Code is required'),
    country: Yup.string()?.required('Country is required'),
    phone: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, 'Phone number must be numeric only')
      .min(10, 'Phone number is invalid')
      .max(13, 'Phone number is invalid'),
  });

  const getAccountStatus = async () => {
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      setAccountStatusPoint(data);
    }
  };

  const getAddress = async () => {
    try {
      const res = await AxiosAll('get', 'addresses?address_type=3', '', userDetails.token);
      const data = res?.data?.data;
      formik.setFieldValue('address', data?.[0]?.v_address_line || '');
      formik.setFieldValue('address2', data?.[0]?.v_address_line2 || '');
      formik.setFieldValue('city', data?.[0]?.v_city || '');
      formik.setFieldValue('postCode', data?.[0]?.v_postcode || '');
      formik.setFieldValue('region', data?.[0]?.v_region || '');
      formik.setFieldValue(
        'country',
        countryOptions?.find((country) => country.name === data?.[0]?.v_country)?.id || '',
      );
      formik.setFieldValue('phone', data?.[0]?.v_phone || '');
      formik.setFieldValue('isDefault', data?.[0]?.i_is_default_address === 1);
      setAddressList(data);
      setIsAddressListUpdated(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressListUpdated]);

  const addAddress = async (data) => {
    try {
      const res = await AxiosAll('post', 'addresses', data, userDetails.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        if (saveType === 'shipping') {
          setIsDisabled({ ...isDisabled, shipping: true });
        } else {
          setIsDisabled({ ...isDisabled, mobile: true });
        }
        if (AccountStatusPoint?.organise_shipping === 0) {
          getAccountStatus();
        }
        getAddress();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAddress = async (data) => {
    try {
      const id = addressList[0]?.id;
      const res = await AxiosAll('put', `addresses/${id}`, data, userDetails.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        if (saveType === 'shipping') {
          setIsDisabled({ ...isDisabled, shipping: true });
        } else {
          setIsDisabled({ ...isDisabled, mobile: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    const data = {
      address_line: values.address,
      address_line2: values.address2,
      city: values.city,
      region: values.region,
      country: values.country,
      phone: values.phone,
      postcode: values.postCode,
      is_default_address: 1,
      address_type: 3,
    };
    if (addressList.length === 0) {
      addAddress(data);
    } else {
      updateAddress(data);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getAccountStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addressList && addressList?.[0]?.v_address_line) {
      formik.setFieldValue('address', addressList?.[0]?.v_address_line || '');
      formik.setFieldValue('address2', addressList?.[0]?.v_address_line2 || '');
      formik.setFieldValue('city', addressList?.[0]?.v_city || '');
      formik.setFieldValue('postCode', addressList?.[0]?.v_postcode || '');
      formik.setFieldValue('region', addressList?.[0]?.v_region || '');
      formik.setFieldValue(
        'country',
        countryOptions?.find((country) => country.name === addressList?.[0]?.v_country)?.id || '',
      );
      formik.setFieldValue('phone', addressList?.[0]?.v_phone || '');
      formik.setFieldValue('isDefault', addressList?.[0]?.i_is_default_address === 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList]);

  const handleDuplicateClick = () => {
    if (!selectedRows?.length) {
      notify('Please select an item to duplicate', 'error');
      return;
    }
    if (selectedRows.length > 1) {
      notify('Please select only one shipping option at a time', 'error');
      return;
    }

    if (hasUnsavedRow) {
      notify('Please save the current row before duplicating', 'error');
      return;
    }

    // Check if selected row still exists and has an ID
    const selectedRow = shippingRows.find((row) => row.id === selectedRows[0]);
    if (!selectedRow) {
      notify('Selected row not found or is not saved yet', 'error');
      setSelectedRows([]); // Clear invalid selection
      return;
    }

    if (tableRef.current) {
      tableRef.current.handleDuplicate(selectedRows);
      setSelectedRows([]); // Clear invalid selection
    }
  };

  // Add new function to fetch countries
  const getCountryOptions = async () => {
    try {
      const res = await request({
        url: '/dropdowns',
        method: 'GET',
        params: {
          type: ['countries'],
          i_special: 1, // Add this query parameter
        },
        token: userDetails?.token,
      });

      if (res?.data?.countries) {
        // Transform the data to match the SelectBox format
        const formattedCountries = res.data.countries.map((country) => ({
          id: country.v_title,
          name: country.v_title,
        }));
        setCountryOptions(formattedCountries);
      }
    } catch (error) {
      notify(error?.message, 'error');
    }
  };

  useEffect(() => {
    getCountryOptions();
  }, []);

  // Add useEffect to clear selection when rows change
  useEffect(() => {
    setSelectedRows([]);
  }, [shippingRows]);

  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;
  const isPaidArtist = plan?.plan_type !== 'free';
  const countryId = userDetails?.user?.v_country === 201;

  return (
    <div className="shippingaddress">
      {isPaidArtist ? (
        <>
          <Typography variant="h5" className="mainheading">
            Your Shipping Settings
          </Typography>
          <Loader loading={loading} color="#f5445e" />
          <CardContainer className="addressfrom">
            <Typography variant="h5" className="heading" sx={{ mb: 2 }}>
              Cosimo Shipping
            </Typography>
            <Typography paragraph className="para_graph fulfilment_text">
              By opting to use Cosimo’s fulfiment services you can take the pressure off your hands and give greater
              security to your buyers. We’ll automatically calculate the cost of shipping your artwork at checkout. The
              buyer will also have Buyer Protection Fee (between 2-5%) automatically included, so that we’re able to
              intervene and support if there are any queries or need to facilitate returns. To allow us to manage this,
              we’ll need to know the address you will be shipping from.
            </Typography>
            <Grid container spacing={{ xs: 0, sm: 5 }} className="city">
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">Apt/Studio Name</Typography>
                <TextFieldInput
                  placeholder="Address Line 1"
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">Street </Typography>
                <TextFieldInput
                  placeholder="Address Line 2"
                  name="address2"
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  error={formik.touched.address2 && Boolean(formik.errors.address2)}
                  helperText={formik.touched.address2 && formik.errors.address2}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, sm: 5 }} className="city">
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">City </Typography>
                <TextFieldInput
                  placeholder="City"
                  name="city"
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">Region </Typography>
                <TextFieldInput
                  placeholder="Region"
                  name="region"
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  value={formik.values.region}
                  onChange={formik.handleChange}
                  error={formik.touched.region && Boolean(formik.errors.region)}
                  helperText={formik.touched.region && formik.errors.region}
                />
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 0, sm: 5 }} className="city">
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">Country </Typography>
                <SimpleSelectBox
                  name="country"
                  options={countryOptions}
                  onChange={formik.handleChange}
                  value={formik.values.country || 0}
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  onBlur={formik.handleBlur}
                  error={formik.touched.country && formik.errors.country}
                  helperText={formik.touched.country && formik.errors.country}
                  defaultOption="Country/Region"
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">Post Code </Typography>

                <TextFieldInput
                  placeholder="Post Code"
                  name="postCode"
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  value={formik.values.postCode}
                  onChange={formik.handleChange}
                  error={formik.touched.postCode && Boolean(formik.errors.postCode)}
                  helperText={formik.touched.postCode && formik.errors.postCode}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, sm: 5 }} className="pin" sx={{ alignItems: 'flex-end' }}>
              <Grid item xs={12} sm={6}>
                <Typography className="para_graph">Mob. Number</Typography>

                <TextFieldInput
                  placeholder="Mobile Number (Optional)"
                  type="number"
                  name="phone"
                  variant={isDisabled.shipping ? 'filled' : 'outlined'}
                  disabled={isDisabled.shipping}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {isDisabled.shipping ? (
                  <DarkBtn
                    title="Edit"
                    onClick={() => {
                      if (!countryId || !isPaidArtist) {
                        notify('You cannot add shipping address, as you are not from UK', 'error');
                        return;
                      }
                      setIsDisabled({ ...isDisabled, shipping: false });
                    }}
                  />
                ) : (
                  <DarkBtn
                    title="Save"
                    type="submit"
                    onClick={() => {
                      if (!countryId || !isPaidArtist) {
                        notify(
                          'You cannot add shipping address, as you are not from UK or might be an unpaid artist',
                          'error',
                        );
                        return;
                      }
                      formik.handleSubmit();
                      setSaveType('shipping');
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </CardContainer>
        </>
      ) : (
        <UpgradePlanContainer
          btnNavigation="/my-subscription"
          btnTitle="Upgrade"
          mainTitle="Upgrade to Cosimo Pro to benefit from our assisted shipping support"
        />
      )}

      <CardContainer className="Personalised_section_updated">
        <div className="Personalised_section">
          <Typography variant="h5" className="Personalised_heading">
            Personalised Shipping
          </Typography>

          <OutlineBtn title="Duplicate" onClick={handleDuplicateClick} disabled={!selectedRows?.length} />
          <OutlineBtn title="Delete" onClick={handleDelete} disabled={!selectedRows?.length} />
          <DarkBtn title="Add New +" onClick={handleAddNewShipping} />
        </div>
        <div className="font14px">
          If you would prefer to organise shipping yourself, please add your options here. You can assign a shipping
          option to each artwork when adding or editing an artwork listing.{' '}
        </div>

        <PersonalisedShippingTable
          ref={tableRef}
          countries={countries}
          rows={shippingRows}
          setRows={setShippingRows}
          getPersonaliseShipping={getPersonaliseShipping}
          onSelectedRowsChange={setSelectedRows}
          hasUnsavedRow={hasUnsavedRow}
          setHasUnsavedRow={setHasUnsavedRow}
        />

        {/* Delete Confirmation Dialog */}
        <Dialog open={openDeleteDialog} className="shipping_delete_popup" onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>{getDeleteDialogMessage()}</DialogContentText>
          </DialogContent>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ px: 3.5, py: 3.5 }}>
            <OutlineBtn title="Cancel" onClick={() => setOpenDeleteDialog(false)} />
            <DarkBtn title="Delete" onClick={handleDeleteConfirm} />
          </Stack>
        </Dialog>
      </CardContainer>
      <CardContainer className="Personalised_section_updated">
        <Typography variant="h5" className="Personalised_heading">
          Returns Policy
        </Typography>

        <FormGroup className="return_police_section">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedPolicyType === 'template'}
                onChange={(e) => {
                  if (e.target.checked) {
                    // Store current state before switching
                    setPreviousPolicyData({
                      type: 'custom',
                      text: customPolicyText,
                    });
                    setSelectedPolicyType('template');
                    getTemplateReturnsPolicy();
                  }
                }}
              />
            }
            label="Use Cosimo's template returns policy(Recommended)"
          />
          <div className="font14px or_text"> Or</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedPolicyType === 'custom'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedPolicyType('custom');
                    // Restore previous custom text if exists
                    if (previousPolicyData.type === 'custom') {
                      setReturnsPolicy(previousPolicyData.text || DEFAULT_RETURNS_POLICY);
                    } else {
                      setReturnsPolicy(customPolicyText || DEFAULT_RETURNS_POLICY);
                    }
                  }
                }}
              />
            }
            label="Create your own Returns Policy."
          />
        </FormGroup>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={8}
          sx={{ width: '100%' }}
          value={returnsPolicy}
          onChange={(e) => {
            setReturnsPolicy(e.target.value);
            if (selectedPolicyType === 'custom') {
              setCustomPolicyText(e.target.value);
              // Clear error when user starts typing
              setPolicyError('');
            }
          }}
          disabled={selectedPolicyType === 'template'}
          // Add placeholder text for custom policy
          placeholder={selectedPolicyType === 'custom' ? 'Enter your custom returns policy here...' : ''}
          error={!!policyError}
          helperText={policyError}
        />
        <Stack direction="row" justifyContent="center" className="last_Save_btn">
          <DarkBtn title="Save" onClick={saveReturnsPolicy} disabled={isCustomPolicySaveDisabled()} />
        </Stack>
      </CardContainer>

      <div className="skip">
        {nextStep(AccountStatusPoint) && AccountStatusPoint?.organise_shipping !== 1 && (
          <UnderLineBtn
            title="Skip For Now"
            onClick={() => navigate(`${nextStep(AccountStatusPoint, 'organise_shipping')}`)}
          />
        )}
        {AccountStatusPoint?.organise_shipping === 1 && AccountStatusPoint?.launch_page !== 1 && (
          <DarkBtn title="Continue" onClick={() => navigate(`${nextStep(AccountStatusPoint)}`)} />
        )}
      </div>
    </div>
  );
};
