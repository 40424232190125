import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Stack } from '@mui/system';
import { AddArtworkDescription } from '../../components/add_collection/AddArtworkDescription';
import { AddArtworkMeta } from '../../components/add_collection/AddArtworkMeta';
import { AddArtworkSize } from '../../components/add_collection/AddartworkSize';
import { ExtraTag } from '../../components/add_collection/ExtraTag';
import { UploadImgSection } from '../../components/add_collection/UploadImgSection';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import { Loader } from '../../components/universal/Loader';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import { notifyMessages } from '../../core/constant';
import sweatAlertBox from '../../components/universal/SweetAlertBox';
import OutlineBtn from '../../components/universal/Buttons/OutLineBtn';
import PageReloadHOC from './PageReloadHOC';

export const AddArtworkPage = () => {
  const [isArtworkDrafed, setIsArtworkDrafed] = useState(true);
  const [dropdowns, setDropdowns] = useState([]);
  const location = useLocation();
  const State = location?.state;
  const [currentArtworkData, setCurrentArtworkData] = useState([]);
  const [totalArtwork, setTotalArtwork] = useState(0);
  const [shippingData, setShippingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [deletedSizes, setDeletedSizes] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [tagKeywords, setTagKeywords] = useState([]);
  const [primaryImage, setPrimaryImage] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { getRootProps: primaryImageRootProps, getInputProps: primaryImageInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    minSize: 0,
    maxSize: 2097152,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            notify(notifyMessages?.fileTooLarge, 'error');
          }

          if (err.code === 'file-invalid-type') {
            notify(notifyMessages?.fileFormat, 'error');
          }
        });
      });
      const updatedImages = acceptedFiles.map((file, index) =>
        Object.assign(file, {
          id: uuidv4(),
          preview: URL.createObjectURL(file),
        }),
      );

      if (primaryImage.length + updatedImages.length > 1) {
        notify(notifyMessages?.primaryFileUploadLimit, 'error');
        return;
      }
      setPrimaryImage(updatedImages);
      formik.setFieldValue('primaryImage', acceptedFiles[0]);
    },
  });
  const [secondaryImages, setSecondaryImages] = useState([]);
  const { getRootProps: secondaryImageRootProps, getInputProps: secondaryImageInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    minSize: 0,
    maxSize: 2097152,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            notify(notifyMessages?.fileTooLarge, 'error');
          }

          if (err.code === 'file-invalid-type') {
            notify(notifyMessages?.fileFormat, 'error');
          }
        });
      });
      const updatedImages = acceptedFiles.map((file, index) =>
        Object.assign(file, {
          id: uuidv4(),
          preview: URL.createObjectURL(file),
        }),
      );
      if (secondaryImages.length + updatedImages.length > 3) {
        notify(notifyMessages?.secondaryFileUploadLimit, 'error');
        return;
      }
      setSecondaryImages([...secondaryImages, ...updatedImages]);
      formik.setFieldValue('secondaryImages', [...secondaryImages, ...updatedImages]);
    },
  });
  const [artworkDetails, setArtworkDetails] = useState([]);
  const [artworkSizes, setArtworkSizes] = useState([]);
  const [artworkTags, setArtworkTags] = useState([]);
  const [artworkMeta, setArtworkMeta] = useState([]);
  const [goodToGo, setGoodToGo] = useState({
    details: false,
    sizes: false,
    tags: false,
    meta: false,
  });

  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const { userDetails } = useSelector((state) => state.user);
  const artworkDetailsRef = useRef();
  const artworkSizesRef = useRef();
  const artworkTagsRef = useRef();
  const artworkMetaRef = useRef();

  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  const initialValues = {
    primaryImage: [],
    secondaryImages: [],
  };
  const validationSchema = Yup.object().shape({
    primaryImage: isArtworkDrafed ? Yup.mixed().notRequired() : Yup.mixed().required('Primary Image is required'),
    secondaryImages: Yup.mixed(),
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      values.primaryImage instanceof File && formData.append('primary_image', values?.primaryImage);
      if (values?.secondaryImages?.length > 0) {
        for (let i = 0; i < values?.secondaryImages?.length; i++) {
          values.secondaryImages[i] instanceof File &&
            formData.append(`secondary_images[${i}]`, values.secondaryImages[i]);
        }
      }
      formData.append('v_title', artworkDetails?.title);
      formData.append('i_maincategory_id', artworkDetails?.category || '');
      formData.append('i_stylecategory_id', artworkDetails?.style || '');
      formData.append('i_subjectcategory_id', artworkDetails?.subject || '');
      formData.append('i_mediumcategory_id', artworkDetails?.medium || '');
      formData.append('l_description', artworkDetails?.description || '');
      formData.append('care_detail', artworkDetails?.careDetail || '');
      if (deletedSizes.length > 0) {
        for (let i = 0; i < deletedSizes.length; i++) {
          formData.append(`size_delete_ids[${i}]`, deletedSizes[i] || '');
        }
      }
      if (deletedImages.length > 0) {
        for (let i = 0; i < deletedImages.length; i++) {
          formData.append(`image_delete_ids[${i}]`, deletedImages[i] || '');
        }
      }
      if (artworkSizes.length > 0) {
        for (let i = 0; i < artworkSizes.length; i++) {
          artworkSizes[i].id && formData.append(`sizes[${i}][id]`, artworkSizes[i].id || '');
          formData.append(`sizes[${i}][height]`, artworkSizes[i].height || '');
          formData.append(`sizes[${i}][width]`, artworkSizes[i].width || '');
          formData.append(`sizes[${i}][length]`, artworkSizes[i].len || '');
          formData.append(`sizes[${i}][edition_type]`, artworkSizes[i].editionType || '');
          formData.append(`sizes[${i}][edition_size]`, artworkSizes[i].editionSize || '');
          formData.append(`sizes[${i}][quantity]`, artworkSizes[i].quantityAvailable || 0);
          formData.append(`sizes[${i}][price]`, artworkSizes[i].price || '');
          formData.append(`sizes[${i}][sale_price]`, artworkSizes[i].salePrice || '');
          formData.append(`sizes[${i}][shipping_size]`, artworkSizes[i].shippingSize || '');

          // Handle availability_type as array
          if (artworkSizes[i]?.availability_type) {
            const availabilityTypes = Array.isArray(artworkSizes[i].availability_type || '')
              ? artworkSizes[i].availability_type
              : [artworkSizes[i].availability_type];

            availabilityTypes.forEach((type, typeIndex) => {
              formData.append(`sizes[${i}][availability_types][${typeIndex}]`, type || '');
            });

            // Always append sale_else_where_link
            // If FOR_SALE_ELSEWHERE (4) is selected, use the link, otherwise empty string
            formData.append(
              `sizes[${i}][sale_else_where_link]`,
              availabilityTypes.includes(4) ? artworkSizes[i].external_sale_link || '' : '',
            );
          } else {
            // If no availability type, still append empty string
            formData.append(`sizes[${i}][sale_else_where_link]`, '');
          }

          formData.append(`sizes[${i}][is_framed]`, artworkSizes[i]?.is_framed || '');

          if (artworkSizes[i]?.shipping_types?.length > 0) {
            artworkSizes[i].shipping_types.forEach((type, typeIndex) => {
              formData.append(`sizes[${i}][shipping_types][${typeIndex}]`, type || '');
            });
          }

          if (artworkSizes[i]?.personalised_shipping?.length > 0) {
            artworkSizes[i].personalised_shipping.forEach((chargeId, typeIndex) => {
              formData.append(`sizes[${i}][shipping_charge_ids][${typeIndex}]`, chargeId || '');
            });
          }
        }
      }
      artworkTags?.is_adult_content && formData.append('i_is_adult', artworkTags?.is_adult_content);
      artworkTags?.tags && formData.append(`v_search_tags`, artworkTags?.tags?.join(','));
      artworkTags?.add_to_collection && formData.append('collection_id', artworkTags?.add_to_collection);
      formData.append('visible', artworkTags?.visible_to_public || '');
      if (artworkTags?.colours.length > 0) {
        for (let i = 0; i < artworkTags?.colours.length; i++) {
          formData.append(`colors[${i}]`, artworkTags?.colours[i] || '');
        }
      }
      formData.append('meta_title', artworkMeta?.metaTitle || '');
      formData.append('meta_description', artworkMeta?.metaDescription || '');
      formData.append('meta_keyword', artworkMeta?.metaKeywords || '');
      const res = await AxiosAll(
        'post',
        params?.id ? `artist/artworks/${params?.id}` : 'artist/artworks',
        formData,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        navigate('/portfolio/artworks');
        setLoading(false);
      } else {
        notify(res?.data?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getPersonaliseShipping = async () => {
    try {
      const res = await request({
        url: '/artist/personalisedShippingPrice',
        method: 'GET',

        token: userDetails?.token,
      });
      setShippingData(res?.data || []);
    } catch (err) {
      notify(err?.message, 'error');
    }
  };
  useEffect(() => {
    getPersonaliseShipping();
  }, []);
  const handleRemoveImage = (status, image) => {
    if (image instanceof File === false) {
      setDeletedImages([...deletedImages, image.id]);
    }
    if (status === 'primary') {
      formik?.setFieldValue('primaryImage', '');
      setPrimaryImage([]);
    } else if (status === 'secondary') {
      const newImages = secondaryImages.filter((item) => item.id !== image.id);
      setSecondaryImages(newImages);
      formik?.setFieldValue('secondaryImages', newImages);
    }
  };

  const getDropdowns = async () => {
    try {
      const res = await request({
        url: '/dropdowns',
        method: 'GET',
        params: {
          type: [
            'mediums',
            'styles',
            'subjects',
            'categories',
            'colours',
            'my_collections',
            'sizes',
            'availability_types',
          ],
        },
        token: userDetails?.token,
      });

      const data = res?.data;
      setDropdowns(data);
    } catch (error) {
      console.error('Error fetching dropdowns: ', error);
    }
  };

  const getArtwork = async (data) => {
    try {
      setFetching(true);
      const res = await request({
        url: `/artist/artworks/${params?.id}`,
        method: 'GET',
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        const artworkData = res?.data;
        setCurrentArtworkData(artworkData);
        const pImage = artworkData?.artworkDetail.artwork_images.find((item) => item.i_default === 1);
        const sImages = artworkData?.artworkDetail.artwork_images.filter((item) => item.i_default === 0);

        // Handle primary image
        if (pImage?.id) {
          const structuredPImage = [{ preview: pImage?.v_image, id: pImage?.id }];
          formik?.setFieldValue('primaryImage', structuredPImage);
          setPrimaryImage(structuredPImage);
        }

        // Handle secondary images - only process and set if there are valid images
        const validSecondaryImages = sImages?.filter((item) => item?.id && item?.v_image);
        if (validSecondaryImages?.length > 0) {
          const structuredSImages = validSecondaryImages.map((item) => ({
            preview: item?.v_image,
            id: item?.id,
          }));
          formik?.setFieldValue('secondaryImages', structuredSImages);
          setSecondaryImages(structuredSImages);
        }

        const artworkDetail = {
          title: artworkData?.artworkDetail?.v_title,
          category: artworkData?.artworkDetail?.i_maincategory_id,
          style: artworkData?.artworkDetail?.i_stylecategory_id,
          subject: artworkData?.artworkDetail?.i_subjectcategory_id,
          medium: artworkData?.artworkDetail?.i_mediumcategory_id,
          description: artworkData?.artworkDetail?.l_description,
          careDetail: artworkData?.artworkDetail?.care_detail,
        };
        const artworkSize = artworkData?.artworkDetail.artwork_prices.map((item) => ({
          id: item?.id,
          height: item?.v_framedsize_1,
          width: item?.v_framedsize_2,
          len: item?.v_framedsize_3,
          allow_access: item?.allow_access,
          editionType: item?.i_edition_type,
          editionSize: item?.i_edition_size,
          quantityAvailable: item?.i_quantity,
          price: item?.i_price,
          salePrice: item?.i_sale_price,
          shippingSize: item?.i_shipping_package_id,
          is_framed: item?.is_framed,
          // Transform availability types from nested structure
          availability_type:
            item?.availability_types.find((type) => type.availability_type_id !== 2)?.availability_type_id || '',
          open_for_enquiries: item.availability_types.some((type) => type.availability_type_id === 2),
          external_sale_link: item.sale_else_where_link || '',
          shipping_types: item?.shipping_types?.map((ship) => ship.shipping_type_id.toString()) || [],
          // Add personalized shipping IDs
          personalised_shipping:
            item?.personalised_shipping_charges?.map((charge) => charge.personal_shipping_charge_id.toString()) || [],
        }));
        const artworkTag = {
          is_adult_content: artworkData?.artworkDetail?.i_is_adult,
          tags: artworkData?.artworkDetail?.v_search_tags?.split(','),
          add_to_collection: artworkData?.artworkDetail?.collection_id,
          visible_to_public: artworkData?.artworkDetail?.e_status,
          colours: artworkData?.artworkDetail?.colours.map((item) => item.id.toString()),
        };
        const artworkMetaData = {
          metaTitle:
            artworkData?.artworkDetail?.meta_title === 'undefined' ? '' : artworkData?.artworkDetail?.meta_title,
          metaDescription:
            artworkData?.artworkDetail?.meta_description === 'undefined'
              ? ''
              : artworkData?.artworkDetail?.meta_description,
          metaKeywords:
            artworkData?.artworkDetail?.meta_keyword === 'undefined' ? '' : artworkData?.artworkDetail?.meta_keyword,
        };
        // setPrimaryImage(structuredPImage);
        // setSecondaryImages(structuredSImages);
        setFetchedData({ artworkDetail, artworkSize, artworkTag, artworkMeta: artworkMetaData });
        setIsArtworkDrafed(artworkData?.artworkDetail?.e_status === 'disabled');
        setTagKeywords(artworkData?.artworkDetail?.v_search_tags?.split(','));
        // formik.setFieldValue('primaryImage', pImage?.v_image);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const getTotalArtworks = async () => {
    try {
      const res = await request({
        url: '/artist/artworks?is_artist_dashboard=1',
        method: 'GET',
        params: {
          artist: userDetails?.user?.id,
          artistId: userDetails?.user?.id,
          slug: userDetails?.user?.v_artists_slug,
          page: 1,
          //   type: 'favourite_artworks',
        },
        token: userDetails?.token,
      });
      setTotalArtwork(res?.data?.total);
    } catch (error) {
      console.log('error', error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getTotalArtworks();
    getDropdowns();
    if (params?.id) {
      getArtwork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trigger) {
      artworkDetailsRef?.current?.click();
      setTrigger(false);
    }
    if (goodToGo.details) {
      artworkSizesRef?.current?.click();
    }
    if (goodToGo.sizes) {
      artworkTagsRef?.current?.click();
    }
    if (goodToGo.tags) {
      artworkMetaRef?.current?.click();
      setFormSubmitted(true);
    }
  }, [trigger, goodToGo.details, goodToGo.sizes, goodToGo.tags, goodToGo.meta]);

  const handleFormSubmit = useCallback(() => {
    if (goodToGo.details && goodToGo.sizes && goodToGo.tags && goodToGo.meta && formSubmitted) {
      formik?.handleSubmit();
      setGoodToGo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodToGo.details, goodToGo.sizes, goodToGo.tags, goodToGo.meta, formSubmitted]);

  useEffect(() => {
    handleFormSubmit();
  }, [handleFormSubmit]);

  useEffect(() => {
    setFormSubmitted(false); // Reset the formSubmitted state when the dependencies change
  }, [goodToGo.details, goodToGo.sizes, goodToGo.tags, goodToGo.meta]);

  return (
    <PageReloadHOC>
      <div className="addartworkpage">
        <Loader loading={fetching} />
        <div className="heading">
          <Typography variant="h2">{params?.id ? 'Edit Artwork' : 'Upload Artwork'}</Typography>
          <WhiteBtn title="Return" onClick={() => navigate('/portfolio/artworks')} />
        </div>
        <UploadImgSection
          error={formik?.errors}
          primaryImage={primaryImage}
          primaryImageRootProps={primaryImageRootProps}
          primaryImageInputProps={primaryImageInputProps}
          secondaryImages={secondaryImages}
          secondaryImageRootProps={secondaryImageRootProps}
          secondaryImageInputProps={secondaryImageInputProps}
          handleRemoveImage={handleRemoveImage}
          isArtworkDrafed={isArtworkDrafed}
        />

        <AddArtworkDescription
          dropdowns={dropdowns}
          setArtworkDetails={setArtworkDetails}
          artworkDetailsRef={artworkDetailsRef}
          fetchedData={fetchedData}
          setGoodToGo={setGoodToGo}
          isArtworkDrafed={isArtworkDrafed}
        />

        <AddArtworkSize
          dropdowns={dropdowns}
          deletedSizes={deletedSizes}
          setDeletedSizes={setDeletedSizes}
          setArtworkSizes={setArtworkSizes}
          artworkSizesRef={artworkSizesRef}
          fetchedData={fetchedData}
          setGoodToGo={setGoodToGo}
          plan={plan}
          userDetails={userDetails}
          isArtworkDrafed={isArtworkDrafed}
        />

        <ExtraTag
          plan={plan}
          dropdowns={dropdowns}
          tagKeywords={tagKeywords}
          setArtworkTags={setArtworkTags}
          artworkTagsRef={artworkTagsRef}
          fetchedData={fetchedData}
          setGoodToGo={setGoodToGo}
          onVisiblityChange={(e) => {
            setIsArtworkDrafed(e === 'disabled');
          }}
        />

        <AddArtworkMeta
          dropdowns={dropdowns}
          setArtworkMeta={setArtworkMeta}
          artworkMetaRef={artworkMetaRef}
          fetchedData={fetchedData}
          setGoodToGo={setGoodToGo}
          isArtworkDrafed={isArtworkDrafed}
        />

        {plan?.plan_type === 'paid' &&
          currentArtworkData?.artworkDetail?.is_pod_available === 1 &&
          plan?.v_print_on_demand === 'Yes' && (
            <Typography color="secondary" sx={{ textAlign: 'center', mb: 5 }}>
              Your artwork has already PoD option enabled.
            </Typography>
          )}
        <DarkBtn
          loading={loading}
          loadingText="Saving Artwork..."
          title="Save Artwork"
          type="submit"
          onClick={() => !trigger && setTrigger(true)}
        />
      </div>
    </PageReloadHOC>
  );
};
