// export const formatAIResponseToHTML = (content) => {
//   if (!content) return '';

//   let formattedContent = content;

//   // Parse and sanitize existing HTML tags
//   formattedContent = formattedContent.replace(/<strong>(.*?)<\/strong>/g, '$1').replace(/<em>(.*?)<\/em>/g, '$1');

//   // Convert line breaks
//   formattedContent = formattedContent.replace(/\n\n/g, '</p><p>');
//   formattedContent = formattedContent.replace(/\n/g, '<br/>');

//   // Convert bold text patterns
//   formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '<span style="font-weight: bold;">$1</span>');
//   formattedContent = formattedContent.replace(/__(.*?)__/g, '<span style="font-weight: bold;">$1</span>');

//   // Convert italic text patterns
//   formattedContent = formattedContent.replace(/\*(.*?)\*/g, '<span style="font-style: italic;">$1</span>');
//   formattedContent = formattedContent.replace(/_(.*?)_/g, '<span style="font-style: italic;">$1</span>');

//   // Convert bullet points
//   formattedContent = formattedContent.replace(/^\s*[-*]\s+(.+)/gm, '<li>$1</li>');
//   formattedContent = formattedContent.replace(/(<li>.*?<\/li>)/gs, '<ul>$1</ul>');

//   // Convert numbered lists
//   formattedContent = formattedContent.replace(/^\d+\.\s+(.+)/gm, '<li>$1</li>');
//   formattedContent = formattedContent.replace(/(<li>\d+\..*?<\/li>)/gs, '<ol>$1</ol>');

//   // Convert headings with proper spacing
//   formattedContent = formattedContent.replace(/^#{6}\s+(.+?)\n*/gm, '<h6 style="margin: 1.5em 0 0.5em 0">$1</h6><p>');
//   formattedContent = formattedContent.replace(/^#{5}\s+(.+?)\n*/gm, '<h5 style="margin: 1.5em 0 0.5em 0">$1</h5><p>');
//   formattedContent = formattedContent.replace(/^#{4}\s+(.+?)\n*/gm, '<h4 style="margin: 1.5em 0 0.5em 0">$1</h4><p>');
//   formattedContent = formattedContent.replace(/^#{3}\s+(.+?)\n*/gm, '<h3 style="margin: 1.5em 0 0.5em 0">$1</h3><p>');
//   formattedContent = formattedContent.replace(/^#{2}\s+(.+?)\n*/gm, '<h2 style="margin: 1.5em 0 0.5em 0">$1</h2><p>');
//   formattedContent = formattedContent.replace(/^#{1}\s+(.+?)\n*/gm, '<h1 style="margin: 1.5em 0 0.5em 0">$1</h1><p>');

//   // Convert blockquotes
//   formattedContent = formattedContent.replace(/^>\s+(.+)/gm, '<blockquote>$1</blockquote>');

//   // Convert code blocks
//   formattedContent = formattedContent.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');
//   formattedContent = formattedContent.replace(/`([^`]+)`/g, '<code>$1</code>');

//   // Clean up any empty paragraphs
//   formattedContent = formattedContent.replace(/<p>\s*<\/p>/g, '');
//   formattedContent = formattedContent.replace(/<p>\s*<p>/g, '<p>');

//   // Ensure proper paragraph wrapping
//   if (!formattedContent.match(/^<[^>]+>/)) {
//     formattedContent = `<p>${formattedContent}</p>`;
//   }

//   return formattedContent;
// };
export const formatAIResponseToHTML = (response) => {
  if (!response) return '';

  // Replace new lines with <br> tags for better formatting
  let formattedResponse = response.replace(/\n/g, '<br>');

  // Convert common Markdown-like bold syntax **text** or *text* to <strong>
  formattedResponse = formattedResponse.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formattedResponse = formattedResponse.replace(/\*(.*?)\*/g, '<em>$1</em>');

  // Convert URLs into clickable links
  formattedResponse = formattedResponse.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');

  return formattedResponse;
};

export const formatAIResponseToHTMLBio = (content) => {
  if (!content) return '';

  let formattedContent = content;

  // Convert bold text patterns (**bold** → BOLD)
  formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '$1'.toUpperCase());
  formattedContent = formattedContent.replace(/__(.*?)__/g, '$1'.toUpperCase());

  // Convert italic text patterns (*italic* → _italic_)
  formattedContent = formattedContent.replace(/\*(.*?)\*/g, '_$1_');
  formattedContent = formattedContent.replace(/_(.*?)_/g, '_$1_');

  // Convert bullet points (- item → • item)
  formattedContent = formattedContent.replace(/^\s*[-*]\s+(.+)/gm, '• $1');

  // Convert numbered lists (1. item → 1) item)
  formattedContent = formattedContent.replace(/^(\d+)\.\s+(.+)/gm, '$1) $2');

  // Convert headings (### Heading → Heading)
  formattedContent = formattedContent.replace(/^#{6}\s+(.+)/gm, '$1');
  formattedContent = formattedContent.replace(/^#{5}\s+(.+)/gm, '$1');
  formattedContent = formattedContent.replace(/^#{4}\s+(.+)/gm, '$1');
  formattedContent = formattedContent.replace(/^#{3}\s+(.+)/gm, '$1');
  formattedContent = formattedContent.replace(/^#{2}\s+(.+)/gm, '$1');
  formattedContent = formattedContent.replace(/^#{1}\s+(.+)/gm, '$1');

  // Convert blockquotes (> text → "text")
  formattedContent = formattedContent.replace(/^>\s+(.+)/gm, '“$1”');

  // Convert code blocks (```code``` → [code])
  formattedContent = formattedContent.replace(/```([\s\S]*?)```/g, '[code]$1[/code]');
  formattedContent = formattedContent.replace(/`([^`]+)`/g, '[$1]');

  // Convert line breaks (\n\n → new paragraph, \n → new line)
  formattedContent = formattedContent.replace(/\n\n/g, '\n\n');
  formattedContent = formattedContent.replace(/\n/g, '\n');

  return formattedContent.trim();
};
