import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

export const TableSkeleton = () => {
  const timesToRender = 7;
  return (
    <Table
      aria-label="simple table"
      sx={{
        boxShadow:
          '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell align="right" width="15%">
            <Skeleton variant="rectangular" width={45} height={45} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="order-body">
        {[...Array(timesToRender)].map((_, index) => (
          <TableRow key={index}>
            <TableCell align="center" width="15%">
              <Skeleton variant="rectangular" width={45} height={45} style={{ borderRadius: 15 }} />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 15 }} />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 50 }} />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 50 }} />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="rectangular" width="100%" height={25} style={{ borderRadius: 50 }} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
