import { Avatar, Card, Container } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { useNavigate, useParams } from 'react-router';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import MsgForm from '../../../components/AllMessages/MsgForm';
import { SigngleMsgView } from '../../../components/AllMessages/SigngleMsgView';
import { SingleBagdeView } from '../../../components/AllMessages/SingleBagdeView';
import { CardContainer } from '../../../components/universal/CardContainer';
import MsgPreviewBox from '../../../components/universal/MsgPreviewBox';
import { useWindowSize } from '../../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../../models/breakpoints.model';
import AxiosAll from '../../../services/axiosAll';
import GrayBtn from '../../../components/universal/Buttons/GrayBtn';
import notify from '../../../utils/notify';

const MessageDetails = () => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const params = useParams();

  const [allChatsData, setAllChatsData] = useState([]);
  const [singelMessageBox, setSingelMessageBox] = useState(true);
  const [sellerMsg, setSellerMsg] = useState([]);

  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleReplyEmail = (email) => {
    if (email) {
      const mailtoLink = `mailto:${email}`;

      window.location.href = mailtoLink;
    } else {
      notify('Email address not found!', 'error');
    }
  };

  const getSellerMessages = useCallback(
    async (id) => {
      try {
        const res = await AxiosAll('get', `sellerMessages?i_chat_id=${id}`, null, userDetails?.token);
        if (res?.data?.statusCode === 200) {
          setSellerMsg(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [userDetails?.token],
  );
  const senderType = sellerMsg?.chat?.user?.e_type;

  const getAllChats = async () => {
    try {
      const res = await AxiosAll('get', 'chats', null, userDetails?.token);
      if (res?.data?.data?.length > 0) {
        setAllChatsData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const currentChat = useMemo(() => {
    return allChatsData?.find((item) => item?.id == params?.id);
  }, [allChatsData, params?.id]);

  const handleScroll = useCallback(() => {
    const child = document.getElementById('child');

    if (child?.scrollTop > 0) {
      setSingelMessageBox(false);
    } else {
      setSingelMessageBox(true);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      const child = document.getElementById('child');
      child?.addEventListener('scroll', handleScroll);
    }
    return () => {
      const child = document.getElementById('child');
      child?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isMobile]);

  useEffect(() => {
    getAllChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.id) {
      getSellerMessages(params?.id);
    }
  }, [params?.id, getSellerMessages]);

  useEffect(() => {
    const child = document.getElementById('child');
    if (child) {
      child.scrollTo({
        top: child.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [sellerMsg]);

  return (
    <div className="allmessage">
      <Container className="all-screen-container search_result_container_inner">
        <div className="searchbtninput">
          <div className="font25px mobilehide">Your Messages</div>
          <div className="font25px mobileshow">Your Messages</div>
        </div>
        <div className="message-section">
          <CardContainer
            height={775}
            gap={25}
            className={senderType === 'guest' ? 'message-card-id guest_card_id' : 'message-card-id normal_card_id'}
          >
            <div style={{ position: 'relative' }}>
              <ErrorSharpIcon className="error_icon" />
              <Card className="verify_user_card">
                <div className="font12px">
                  {senderType === 'guest'
                    ? 'This user does not have a registered Cosimo account, meaning their inquiry was submitted via the contact form without registration. Please exercise caution when responding, as Cosimo cannot verify their identity or track further communication. Be mindful of potential red flags such as suspicious requests, unsolicited offers, links to unknown websites, requests for personal or financial information, and vague or overly generic inquiries. We recommend keeping conversations on-platform for added security and support. Cosimo is not liable for any issues, disputes, or losses arising from communications conducted outside of our platform. If you are unsure about the validity of this inquiry, please contact our support team for assistance.'
                    : 'While we take steps to verify users, we always recommend staying cautious and mindful of best practices when responding. Look out for things like unexpected requests, links to unfamiliar websites, or requests for sensitive information. Keeping your conversations on-platform helps ensure a secure and supported experience. If you have any questions or concerns about this inquiry, our support team is here to help.'}
                </div>
              </Card>
            </div>
            {singelMessageBox ? (
              <SigngleMsgView
                data={currentChat}
                messageId={params?.id}
                sendTime={moment.utc(currentChat?.last_message?.created_at).local().format('HH:mm')}
                sendDate={moment.utc(currentChat?.last_message?.created_at).local().format('DD/MM/YY')}
                className="full-width"
              />
            ) : (
              <SingleBagdeView data={currentChat} messageId={params?.id} handler={() => navigate('/messages')} />
            )}
            <div className="scroll-dir" id="child">
              <div className="messagesbox">
                {sellerMsg?.messages?.map((msg, i) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <>
                      <div className="msg_avatar">
                        {userDetails?.user?.id !== msg?.i_sender_id && (
                          <Avatar src={sellerMsg?.chat?.receiver?.v_image} className="avatar_new-img" />
                        )}
                        <MsgPreviewBox
                          senderId={msg?.i_sender_id}
                          senderName={msg?.sender?.v_fname}
                          senderDescription={msg?.l_message}
                          sendTime={moment.utc(msg?.created_at).local().format('HH:mm')}
                          sendDate={moment.utc(msg?.created_at).local().format('DD/MM/YY')}
                          key={msg?.id}
                        />
                        {userDetails?.user?.id === msg?.i_sender_id && (
                          <Avatar src={sellerMsg?.chat?.seller?.v_image} className="avatar_new-img" />
                        )}
                      </div>
                      {senderType === 'guest' && sellerMsg.messages.length - 1 === i && (
                        <div className="reply_section">
                          <GrayBtn
                            className="reply_email"
                            title="Reply via email"
                            onClick={() => handleReplyEmail(sellerMsg?.chat?.user?.v_email)}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>

            <MsgForm
              singelMessageBox={singelMessageBox}
              data={sellerMsg}
              senderType={senderType}
              getSellerMessages={getSellerMessages}
              className="id-form"
              Back={() => navigate('/messages')}
            />
          </CardContainer>
        </div>
      </Container>
    </div>
  );
};

export default MessageDetails;
