import { Dialog, DialogContent, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { Stack } from '@mui/system';
import TextFieldInput from '../universal/InputTypeText/TextField';
import DarkBtn from '../universal/Buttons/DarkBtn';

export const StudioPopup = ({
  message,
  btnTitle,
  handleStudio,
  bottomMessage,
  open,
  setOpen,
  initialUrl = '',
  loading,
}) => {
  const formik = useFormik({
    initialValues: {
      url: initialUrl, // Use the initialUrl prop here
    },
    validationSchema: Yup.object({
      url: Yup.string().url('Please enter a valid URL').required('URL is required'),
    }),
  });
  const handleClose = () => {
    setOpen(false);
  };
  const isFormValid = () => {
    // Return false if URL is empty or has validation errors
    if (!formik.values.url || formik.errors.url) {
      return false;
    }
    return true;
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    formik.setTouched({ url: true });

    if (!isFormValid()) {
      return;
    }
    handleStudio(formik.values.url || '');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // className="new_contact_dialogbox"
      className="contactartist"
    >
      <DialogContent className="contact_inner">
        <div className="font20px message_text">{message}</div>
        <div className="font12px para_text">{bottomMessage}</div>

        <div className="dispatch-order">
          <TextFieldInput
            type="text"
            className="textfield"
            name="url"
            value={formik.values.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter Link"
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url ? formik.errors.url : 'Please enter Link'}
          />
        </div>
        <Stack direction="column" spacing={2} mt={1}>
          <DarkBtn
            title={loading ? 'Genarating...' : 'Genarate'}
            loadingText="Genarating..."
            onClick={(e) => handleButtonClick(e)}
            loading={loading}
            disabled={loading || !isFormValid()}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
