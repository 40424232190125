import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';

export const MyCertificate = ({ artworkData, backBtn, loading }) => {
  const navigate = useNavigate();
  const para =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore<br/>          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco...';
  return (
    <>
      {backBtn && (
        <div className="return-btn">
          <DarkBtn
            title="Return to certificates"
            styleBtn="pdfbtn"
            onClick={() => navigate('/portfolio/certificates')}
          />
        </div>
      )}
      {loading ? (
        <Skeleton sx={{ m: '2rem auto', borderRadius: '15px' }} variant="rectangular" width="60%" height="100vh" />
      ) : artworkData?.seller?.allow_certificate ? (
        <div className="pdfbleed" id={`certificate-${artworkData?.id}`}>
          <div className="main">
            <Typography variant="h4" className="main-heading">
              Certificate
              <br />
              of Authenticity
            </Typography>
            <div className="art-title">
              <div className="title-container">
                <Typography variant="h3" className="title-heading">
                  {artworkData?.artwork?.v_title}
                </Typography>
                <table>
                  <tr>
                    <td>Size:</td>
                    <td>{artworkData?.shipping_size?.v_name}</td>
                  </tr>
                  <tr>
                    <td>Medium:</td>
                    <td>{artworkData?.artwork?.artwork_medium?.v_name}</td>
                  </tr>
                  <tr>
                    <td>Style:</td>
                    <td>{artworkData?.artwork?.artwork_style?.v_name}</td>
                  </tr>
                  <tr>
                    <td>Subject:</td>
                    <td>{artworkData?.artwork?.artwork_subject?.v_name}</td>
                  </tr>
                  <tr>
                    <td>Artist Location:</td>
                    <td>{artworkData?.seller?.user_city?.v_title}</td>
                  </tr>
                  <tr>
                    <td>Edition Size:</td>
                    <td>{artworkData?.i_edition_size === 10000 ? 'Unlimited' : artworkData?.i_edition_size}</td>
                  </tr>
                </table>
                <Typography variant="h4">
                  {artworkData?.seller?.v_artist_name || artworkData?.seller?.full_name}
                </Typography>
                <Typography paragraph>............................</Typography>
              </div>
              <div className="art-img">
                {artworkData?.artwork?.artwork_images.map((item) => {
                  return item.i_default === 1 && <img className="big-art-img" src={item?.v_image} alt="" />;
                })}
              </div>
            </div>
            <div className="discription">
              <Typography variant="h4" className="certificate-heading">
                Artwork Description
              </Typography>
              <Typography variant="h4" dangerouslySetInnerHTML={{ __html: artworkData?.artwork?.l_description }} />
            </div>
            <div className="footer">
              <Typography paragraph>Powered by</Typography>
              <img src={images.PDFLogo} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <UpgradePlanContainer mainTitle="Artist does not have valid plan for certificates" disableBtn />
      )}
    </>
  );
};
