import React, { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/system';
import { FormHelperText, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import notify from '../../utils/notify';
import { updateUser } from '../../redux/actions/user';
import { request } from '../../services/request';
import BannerUpload from '../../components/ImageCropper/BannerUpload';

const PortfolioSettings = () => {
  const [profileBanner, setProfileBanner] = useState(null);

  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState({});

  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getUserProfile = async () => {
    setProfileLoading(true);
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        token: userDetails?.token,
      });
      if (res.statusCode == 200) {
        setProfileData(res?.data);
        setProfileBanner(res?.data?.v_profile_banner_image);
      }
    } catch (error) {
      notify(error?.message, 'error');
    } finally {
      setProfileLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      await getUserProfile();
    };
    init();
  }, []);

  const options = [{ id: "'Urbanist', sans-serif", v_label_name: "'Urbanist', sans-serif" }];

  const initialValues = {
    facebook: profileData?.user_info?.v_facebook || '',
    instagram: profileData?.user_info?.v_instagram || '',
    pinterest: profileData?.user_info?.v_pinterest || '',
    linkedin: profileData?.user_info?.v_linkedin || '',
    twitter: profileData?.user_info?.v_twitter || '',
    tiktok: profileData?.user_info?.v_tiktok || '',
    // imprimo: profileData?.user_info?.v_imprimo || '',
    other: profileData?.user_info?.v_other_url || '',
    fontstyle: profileData?.user_info?.v_font_style ? profileData?.user_info?.v_font_style : options[0]?.id,
    profileBanner: null,
  };

  const validationSchema = Yup.object().shape({
    facebook: Yup.string().matches(
      /^https:\/\/(www\.)?facebook\.com\/?/,
      'Invalid Facebook URL, must start with https://www.facebook.com',
    ),
    instagram: Yup.string().matches(
      /^https:\/\/(www\.)?instagram\.com\/?/,
      'Invalid Instagram URL, must start with https://www.instagram.com',
    ),
    twitter: Yup.string().matches(/^https:\/\/x\.com\/?/, 'Invalid Twitter URL, must start with https://x.com'),
    pinterest: Yup.string().matches(
      /^(https?:\/\/)?([a-z]{2,}\.)?pinterest\.(com|[a-z]{2,})\/[a-zA-Z0-9_]+\/?$/,
      'Please enter a valid Pinterest URL.',
    ),
    linkedin: Yup.string().matches(
      /^https:\/\/(www\.)?linkedin\.com\/?/,
      'Invalid LinkedIn URL, must start with https://www.linkedin.com',
    ),
    tiktok: Yup.string().matches(
      /^https:\/\/(www\.)?tiktok\.com\/?/,
      'Invalid TikTok URL, must start with https://www.tiktok.com',
    ),
    // imprimo: Yup.string().matches(
    //   /^https:\/\/(?:[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*\.)*imprimo\.ca\/?/,
    //   'Invalid Imprimo URL, must start with https://imprimo.ca or https://subdomain.imprimo.ca'
    // ),
    other: Yup.string().matches(/^https:\/\/?/, 'Other link must start with https://'),
  });

  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append('v_facebook', values?.facebook);
    formData.append('v_instagram', values?.instagram);
    formData.append('v_twitter', values?.twitter);
    formData.append('v_pinterest', values?.pinterest);
    formData.append('v_linkedin', values?.linkedin);
    formData.append('v_tiktok', values?.tiktok);
    // formData.append('v_imprimo', values?.imprimo);
    formData.append('v_other_url', values?.other);
    formData.append('v_font_style', values?.fontstyle);

    if (values?.profileBanner instanceof File) {
      formData.append('v_profile_banner_image', values?.profileBanner);
    }
    setLoading(true);
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data: formData,
        token: userDetails?.token,
      });
      if (res.statusCode == 200) {
        dispatch(updateUser(res?.data));
        notify(res?.message, 'success');
        getUserProfile();
      }
    } catch (error) {
      notify(error?.message, 'error');
    } finally {
      setLoading(false);
      resetForm();
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const navigateToPreviewHomePage = () => {
    const url = `${process.env.REACT_APP_USER_APP_URL}artist/${
      profileData?.v_artist_name || `${profileData?.v_fname}-${profileData?.v_lname}`
    }/${profileData?.user_info?.i_user_id}`;
    window.open(url, '_blank');
  };

  const requiredKeys = [
    'v_instagram',
    'v_facebook',
    'v_pinterest',
    'v_linkedin',
    'v_twitter',
    'v_tiktok',
    'v_other_url',
  ];

  const isDisabled = !requiredKeys.some((key) => profileData?.user_info?.[key]);

  return (
    <div className="portfolio-settings ">
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        className="my-account-new"
      >
        <h2 className="font25px maintitle">Portfolio Settings</h2>
        <Stack direction="row" alignItems="center" spacing={3}>
          <DarkBtn title="Preview" onClick={navigateToPreviewHomePage} styleBtn={isDisabled ? 'disable_darkbtn' : ''} />
        </Stack>
      </Stack>
      <div className="profilesection card-container gray_theme_update">
        <form onSubmit={formik.handleSubmit}>
          {profileLoading ? (
            <div className="loading-container">
              <Typography variant="h6">Loading...</Typography>
            </div>
          ) : (
            <>
              <BannerUpload
                initialImage={profileBanner}
                onImageCropped={(croppedFile) => formik.setFieldValue('profileBanner', croppedFile)}
              />
              {formik?.errors?.profileBanner && formik?.touched?.profileBanner && (
                <FormHelperText error>{formik?.errors?.profileBanner}</FormHelperText>
              )}

              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
                <div className="nameemailsection">
                  <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                    Instagram
                  </Typography>
                  <TextField
                    placeholder="Instagram"
                    name="instagram"
                    type="text"
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.instagram && formik?.touched?.instagram && (
                    <FormHelperText error>{formik?.errors?.instagram}</FormHelperText>
                  )}
                </div>
                <div className="nameemailsection">
                  <Typography className="font18px" variant="body1" sx={{ mb: 1 }} type="text">
                    X
                  </Typography>

                  <TextField
                    placeholder="twitter "
                    type="text"
                    name="twitter"
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.twitter && formik?.touched?.twitter && (
                    <FormHelperText error>{formik?.errors?.twitter}</FormHelperText>
                  )}
                </div>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
                <div className="nameemailsection">
                  <Typography className="font18px" variant="body1" sx={{ mb: 1 }}>
                    Facebook
                  </Typography>
                  <TextField
                    placeholder="Facebook"
                    type="text"
                    name="facebook"
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.facebook && formik?.touched?.facebook && (
                    <FormHelperText error>{formik?.errors?.facebook}</FormHelperText>
                  )}
                </div>
                <div className="nameemailsection">
                  <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                    LinkedIn
                  </Typography>
                  <TextField
                    placeholder="LinkedIn"
                    name="linkedin"
                    type="text"
                    value={formik.values.linkedin}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.linkedin && formik?.touched?.linkedin && (
                    <FormHelperText error>{formik?.errors?.linkedin}</FormHelperText>
                  )}
                </div>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
                <div className="nameemailsection">
                  <Typography className="font18px" variant="body1" sx={{ mb: 1 }}>
                    TikTok
                  </Typography>
                  <TextField
                    placeholder="TikTok"
                    type="text"
                    name="tiktok"
                    value={formik.values.tiktok}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.tiktok && formik?.touched?.tiktok && (
                    <FormHelperText error>{formik?.errors?.tiktok}</FormHelperText>
                  )}
                </div>
                <div className="nameemailsection">
                  <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                    Pinterest
                  </Typography>
                  <TextField
                    placeholder="Pinterest"
                    name="pinterest"
                    type="text"
                    value={formik.values.pinterest}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.pinterest && formik?.touched?.pinterest && (
                    <FormHelperText error>{formik?.errors?.pinterest}</FormHelperText>
                  )}
                </div>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
                <div className="nameemailsection">
                  <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                    Other
                  </Typography>
                  <TextField
                    placeholder="Other"
                    name="other"
                    type="text"
                    value={formik.values.other}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.other && formik?.touched?.other && (
                    <FormHelperText error>{formik?.errors?.other}</FormHelperText>
                  )}
                </div>
              </Stack>

              <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mb: 2.5, mt: 2.5 }}>
                <DarkBtn
                  type="submit"
                  title="Save"
                  loading={loading}
                  styleBtn={!formik.dirty ? 'disable_darkbtn' : 'darkbtn_save'}
                />
              </Stack>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default PortfolioSettings;
