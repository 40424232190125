'use client';

import React from 'react';
import DarkBtn from '../Buttons/DarkBtn';
import ImageCard from '../imageCard';

const ArtistsCard = ({ item, buttonTitle, onClick, className }) => {
  return (
    <div className={`artistscard ${className}`}>
      <div className="image-section ">
        <ImageCard
          image={item?.v_image}
          alt={item?.v_artist_name || `${item?.v_fname} ${item?.v_lname}`}
          onClick={onClick}
        />
        <div>
          <p className="proname">{item?.v_artist_name || `${item?.v_fname} ${item?.v_lname}`}</p>

          <p className="prolocation">{item?.user_city?.v_title}</p>
        </div>
      </div>
    </div>
  );
};

export default ArtistsCard;
