/* eslint-disable import/no-useless-path-segments */
export const images = {
  mainLogo: require('./images/header/mainlogo.webp'),
  mainLogoWhite: require('./images/home/white-cosimo-logo.webp'),
  searchIcon: require('./images/header/searchicon.webp'),
  sidebarIcon: require('./images/header/sidebaricon.webp'),
  cartIcon: require('./images/header/carticon.webp'),
  visaIcon: require('./images/footer/visa.webp'),
  masterIcon: require('./images/footer/mastercard.webp'),
  payIcon: require('./images/footer/pay.webp'),
  gpayIcon: require('./images/footer/gpay.webp'),
  FACEBOOK: require('./images/footer/footer-face-book.webp'),
  INSTAGRAM: require('./images/footer/footer-instagram.webp'),
  LINKEDIN: require('./images/footer/footer-linkedin.webp'),
  PINTEREST: require('./images/footer/pinterest.webp'),
  YOUTUBE: require('./images/footer/footer-youtube.webp'),
  TWITTER: require('./images/footer/twitter.webp'),
  google: require('./images/home/google.webp'),
  apple: require('./images/home/apple.webp'),
  rightIcon: require('./images/home/righticon.webp'),
  locationRight: require('./images/home/artistssign.webp'),
  checked: require('./images/icons/checked.webp'),
  pending: require('./images/icons/pending.webp'),
  checkedNew: require('./images/icons/checkgreen.webp'),
  lockBlack: require('./images/icons/lockBlack.webp'),
  pinktick: require('./images/icons/pinktick.webp'),
  pendingNew: require('./images/icons/pendingNew.webp'),
  map: require('./images/productdetail/map.webp'),
  imagePlaceholder: require('./images/image-placeholder.webp'),
  facebook: require('./images/icons/facebook.webp'),
  pinterest: require('./images/icons/pinterest.webp'),
  imprimo: require('./images/icons/imprimo.webp'),
  tikTok: require('./images/icons/tik-tok.webp'),
  linkedin: require('./images/icons/linkedin.webp'),
  twitter: require('./images/icons/twitter.webp'),
  instagram: require('./images/icons/instagram.webp'),
  addFile: require('./images/icons/add-file.webp'),
  addImage: require('./images/icons/add-image.webp'),
  eyebal: require('./images/icons/eyebal.webp'),
  download: require('./images/icons/download.webp'),
  send: require('./images/icons/send.webp'),
  kidWithArt: require('./images/home/kidwithart.webp'),
  action: require('./images/notification/action.webp'),
  done: require('./images/notification/done.webp'),
  PDFLogo: require('./images/home/cosimo-pdf-logo.webp'),
  card: require('../assets/images/card/card.webp'),
  visa: require('../assets/images/card/visa.webp'),
  mastercard: require('../assets/images/card/mastercard.webp'),
  amex: require('../assets/images/card/amex.webp'),
  discover: require('../assets/images/card/discover.webp'),
  jcb: require('../assets/images/card/jcb.webp'),
  dinner_club: require('../assets/images/card/dinner-club.webp'),
  union_pay: require('../assets/images/card/union-pay.webp'),
  Ellips: require('../assets/images/productdetail/Ellipse.webp'),
  Info: require('../assets/images/icons/info.webp'),
  avtarPlaceholder: require('../assets/images/avtar-placholder.webp'),
  celebrateImg: require('../assets/images/home/celebrate.png'),
  uploadFile: require('./images/upload_file.webp'),
  blacktick: require('./images/notification/EllipseBlack.webp'),
  tickNew: require('./images/notification/EllipseNew.png'),
  directoryLoction: require('./images/icons/location_new.webp'),
  cartNew: require('./images/icons/cart_new.webp'),
  cosimoIcon: require('./images/icons/logo512.webp'),
  filterIcon: require('./images/icons/filter.webp'),
  academyNew: require('./images/home/academy.webp'),
  message: require('./images/home/messageImg.svg'),
  community: require('./images/home/community.webp'),
  home: require('./images/home/home.webp'),
  portfolio: require('./images/home/portfolio.webp'),
  salesmanager: require('./images/home/salesmanager.webp'),
  support: require('./images/home/support.webp'),
  account: require('./images/home/account_new.webp'),
  pngtreeGray: require('../assets/images/pngtree-gray.jpg'),
  hat: require('../assets/images/hat.png'),
};
