import { Container, Typography } from '@mui/material';

const ReturnPolicy = () => {
  return (
    <div className="retrun-policy">
      <Container>
        <section className="first-section">
          <Typography variant="h2" className="first-heading">
            Returns Policy
          </Typography>
          <Typography paragraph className="headign-para">
            We want you to be happy with your purchase, and we offer a 2-day money-back guarantee on all items sold on
            Cosimo. If you are not satisfied with your purchase for any of the following reasons, you can return it for
            a full refund:
          </Typography>
          <ul>
            <li>The artwork was damaged in transit.</li>
            <li>The artwork was significantly not as described.</li>
            <li>The artwork was counterfeit or fraudulent.</li>
            <li>The artwork did not turn up at all.</li>
          </ul>
          <Typography paragraph className="headign-para">
            To return an item, please contact us within 2 days of the expected delivery date. We will provide you with a
            return shipping label and instructions on how to return the item to the artist.
            <br />
            <br />
            The item must be returned in its original condition, with all original packaging and materials. The item
            must also be unused and undamaged.
            <br />
            <br />
            If the item is not returned in its original condition, we may not be able to offer a full refund.
          </Typography>
        </section>
        <section className="second-section">
          <Typography variant="h4" className="second-heading">
            Other Returns
          </Typography>
          <Typography paragraph className="headign-para">
            If you are not satisfied with your purchase for any other reason, you will need to contact the artist
            directly to arrange a return. Cosimo will not be able to help with these types of returns.
          </Typography>
        </section>
        <section className="third-section">
          <Typography variant="h4" className="third-heading">
            Return Shipping
          </Typography>
          <Typography paragraph className="headign-para">
            In some cases, buyers may be liable to cover the costs of return shipping. We will send them the label for
            the shipping to return the item to the artist.
          </Typography>
        </section>
        <section className="fourth-section">
          <Typography variant="h4" className="fourth-heading">
            Refunds
          </Typography>
          <Typography paragraph className="headign-para">
            Once the artist has received the returned item, or after 2 days of being meant to receive it back, we will
            process the refund for the full price of the artwork. The refund will be issued to the same payment method
            that you used to make the purchase.
          </Typography>
        </section>
        <section className="contact-section">
          <Typography variant="h4" className="contact-heading">
            Contact Us
          </Typography>
          <Typography paragraph className="headign-para">
            If you have any questions about our returns policy, please contact us. We are happy to help! Additional
            Information
          </Typography>
          <ul>
            <li>You can only return items that have been purchased directly from Cosimo.art.</li>
            <li>Items that have been purchased from a third-party seller are not eligible for a refund.</li>
            <li>
              You can only return items that have been shipped to the address that you provided when you made your
              purchase.
            </li>
            <li>You cannot return items that have been damaged in transit.</li>
            <li>You cannot return items that have been lost or stolen.</li>
            <li>You cannot return items that have been purchased with a gift card.</li>
          </ul>
        </section>
        <section className="item-return">
          <Typography variant="h4" className="item-heading">
            How to Return an Item
          </Typography>
          <Typography paragraph className="headign-para">
            To return an item, please follow these steps:
          </Typography>
          <ol>
            <li>Contact us within 2 days of the expected delivery date.</li>
            <li>Provide us with your order number and the reason for your return.</li>
            <li>
              We will provide you with a return shipping label and instructions on how to return the item to the artist.
            </li>
            <li>
              Pack the item in accordance with the Seller&apos;s instructions and take photographs of the packed item as
              proof of compliance.
            </li>
            <li>Return the item to the address that we provide via the appropriate courier.</li>

            <li>
              Once the artist has confirmed they have received the returned item, or after 2 days of being meant to
              receive it back, we will process the refund, provided that the artist is content that the artwork has been
              returned undamaged and in the same condition as it was when it was shipped.
            </li>
            <li>The refund will be issued to the same payment method that you used to make the purchase.</li>
          </ol>
        </section>
        <section className="dispute-resolution">
          <Typography variant="h4" className="sec-heading">
            Dispute Resolution
          </Typography>
          <Typography paragraph className="dispute-heading">
            If you are not satisfied with the outcome of the return process, you may contact Cosimo customer support for
            assistance. Cosimo will review the situation and make a final decision.
          </Typography>
          <Typography variant="h4" className="sec-heading">
            Additional Terms and Conditions
          </Typography>
          <ul>
            <li>Buyers may be responsible for the cost of shipping the item back to the artist.</li>
            <li>Buyers are responsible for the risk of loss or damage to the item during shipping.</li>
            <li>Cosimo is not responsible for any loss or damage to the item during shipping.</li>
            <li>Cosimo reserves the right to refuse any return for any reason.</li>
          </ul>
          <Typography variant="h4" className="policy-heading">
            Changes to the Returns Policy
          </Typography>
          <Typography paragraph className="policy-para">
            Cosimo reserves the right to change our Returns Policy at any time. Any changes will be effective
            immediately upon posting on the Cosimo website. It is your responsibility to check both the site terms and
            conditions, and the returns policy, regularly for changes. Your continued use of the Cosimo website after
            any changes to these policies will constitute your acceptance of those changes.
          </Typography>
        </section>
      </Container>
    </div>
  );
};
export default ReturnPolicy;
