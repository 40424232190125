import { useMemo } from 'react';
import { images } from '../../assets';
import { formatDate } from '../../hooks/formatDate';

export const SigngleMsgView = ({ className, data, sendTime, sendDate }) => {
  const active = {
    border: '1px solid #EC425B',
  };

  // const sendTime = useMemo(
  //   () => formatDate(data?.last_message?.created_at, null, 'HH:mm')[data?.last_message?.created_at],
  // );
  // const sendDate = useMemo(() => formatDate(data?.last_message?.created_at)[data?.last_message?.created_at]);

  return (
    <div className={`message-card ${className}`} style={active}>
      <div className="profile-img">
        <img src={data?.receiver?.v_image || images.imagePlaceholder} width={42} height={42} alt="" />
      </div>
      <div className="buyer-details">
        <div className="buyer">
          <h3>{data?.receiver?.full_name}</h3>
        </div>
        <p className="msg-date">
          Last message: {sendTime} {sendDate}
        </p>
      </div>
    </div>
  );
};
