/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { TextField, Tooltip, Typography, tooltipClasses } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import { EmptyText } from '../../components/universal/EmptyText';
import PaginationBox from '../../components/universal/PaginationBox';
import Share from '../../components/universal/Share';
import { baseURL, globalWindow, pageLimitOptions, truncateMessage } from '../../core/constant';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import navigator from '../../utils/navigator';
import notify from '../../utils/notify';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 550,
      fontSize: 20,
      border: '1px solid #dadde9',
    },
  }),
);

export const GenrateCertificate = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');
  const orderByParams = searchParam.get('order_by');

  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [artworkData, setArtworkData] = useState([]);
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(12);
  const [isLoading, setIsLoading] = useState(false);
  const [parameters, setParameters] = useState({});
  const isShowPagination = !isLoading && artworkData?.total > limit;

  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;

  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  const getArtwork = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: '/artist/getCertificates',
        method: 'GET',
        params: {
          // artist: userDetails?.user?.id,
          // artistId: userDetails?.user?.id,
          // slug: userDetails?.user?.v_artists_slug,
          page,
          limit,
          order_by: orderByParams,
          search_keyword: search,
        },
        token: userDetails?.token,
      });
      setArtworkData(res?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [orderByParams, page, limit, search, userDetails?.token]);

  const generateCertificate = async (id) => {
    try {
      const res = await AxiosAll('get', `artist/generateCertificate/${id}`, {}, userDetails?.token);
      notify(res?.data?.message, 'success');
      getArtwork();
    } catch (error) {
      console.log('error', error);
    }
  };

  const downloadCertificate = async (data) => {
    try {
      globalWindow?.open(`${baseURL}downloadCertificate/${data?.id}`, '_blank');
    } catch (error) {
      console.log('error', error);
    }
  };

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    getArtwork();
  }, [getArtwork]);

  useEffect(() => {
    document.getElementById('top').scrollIntoView({
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams, limit]);

  return (
    <div className="certificate">
      <div className="topsearchdiv" style={{ position: 'relative' }} id="top">
        <div className="search-container">
          <TextField
            placeholder="Search Artworks"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            className="topsearchbar"
          />
          <Link href className="searchicon">
            <div tabIndex={0} role="button">
              <img src={images.searchIcon} alt="search icon" className="searciconimg" />
            </div>
          </Link>
        </div>
      </div>
      {isLoading ? (
        <div className="empty-data">
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            Loading...
          </Typography>
        </div>
      ) : artworkData?.data?.length === 0 ? (
        <EmptyText text="Certificate" />
      ) : (
        artworkData?.data?.map((item) => {
          return item?.certificate_generated === 0 ? (
            <CardContainer className="genrate" key={item?.id}>
              <HtmlTooltip title={item?.artwork?.v_title} placement="top-start">
                <Typography variant="h5">
                  {truncateMessage(item?.artwork?.v_title, 10)} - {item?.shipping_size?.v_name}
                </Typography>
              </HtmlTooltip>
              {plan?.v_certificate_of_autheticity === 'Yes' && item?.seller?.allow_certificate ? (
                <DarkBtn title="Generate Certificate" onClick={() => generateCertificate(item?.id)} />
              ) : (
                <DarkBtn title="Upgrade Plan" onClick={() => navigate('/my-subscription')} />
              )}
            </CardContainer>
          ) : (
            <CardContainer className="genrate-edit" key={item?.id}>
              <Typography variant="h5">
                {item?.artwork?.v_title} - {item?.shipping_size?.v_name}
              </Typography>

              {plan?.v_certificate_of_autheticity === 'Yes' && item?.seller?.allow_certificate ? (
                <div className="eidt">
                  <div className="icons">
                    <img
                      src={images?.eyebal}
                      alt=""
                      className="eyebal"
                      onClick={() => navigate(`/portfolio/certificates/${item.id}`)}
                    />
                    <img src={images?.download} alt="" className="download" onClick={() => downloadCertificate(item)} />
                    <Share url={`${process.env.REACT_APP_SELLER_APP_URL}certificate/${item?.id}`} showSendImage />
                  </div>
                  <DarkBtn title="Edit" onClick={() => navigate(`/portfolio/artworks/${item?.artwork_id}`)} />
                </div>
              ) : (
                <div className="eidt">
                  <DarkBtn title="Upgrade Plan" onClick={() => navigate('/my-subscription')} />
                </div>
              )}
            </CardContainer>
          );
        })
      )}

      {isShowPagination && (
        <PaginationBox
          count={artworkData?.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}
    </div>
  );
};
