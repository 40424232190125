import { Button, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup'; // Import Yup
import { useFormik } from 'formik';
import { ChangePassword } from '../../components/dialogs/ChangePassword';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CustomizedSwitches } from '../../components/universal/Buttons/toggle';
import { CardContainer } from '../../components/universal/CardContainer';
import TextFieldInput from '../../components/universal/InputTypeText/TextField';
import Share from '../../components/universal/Share';
import InputFileUpload from '../../components/universal/UploadFile';
import { notifyMessages } from '../../core/constant';
import { deleteUser, updateUser } from '../../redux/actions/user';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';
import OutlineBtn from '../../components/universal/Buttons/OutLineBtn';
import DeleteMyAccount from '../../components/dialogs/DeleteMyAccount';

const AccountSettings = () => {
  const [open, setOpen] = useState(false);

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: null,
    profileBanner: '',
  });

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [switchState, setSwitchState] = useState({
    marketing_communications: false,
    platform_updates: false,
  });
  const [isDisabled, setIsDisabled] = useState({
    userInfo: true,
    profilePicture: true,
    profileBanner: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profilePictureRef = useRef();
  const { userDetails } = useSelector((state) => state.user);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const updateUserProfile = async (data) => {
    setLoading(true);
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data,
        token: userDetails?.token,
      });
      await getUserProfile();
      dispatch(updateUser(res?.data));
      notify(res?.message, 'success');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      setProfileData(res?.data);
      setUser((prev) => ({
        ...prev,
        profilePicture: res?.data?.v_image || '',
      }));
      setSwitchState({
        marketing_communications: res?.data?.marketing_communications === 1,
        platform_updates: res?.data?.platform_updates === 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      await getUserProfile();
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitchChange = (e) => {
    const { name } = e.target;
    setSwitchState((prev) => ({ ...prev, [name]: !switchState[name] }));
    updateUserProfile({ [name]: switchState[name] ? 0 : 1 });
  };

  const initialValues = {
    firstName: userDetails?.user?.v_fname || '',
    lastName: userDetails?.user?.v_lname || '',
    artistName: userDetails?.user?.v_artist_name || '',
    profilePicture: userDetails?.user?.v_image || null,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    artistName: Yup.string().required('Artist name is required'),
  });

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('v_fname', values?.firstName);
    formData.append('v_lname', values?.lastName);
    formData.append('v_artist_name', values?.artistName);
    if (user?.profilePicture instanceof File) {
      formData.append('v_image', user?.profilePicture);
    }
    await updateUserProfile(formData);
  };

  const deleteProfileImage = async () => {
    const formData = new FormData();
    formData.append('is_delete_profile_image', 1);
    await updateUserProfile(formData);
  };

  const handleDeleteAccount = async () => {
    setLoading(true);

    try {
      const res = await request({
        url: '/artist/deleteAccount',
        method: 'DELETE',
        token: userDetails?.token,
      });
      dispatch(deleteUser());
      notify(res?.message, 'success');
      setOpen(false);
      setLoading(false);
      navigate('/signup');
    } catch (error) {
      notify(error?.message, 'error');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleRemoveProfilePic = () => {
    if (user?.profilePicture) {
      profilePictureRef.current.value = '';
      setUser((prev) => ({ ...prev, profilePicture: '' }));
      setIsDisabled({ ...isDisabled, profilePicture: true });
    }
  };

  const handleProfilePicURL = (image) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image;
  };

  const handleImageUpload = (e) => {
    if (e?.target?.files[0]?.size > 2097152) {
      notify(notifyMessages?.fileTooLarge, 'error');
      return;
    }
    setUser((prev) => ({
      ...prev,
      profilePicture: e.target.files[0],
    }));
    formik.setFieldValue('profilePicture', e.target.files[0]);
  };

  return (
    <div className="myprofilerightsection  ">
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        className="my-account-new"
      >
        <h2 className="font25px maintitle">Your Personal Information</h2>
      </Stack>
      <div className="profilesection  gray_theme_update">
        <form onSubmit={formik.handleSubmit}>
          <div className="upload_delete_photo">
            <div className="upload_section">
              <InputFileUpload
                deleteProfileImage={deleteProfileImage}
                title="Add Profile Picture"
                pictureRef={profilePictureRef}
                image={user?.profilePicture ? handleProfilePicURL(user?.profilePicture) : userDetails?.user?.v_image}
                onChange={(e) => handleImageUpload(e)}
                showEditIcon
                alt="Profile Picture"
              />
              <Stack direction="row" alignItems="center" spacing={3}>
                {user?.profilePicture instanceof File && !isDisabled?.profilePicture && (
                  <Typography
                    onClick={handleRemoveProfilePic}
                    variant="body1"
                    className="font16px"
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Remove
                  </Typography>
                )}
              </Stack>
            </div>
          </div>
          <Stack direction="row" spacing={2} sx={{ mb: 2.5 }}>
            <div className="nameemailsection">
              <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                First Name
              </Typography>
              <TextField
                placeholder="First Name"
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik?.errors.firstName && formik?.touched?.firstName && (
                <FormHelperText error>{formik?.errors.firstName}</FormHelperText>
              )}
            </div>

            <div className="nameemailsection">
              <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                Last Name
              </Typography>
              <TextField
                placeholder="Last Name"
                name="lastName"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik?.errors?.lastName && formik?.touched?.lastName && (
                <FormHelperText error>{formik?.errors?.lastName}</FormHelperText>
              )}
            </div>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
            <div className="nameemailsection">
              <Typography className="font18px" variant="body1" sx={{ mb: 1 }}>
                Artist Name
              </Typography>
              <TextField
                placeholder="Artist Name"
                name="artistName"
                type="text"
                value={formik.values.artistName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik?.errors.artistName && formik?.touched?.artistName && (
                <FormHelperText error>{formik?.errors.artistName}</FormHelperText>
              )}
            </div>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2.5 }}>
            <div className="nameemailsection">
              <Typography variant="body1" className="font18px" sx={{ mb: 1 }}>
                Email
              </Typography>
              <TextField placeholder="Email" name="email" type="email" value={userDetails?.user?.v_email} disabled />
            </div>
          </Stack>
          {formik.dirty && <DarkBtn title="Save" type="submit" loading={loading} disabled={!formik.dirty} />}
        </form>
      </div>
      <CardContainer paddingX={28} paddingY={33} className="shareuri gray_theme_update">
        <div className="font20px shareable_link">Your Shareable Link</div>
        <div className="share_ui_inner">
          <TextFieldInput
            type="text"
            value={`${process.env.REACT_APP_USER_APP_URL}artist/${
              profileData?.v_artist_name || `${profileData?.v_fname}-${profileData?.v_lname}`
            }/${profileData?.user_info?.i_user_id}`}
          />
          <Share
            url={`${process.env.REACT_APP_USER_APP_URL}artist/${
              profileData?.v_artist_name || `${profileData?.v_fname}-${profileData?.v_lname}`
            }/${profileData?.user_info?.i_user_id}`}
            title="Share Profile URL"
            onClick={(handleOpen) => {
              if (!profileData?.user_info?.i_user_id) {
                notify(notifyMessages?.completeProfileMsg, 'error');
                navigate('/personalize');
                return;
              }
              handleOpen();
            }}
          />
        </div>
      </CardContainer>
      <div className="requestlinksection  gray_theme_update">
        <p className="font20px">Reset Password</p>
        <ChangePassword />
      </div>
      <CardContainer paddingX="3rem" gap={0} className="profile-checks gray_theme_update">
        <Typography variant="h6" className="check-profile">
          Email Preferences
        </Typography>
        <div className="profile-switch">
          <Typography variant="h6">Marketing Communications</Typography>
          <CustomizedSwitches
            name="marketing_communications"
            checked={switchState.marketing_communications}
            onChange={handleSwitchChange}
          />
        </div>
        <div className="profile-switch ">
          <Typography variant="h6">Platform Updates</Typography>
          <CustomizedSwitches
            name="platform_updates"
            checked={switchState.platform_updates}
            onChange={handleSwitchChange}
          />
        </div>
      </CardContainer>

      <CardContainer paddingX="1.538rem" paddingY="0.875rem" gap={0} className="profile-checks ">
        <div className="profile-switch" style={{ marginBottom: 0 }}>
          <div className="font14px" style={{ color: '#F5445E' }}>
            Delete my account
          </div>
          {/* <OutlineBtn title="Select" styleBtn="select_new_btn"/> */}
          <Button className="outlinebtn select_new_btn" onClick={handleClickOpen}>
            Select
          </Button>
          {open && (
            <DeleteMyAccount open={open} setOpen={setOpen} loading={loading} handleDelete={handleDeleteAccount} />
          )}
        </div>
      </CardContainer>
    </div>
  );
};

export default AccountSettings;
