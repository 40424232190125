import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldInput from '../InputTypeText/TextField';
import { GreenBtn } from '../universal/Buttons/GreenBtn';
import notify from '../../utils/notify';

const DynamicTable = ({
  columns,
  data = [], // Add default empty array
  onChange,
  onEdit,
  onAddNew,
  onDelete,
  errors = [],
  touched = [],
  handleBlur,
  keyData,
  dropdownOptions = [],
}) => {
  // Modified check for unsaved rows with safety check
  const hasUnsavedRows = Array.isArray(data) && data.some((row) => row?.isEdit);

  // Add a helper function to check for errors in the current row
  const hasErrorsInRow = (rowErrors) => {
    if (!rowErrors) return false;
    return Object.keys(rowErrors).length > 0;
  };

  // Modified onAddNew handler with validation
  const handleAddNew = () => {
    if (hasUnsavedRows) {
      // Show error notification
      // You'll need to import your notification system
      notify('Please save the current row before adding a new one', 'error');
      return;
    }
    onAddNew();
  };

  const renderTableCell = (row, col, rowIndex, fieldName, rowErrors, rowTouched) => {
    if (!row) return null;
    const isDisabled = !row.isEdit;

    switch (col.type) {
      case 'dropdown':
        return (
          <>
            <Select
              fullWidth
              className="selectbox_new"
              size="small"
              name={fieldName}
              value={row[col.field] || ''}
              onChange={(e) => onChange(rowIndex, col.field, e.target.value)}
              onBlur={handleBlur}
              error={Boolean(rowErrors[col.field] && rowTouched[col.field])}
              disabled={isDisabled}
            >
              <MenuItem value="">Select {col.label}</MenuItem>
              {dropdownOptions.map((option) => (
                <MenuItem key={option.v_label_name} value={option.v_label_name}>
                  {option.v_label_name}
                </MenuItem>
              ))}
            </Select>
            {rowErrors[col.field] && rowTouched[col.field] && (
              <div className="error-text" style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '3px' }}>
                {rowErrors[col.field]}
              </div>
            )}
          </>
        );
      default:
        return (
          <TextFieldInput
            name={fieldName}
            value={row[col.field] || ''}
            onBlur={handleBlur}
            onChange={(e) => onChange(rowIndex, col.field, e.target.value)}
            fullWidth
            error={Boolean(rowErrors[col.field] && rowTouched[col.field])}
            helperText={rowErrors[col.field] && rowTouched[col.field] ? rowErrors[col.field] : ''}
            disabled={isDisabled}
          />
        );
    }
  };

  return (
    <Box className="button_section card-container gray_theme_update">
      <TableContainer component={Paper} className="cv_builder_table ">
        <Table>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              {columns?.map((col, index) => (
                <TableCell key={index}>{col.label}</TableCell>
              ))}
              {data?.length > 0 && <TableCell>Actions</TableCell>} {/* Hide Actions column if no data */}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {Array.isArray(data) && data.length > 0
              ? data?.map((row, rowIndex) => {
                  const rowErrors = errors[rowIndex] || {};
                  const rowTouched = touched[rowIndex] || {};

                  return (
                    <TableRow key={rowIndex}>
                      {columns.map((col, colIndex) => {
                        const fieldName = `${keyData}[${rowIndex}].${col.field}`;
                        return (
                          <TableCell key={colIndex}>
                            {renderTableCell(row, col, rowIndex, fieldName, rowErrors, rowTouched)}
                          </TableCell>
                        );
                      })}

                      {/* Always show action buttons */}
                      <TableCell>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <Button
                            variant="contained"
                            className={row?.isEdit ? 'table_save_btn' : 'table_edit_btn'}
                            onClick={() => onEdit(rowIndex)}
                            // Disable save button if there are errors and row is in edit mode
                            disabled={row?.isEdit && hasErrorsInRow(errors[rowIndex])}
                          >
                            {row?.isEdit ? 'Save' : 'Edit'}
                          </Button>
                          {/* <Button
                            variant="contained"
                            className="table_delete_btn"
                            onClick={() => onDelete(rowIndex)}
                            style={{ backgroundColor: '#ff4444' }}
                          >
                            Delete
                          </Button> */}
                          <IconButton onClick={() => onDelete(rowIndex)} className="delete-icon-btn">
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}

            {/* Add New Row Button */}
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Button variant="contained" className="table_add_btn" onClick={handleAddNew}>
                  + Add New
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DynamicTable;
