import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { RefundModal } from '../dialogs/Refundmodal';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { formatDate } from '../../hooks/formatDate';

/* eslint-disable jsx-a11y/anchor-is-valid */
export const ShippingOrderDetial = ({ data }) => {
  return (
    <div className="detials">
      <div className="detials-headings">
        <h3>Name:</h3>
        <h3>{`${data?.order?.v_shipping_fname} ${data?.order?.v_shipping_lname}`}</h3>
      </div>
      <div className="detials-headings">
        <h3>House No.:</h3>
        <h3>{data?.order?.v_shipping_address_line_1}</h3>
      </div>
      <div className="detials-headings">
        <h3>Road:</h3>
        <h3>{data?.order?.v_shipping_address_line_2}</h3>
      </div>
      <div className="detials-headings">
        <h3>Country/Region:</h3>
        <h3>{data?.order?.v_shipping_country}</h3>
      </div>

      <div className="detials-headings">
        <h3>Email:</h3>
        <h3>{data?.order?.v_shipping_email}</h3>
      </div>
    </div>
  );
};

export const ShippingInPersonOrderDetail = ({ data, inPerson }) => {
  return inPerson ? (
    <div className="detials">
      {data?.v_order_status == 'Completed' ? (
        <>
          <div className="detials-headings">
            <h3>You marked the order as 'Collected' which means it was picked up by an art lover</h3>
          </div>
          <br />
          <div className="detials-headings">
            <h3>The art lover can still contact you, Even after the order has been collected.</h3>
          </div>
        </>
      ) : (
        <>
          <div className="detials-headings">
            <h3>Once you mark the order as ready for collection</h3>
          </div>
          <br />
          <div className="detials-headings">
            <h3>you need to coordinate with the art lover to arrange the pickup.</h3>
          </div>
        </>
      )}
    </div>
  ) : data?.v_order_status == 'Cancelled' ? (
    <div className="detials">
      <div className="detials-headings">
        <h3>The order is been canceled</h3>
      </div>
    </div>
  ) : (
    <div className="detials">
      <div className="detials-headings">
        <h3>The buyer has requested to collect this artwork in person.</h3>
      </div>
      <br />
      <div className="detials-headings">
        <h3>Once you have accepted the order you will have 7 days to process the artwork.</h3>
      </div>
      <br />
      <div className="detials-headings">
        <h3>Once the buyer has the artwork, it is your responsibility to mark it as delivered.</h3>
      </div>
      <br />
      <div className="detials-headings">
        <h3>You will need to message the buyer to facilitate this.</h3>
      </div>
    </div>
  );
};

export const DisputeCard = ({ data, update }) => {
  return (
    <div className="raise-dispute-detials">
      <h2>Dispute Summary</h2>
      <div className="artlovertext">Art Lover</div>
      <div className="raise-dispute-headings">
        <h3>Reason:</h3>
        <h4>{data?.v_dispute_reason?.replaceAll('-', ' ')}</h4>
      </div>
      <div className="raise-dispute-headings">
        <h3>Comment:</h3>
        <h4>{data?.v_dispute_comment}</h4>
      </div>
      <div className="raise-dispute-headings">
        {data?.v_dispute_status == 'No' ? (
          <p className="issue">To resolve the issue: </p>
        ) : (
          <p className="marked">You have marked it resolved with: </p>
        )}
        <div className="artlovertext">You (Artist)</div>
      </div>
      {data?.v_dispute_status == 'No' ? (
        <RefundModal orderData={data} update={update} />
      ) : (
        <>
          <div className="raise-dispute-headings">
            <h3>Resolution Type:</h3>
            <h4>{data?.v_dispute_res_type?.replaceAll('_', ' ')}</h4>
          </div>
          <div className="raise-dispute-headings">
            <h3>Comment:</h3>
            <h4>{data?.v_dispute_res_comment}</h4>
          </div>
        </>
      )}

      <div className="raise-dispute-headings">
        <p className="details-discription">
          For more details please connect with <a href="/support">Support</a> team
        </p>
      </div>
    </div>
  );
};

export const CancelCard = ({ data }) => {
  return (
    <div className="cancel-card">
      <Typography variant="h2">Cancellation Summary</Typography>
      {data?.v_order_accepted === 'Rejected' ? (
        <Typography>You have Cancelled the order.</Typography>
      ) : (
        <Typography>The Art Lover has Cancelled the order. </Typography>
      )}
      <Typography>
        Cancellation Time: <span className="canceldata">{formatDate(data?.d_cancellation_date, true)}</span>{' '}
      </Typography>
      <Typography>
        Cancellation Reason: <span className="canceldata">{data?.l_cancellation_reason?.replace('Seller', 'You')}</span>
      </Typography>
    </div>
  );
};

export const RefunCard = ({ data }) => {
  return (
    <div className="refun-card">
      <Typography variant="h2">Refund Summary</Typography>
      <Typography>You have initiated a refund process. </Typography>
      <Typography>Amount : £{data?.f_refund_amount?.toFixed(2)}</Typography>
      <Typography>Time: {formatDate(data?.v_refund_time, true)}</Typography>
      <Typography>Refund Status: {data?.v_refund_status}</Typography>
      <Typography>Comment: {data?.v_dispute_res_comment}</Typography>
      <br />
      <p>
        Rest assured, refund will be credited to Art lover's account shortly as per our
        <a className="" href="/return-policy">
          {' '}
          refund Policy
        </a>
        . If you encounter any issues, please contact our{' '}
        <a className="" href="/contact-us">
          {' '}
          support
        </a>{' '}
        team for assistance.
      </p>
    </div>
  );
};
