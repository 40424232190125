import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router';
import DarkBtn from '../../universal/Buttons/DarkBtn';

// eslint-disable-next-line react/function-component-definition
export default function PricingCalculator({ open, setOpen }) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const handleNavigate = () => {
    navigate('/my-subscription');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="new_contact_dialogbox upgrade_dialog_box"
    >
      <DialogContent className="contact_inner">
        <div className="font12px para_text">
          To access the pricing calculator, please subscribe to one of our non-academic plans.
        </div>
        <Stack direction="column" spacing={2}>
          <DarkBtn title="Upgrade Plan" onClick={handleNavigate} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
