import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { images } from '../../assets';
import { EmptyNotification } from '../../components/universal/EmptyNotification';
import { NotificationPopUp } from '../../components/universal/NotificationPopUp';
import PaginationBox from '../../components/universal/PaginationBox';
import ImageCard from '../../components/universal/imageCard';
import { globalWindow, pageLimitOptions, truncateMessage } from '../../core/constant';
import { formatDate } from '../../hooks/formatDate';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';

const RenderNotifications = ({ item, readNotification }) => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 350;

  return (
    <div className="notificationbar">
      <div className="nottext font20px">{truncateMessage(item?.v_title, isMobile ? 15 : 20)}</div>
      <div className="nottime font20px">{formatDate(item?.created_at, null, 'hh:mm A')}</div>
      <div className="notdate font20px">{formatDate(item?.created_at)}</div>
      <div className="noticon ">
        {item?.e_status === '0' ? (
          <ImageCard image={images.action} alt="notification" />
        ) : (
          <ImageCard image={images.done} alt="notification" />
        )}
      </div>
      <div className="notbutton ">
        <NotificationPopUp
          item={item}
          cardWidth={(isToSmall && 280) || (isMobile && 350)}
          readNotification={readNotification}
        />
      </div>
    </div>
  );
};

const MyNotifications = () => {
  const { width } = useWindowSize();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isShowNotifyPagination = notifications?.data?.length > 0 && notifications?.last_page > 1;

  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const getNotifications = useCallback(async () => {
    try {
      const res = await AxiosAll(
        'GET',
        `artLoverNotifications?limit=${limit}&page=${page}&user_type=seller`,
        null,
        userDetails?.token,
      );
      setNotifications(res?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
  }, [limit, page, userDetails?.token]);

  const readNotification = async (id) => {
    try {
      const res = await AxiosAll('POST', `artist/readNotification/${id}`, null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        getNotifications();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    const child = document.getElementById('notification-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    const child = document.getElementById('notification-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    globalWindow?.scrollTo({
      // eslint-disable-next-line no-unsafe-optional-chaining
      top: document?.getElementById('notification-area')?.offsetTop - 40,
      behavior: 'smooth',
    });
  }, [page, limit]);

  return (
    <div className="notifications allnotification" id="notification-area">
      <div className="notiheading font25px">Notifications</div>
      <div className="managepad">
        <div className="notifiarea" id="notification-area">
          {notifications?.data?.map((item, index) => {
            return <RenderNotifications key={index} item={item} readNotification={readNotification} />;
          })}
          {notifications?.data?.length === 0 && <EmptyNotification />}
        </div>
        {isShowNotifyPagination && (
          <PaginationBox
            count={notifications?.last_page}
            page={page}
            onChange={onPageChange}
            value={limit}
            pageLimit={pageLimitOptions}
            onChangePageLimit={onPageLimitChange}
          />
        )}
      </div>
    </div>
  );
};

export default MyNotifications;
