import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { images } from '../../../assets';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputFileUpload = ({ image, pictureRef, alt, onChange, title, showEditIcon, deleteProfileImage }) => {
  const handleClick = () => {
    if (!showEditIcon) {
      pictureRef?.current?.click();
    }
  };

  // Function to get the file name from URL
  const getFileNameFromUrl = (url) => {
    return url.split('/').pop();
  };

  // Check if the image is 'profile-image.png'
  const isProfileImage = image ? getFileNameFromUrl(image) === 'profile-image.png' : false; // We disable the delete button when the profile image is not set and the default 'profile-image.png' is coming from api.

  return (
    <>
      <Button
        onClick={handleClick}
        component="label"
        variant="outlined"
        sx={{ flexDirection: 'column' }}
        className="upload-file_new"
      >
        <img src={image || images.uploadFile} alt={alt} className={image ? 'upload_full_img' : 'upload_default_img'} />
        {!image && <span className="font18px profile_text">{title || 'Add Picture'}</span>}
      </Button>
      {showEditIcon && (
        <div style={{ marginLeft: '30px' }}>
          <Button className="upload_photo_btn font12pxNew" onClick={() => pictureRef?.current?.click()}>
            Upload Photo
          </Button>
          <div>
            <Button className="delete_photo_btn font12pxNew" onClick={deleteProfileImage} disabled={isProfileImage}>
              Delete Photo
            </Button>
          </div>
        </div>
      )}
      <VisuallyHiddenInput type="file" ref={pictureRef} accept="image/x-png ,image/jpeg" onChange={onChange} />
    </>
  );
};

export default InputFileUpload;
