import { combineReducers } from 'redux';
import userReducer from './user';
import subscriptionReducer from './subscription';
import messagesReducer from './messages';

export const rootReducer = combineReducers({
  subscription: subscriptionReducer,
  user: userReducer,
  messages: messagesReducer,
});

export default rootReducer;
