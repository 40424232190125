export const CardContainer = ({
  children,
  height,
  width,
  borderRadius,
  paddingX,
  paddingY,
  columnGap,
  rowGap,
  bottomMargin,
  topMargin,
  className,
}) => {
  return (
    <div
      className={`card-container ${className}`}
      style={{
        height,
        width,
        paddingLeft: paddingX || '2rem',
        paddingRight: paddingX || '2rem',
        paddingBottom: paddingY || '1.25rem',
        paddingTop: paddingY || '1.25rem',
        columnGap: columnGap || '2rem',
        rowGap: rowGap || '2rem',
        marginBottom: bottomMargin || '2rem',
        marginTop: topMargin || '2rem',
      }}
    >
      {children}
    </div>
  );
};
