import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { images } from '../../assets';
import DarkBtn from '../universal/Buttons/DarkBtn';

const BannerUpload = ({ initialImage, onImageCropped }) => {
  const [imageSrc, setImageSrc] = useState(initialImage);
  const [isCropping, setIsCropping] = useState(false);
  // Modified initial crop state to have x, y coordinates and specific dimensions
  const [crop, setCrop] = useState({
    unit: '%',
    width: 80,
    height: 45, // Maintaining 16:9 aspect ratio
    x: 10, // Center horizontally
    y: 10, // Center vertically
    aspect: 16 / 9,
  });
  const [imageSelected, setImageSelected] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setIsCropping(true);
        setImageSelected(true);
        // Reset crop to initial values when new image is selected
        setCrop({
          unit: '%',
          width: 80,
          height: 45,
          x: 10,
          y: 10,
          aspect: 16 / 9,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const enableCroppingAgain = () => {
    setIsCropping(true);
  };

  const handleCropComplete = () => {
    if (!imgRef.current || !crop.width || !crop.height) return;

    const canvas = previewCanvasRef.current;
    const image = imgRef.current;
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;

    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    canvas.toBlob((blob) => {
      if (blob) {
        const croppedFile = new File([blob], 'croppedImage.jpg', { type: 'image/jpeg' });
        const croppedURL = URL.createObjectURL(croppedFile);
        setImageSrc(croppedURL);
        setIsCropping(false);
        onImageCropped(croppedFile);
      }
    }, 'image/jpeg');
  };

  return (
    <>
      {isCropping ? (
        <>
          <div className="font20px banner_img">Crop Your Banner</div>
          <div className="font12px banner_img">
            {' '}
            The area within the selected space will appear as the background to your homepage.
          </div>
        </>
      ) : (
        <div className="font12px banner_img"> Banner Image </div>
      )}
      <div style={{ textAlign: 'center' }}>
        <div className="banner_upload_new" style={{ position: 'relative', width: '100%' }}>
          {imageSrc && !isCropping && (
            <div className="image-container">
              <img src={imageSrc} alt="Profile Banner" className="banner-image" />
              <div className="hover-text">
                <Box
                  onClick={() => fileInputRef.current.click()}
                  className="crop_upload_section"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px dashed #aaa',
                    borderRadius: '10px',
                    padding: '20px',
                    width: '100%',
                    cursor: 'pointer',
                    backgroundColor: '#f9f9f9',
                    '&:hover': { backgroundColor: '#f1f1f1' },
                  }}
                >
                  <img src={images.uploadFile} alt="banner" className="croping_full_img" />
                  <div className="font20px banner_upload_text_new" fontWeight="bold">
                    Add Banner Image
                  </div>
                  <div className="font12px upload_text_new">
                    To upload an image that appears perfectly proportioned, use a 1432 x 809 (width:height) aspect
                    ratio.
                  </div>
                </Box>
              </div>
            </div>
          )}
        </div>
        {isCropping && (
          <>
            <ReactCrop crop={crop} onChange={setCrop} aspect={16 / 9}>
              <img ref={imgRef} src={imageSrc} alt="Crop" style={{ Width: '100%' }} />
            </ReactCrop>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <DarkBtn title="Save" onClick={handleCropComplete} styleBtn="crop_btn" />
            </Stack>
          </>
        )}

        <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
        {!isCropping && imageSrc && imageSelected && (
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
            <DarkBtn title="Crop banner" onClick={enableCroppingAgain} styleBtn="crop_btn" />
          </Stack>
        )}
        {!imageSrc && (
          <Box
            onClick={() => fileInputRef.current.click()}
            className="crop_upload_section"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px dashed #aaa',
              borderRadius: '10px',
              padding: '20px',
              width: '100%',
              cursor: 'pointer',
              backgroundColor: '#f9f9f9',
              '&:hover': { backgroundColor: '#f1f1f1' },
            }}
          >
            <img src={images.uploadFile} alt="banner" className="croping_full_img" />
            <div className="font20px banner_upload_text_new" fontWeight="bold">
              Add Banner Image
            </div>
            <div className="font12px upload_text_new">
              To upload an image that appears perfectly proportioned, use a 1432 x 809 (width:height) aspect ratio.
            </div>
          </Box>
        )}

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
      </div>
    </>
  );
};

export default BannerUpload;
