import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentStatusDialog = () => {
  const [open, setOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Get payment status from URL parameters
    const params = new URLSearchParams(location.search);
    const status = params.get('paymentStatus');

    if (status) {
      setPaymentStatus(status);
      setOpen(true);
      // Remove the paymentStatus parameter from URL without refreshing the page
      const newUrl = location.pathname;
      window.history.pushState({}, '', newUrl);
    }
  }, [location]);

  const handleClose = () => {
    setOpen(false);
  };

  const getDialogContent = () => {
    switch (paymentStatus) {
      case 'success':
        return {
          icon: <CheckCircleIcon sx={{ fontSize: 60, color: '#EC425B' }} />,
          title: 'Payment Successful!',
          message: 'Thank you for your payment. Your transaction has been completed successfully.',
          buttonColor: 'success',
          StyleClass: 'error_message_btn',
        };
      case 'failure':
        return {
          icon: <ErrorIcon sx={{ fontSize: 60, color: 'error.main' }} />,
          title: 'Payment Failed',
          message:
            "We're sorry, but your payment could not be processed. If your amount is currently being deducted, you should expect any refunds to be processed within 2-6 business days. We appreciate your understanding. Thanks!.",
          buttonColor: 'error',
          styles: { background: '#F5445E', boxShadow: 'none' },
          StyleClass: 'error_message_btn',
        };
      default:
        return null;
    }
  };

  const content = getDialogContent();

  if (!content) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          position: 'relative',
        },
      }}
    >
      {/* Close button in the corner */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            py: 3,
          }}
        >
          {content.icon}
          <DialogTitle sx={{ pb: 1, pt: 2 }}>{content.title}</DialogTitle>
          <Typography variant="body1" sx={{ px: 4, pb: 2 }}>
            {content.message}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 3, justifyContent: 'center' }}>
        <Button
          variant="contained"
          color={content.buttonColor}
          onClick={handleClose}
          className={content.StyleClass}
          sx={{
            ...content.styles,
            minWidth: 200,
            borderRadius: 2,
            textTransform: 'none',
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentStatusDialog;
