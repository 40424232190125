import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { images } from '../../../assets';
import { globalWindow } from '../../../core/constant';
import { DesktopViewHeader } from './DesktopViewHeader';
import { MobileViewheader } from './MobileViewheader';

export const Header = ({ headerData }) => {
  const [search, setSearch] = useState('');
  const { userDetails } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const [headerClass, setHeaderClass] = useState('');
  const artists = pathname.includes('artists');

  const searchQuery = `${process.env.REACT_APP_USER_APP_URL}search/${artists ? 'artists' : 'artworks'}/${search
    ?.replaceAll(/[^a-zA-Z0-9 \-]/g, ' ')
    ?.replaceAll(/\//g, '-')
    ?.replaceAll(/\\/g, '-')
    ?.trim()
    ?.replaceAll(/\s\s+/g, ' ')
    ?.replaceAll(' ', '+')}`;

  useEffect(() => {
    if (pathname.includes('search')) {
      const urlSearchQuery = pathname.split('/')[3];
      if (urlSearchQuery) {
        // remove all special characters and spaces from search query
        const decodedSearchQuery = decodeURIComponent(urlSearchQuery);
        const query = decodedSearchQuery.replace(/[^a-zA-Z0-9 \-]/g, ' ');
        setSearch(query);
      }
    } else {
      setSearch('');
    }
  }, [pathname]);

  // function for listening scroll event
  const listenScrollEvent = () => {
    if (globalWindow?.scrollY < 60) {
      return setHeaderClass('');
    }
    if (globalWindow?.scrollY > 60) {
      return setHeaderClass('headerscroll');
    }
    return setHeaderClass('');
  };

  // calling window's listener on load
  useEffect(() => {
    globalWindow?.addEventListener('scroll', listenScrollEvent);

    return () => globalWindow?.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <section className={`header ${headerClass}`}>
      <div className="logosection">
        <MobileViewheader
          userDetails={userDetails}
          headerData={headerData}
          search={search}
          searchQuery={searchQuery}
          setSearch={setSearch}
        />
        <DesktopViewHeader
          userDetails={userDetails}
          headerData={headerData}
          search={search}
          searchQuery={searchQuery}
          setSearch={setSearch}
        />
      </div>
    </section>
  );
};
