import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import CustomTable from '../../components/universal/CustomTable';
import PaginationBox from '../../components/universal/PaginationBox';
import { request } from '../../services/request';
import { pageLimitOptions } from '../../core/constant';
import navigator from '../../utils/navigator';

function createData(ref, value, date, status, id) {
  return { ref, value, date, status, id };
}

const MyOrders = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');
  const orderByParams = searchParam.get('order_by');

  const { userDetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(12);
  const [parameters, setParameters] = useState({});

  const headCells = [
    { label: 'Order Ref' },
    { label: 'Value' },
    { label: 'Date' },
    { label: 'Status' },
    { label: ' ' },
  ];

  const isShowPagination = !isLoading && orders?.total > limit;

  const getOrders = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: '/artist/getMyOrders',
        method: 'GET',
        params: {
          page,
          limit,
        },
        token: userDetails?.token,
      });

      setOrders(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, limit, userDetails?.token]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <div className="artlovermyaccount">
      <CustomTable
        order
        isEmpty={orders?.data?.length < 1}
        rows={orders?.data}
        headCells={headCells}
        heading="Order History"
        routePath="/sales/orders/detail/"
        isButton
        hideCreditBalance
        buttonLabel="Details"
        isShowHeading
        styleName="gray_theme_update"
      />
      {isShowPagination && (
        <PaginationBox
          count={orders?.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}
    </div>
  );
};

export default MyOrders;
