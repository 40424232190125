/* eslint-disable camelcase */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import DarkBtn from '../universal/Buttons/DarkBtn';

const MostPopular = ({ data, link, onSubscribe, module }) => {
  const navigate = useNavigate();
  const {
    most_popular,
    plan_type,
    id,
    v_sale_amount,
    v_amount,
    v_recurring_interval,
    v_display_name,
    v_name,
    v_currency,
    l_description,
  } = data || {};

  const { lessonCount, resourceCount, name } = module;
  const isMostPopular = most_popular;
  const buttonTitle = plan_type === 'onetime' ? 'Buy Now' : 'Subscribe & Save';
  const buttonType = plan_type === 'onetime' ? 'outlinebtn' : '';
  const price = v_sale_amount > 0 ? v_sale_amount : v_amount;
  const paymentText = v_recurring_interval !== 'one_time' ? `${v_recurring_interval}ly` : 'One-Time Payment';
  const filterDescription = l_description?.split(/<br\/>\r?\n?/).filter((item) => item.trim() !== '');
  const oneTimeDescription = `Access '${name}'\nIncludes: ${lessonCount} Video ${
    lessonCount <= 1 ? 'Lesson' : 'Lessons'
  }`;

  const handleClick = () => {
    if (plan_type === 'onetime') {
      onSubscribe(data);
    } else {
      navigate(
        `/checkout?subscription-plan=${id}&method=false&price=${price}&type=${
          v_recurring_interval || 'One-Time Payment'
        }&name=${v_display_name || v_name}`,
      );
    }
  };
  const getPlanDescription = () => {
    if (plan_type === 'onetime') {
      const formattedDescription = oneTimeDescription.replace(/\n/g, '<br />');
      return <div className="font12px" dangerouslySetInnerHTML={{ __html: formattedDescription }} />;
    }
    return filterDescription?.map((dec, i) => {
      const formattedDescription = dec.replace(/\n/g, '<br />');
      return <div className="font12px" key={i} dangerouslySetInnerHTML={{ __html: formattedDescription }} />;
    });
  };
  return (
    <div className={`${isMostPopular === 1 ? 'most_popular_card' : 'unlock_plan_card'}`}>
      {isMostPopular === 1 && <div className="font18px popular_text">Most Popular</div>}
      <div className="popular_inner">
        <div className="font20px purchase_text">{v_display_name || v_name}</div>
        <div className="font20px">
          {v_currency}
          {price}
        </div>
        <div className="font12px payment_text" style={{ textTransform: 'capitalize' }}>
          {paymentText}
        </div>
        {getPlanDescription()}

        <Stack direction="row" className="btn_section">
          <DarkBtn title={buttonTitle} onClick={handleClick} styleBtn={buttonType} />
        </Stack>
      </div>
    </div>
  );
};

export default MostPopular;
