import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SimpleSelectBox } from '../../components/InputTypeText/SelectBox';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';
import { GreenBtn } from '../../components/universal/Buttons/GreenBtn';
import TextFieldInput from '../../components/universal/InputTypeText/TextField';
import PaginationBox from '../../components/universal/PaginationBox';
import { globalWindow, pageLimitOptions } from '../../core/constant';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { TableBodySkeleton } from '../../components/Skeleton/TableBodySkeleton';

export const DiscountPage = () => {
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const { userDetails } = useSelector((state) => state.user);

  const isShowPagination = !loading && discountData?.total > limit;

  const onPageChange = (e, pg) => {
    setPage(pg);
    const child = document.getElementById('discount-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    const child = document.getElementById('discount-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getDiscountList = async () => {
    setLoading(true);
    const res = await AxiosAll(
      'get',
      `artist/artistPromoCodes?per_page=${limit}&page=${page}`,
      null,
      userDetails?.token,
    );

    if (res?.data?.statusCode === 200) {
      setDiscountData(res?.data?.data);
      const discountLists = res?.data?.data?.data?.map((code) => {
        return {
          id: code?.id,
          promoCode: code?.v_promo_code,
          discountValue: code?.v_discount,
          status: code?.e_status,
          isEdit: false,
        };
      });
      setDiscountList(discountLists);
    }
    setLoading(false);
  };

  const onSubmit = async (values, id) => {
    setLoading(true);

    const data = {
      promo_code: values?.promoCode,
      discount_value: values?.discountValue,
      status: values?.status,
    };

    const endPoint = id && id !== 'new-row' ? `artist/artistPromoCodes/${id}?_method=PUT` : 'artist/artistPromoCodes';

    const res = await AxiosAll('post', endPoint, data, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      notify(res?.data?.message, 'success');
      // update the list
      setDiscountList(
        discountList?.map((code) => {
          if (code?.id === id) {
            return {
              promoCode: values?.promoCode,
              discountValue: values?.discountValue,
              status: values?.status,
              isEdit: false,
            };
          }
          return code;
        }),
      );
      getDiscountList();
    } else {
      notify(res?.data?.message, 'error');
    }
    setLoading(false);
  };

  useEffect(() => {
    globalWindow?.scrollTo({
      // eslint-disable-next-line no-unsafe-optional-chaining
      top: document?.getElementById('discount-area'),
      behavior: 'smooth',
    });
  }, [page, limit]);

  useEffect(() => {
    getDiscountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, userDetails?.token]);

  return (
    <div className="discount-code">
      <Typography variant="h2" className="discount-code-heading">
        Manage Your Discounts
      </Typography>

      <TableContainer className="order-table gray_theme_update" id="discount-area">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="order-head">
            <TableRow>
              <TableCell align="center">Discount Code</TableCell>
              <TableCell align="center">Discount Value %</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">
                <GrayBtn
                  title="Add New"
                  onClick={() => {
                    if (discountList?.some((item) => item?.isEdit)) {
                      notify('Please save the current code first', 'error');
                      return;
                    }
                    setDiscountList([
                      { id: 'new-row', promoCode: '', discountValue: '', status: 'active', isEdit: true },
                      ...discountList,
                    ]);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBodySkeleton />
          ) : (
            <TableBody className="order-body">
              {discountList.map((item, index) => (
                <Formik
                  key={`discount-${item?.id || index}`}
                  initialValues={{
                    promoCode: item?.promoCode,
                    discountValue: item?.discountValue,
                    status: item?.status,
                    isEdit: item?.isEdit,
                  }}
                  validationSchema={Yup.object().shape({
                    promoCode: Yup.string()
                      .required('Discount code required')
                      .matches(/^[A-Z0-9]*$/, 'Alphanumeric only')
                      .max(10, 'Max 10 characters allowed'),
                    discountValue: Yup.number()
                      .required('Discount value required')
                      .min(0.01, 'Must be greater than 0')
                      .max(99, 'Must be less than 100')
                      .test('decimal-places', 'Only two decimals', (value) => /^[0-9]*(\.[0-9]{1,2})?$/.test(value)),
                    status: Yup.string().required('Active is required'),
                  })}
                  onSubmit={(values) => {
                    onSubmit(values, item?.id);
                  }}
                >
                  {({ handleSubmit }) => (
                    <TableRow key={`discount-${item?.id || index}`}>
                      <TableCell align="center">
                        <div style={{ position: 'relative' }}>
                          <Field name="promoCode">
                            {({ field, form }) => (
                              <TextFieldInput
                                className="discount-input"
                                id="outlined-basic"
                                variant="outlined"
                                type="text"
                                placeholder="eg. CODE100"
                                inputProps={{ style: { textAlign: 'center' } }}
                                {...field}
                                disabled={!item?.isEdit}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  form.setFieldValue(name, value?.toUpperCase()?.replace(/\s/g, ''));
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="promoCode" component="div" className="error_text" />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ position: 'relative' }}>
                          <Field name="discountValue">
                            {({ field }) => (
                              <TextFieldInput
                                className="discount-input"
                                id="outlined-basic"
                                variant="outlined"
                                type="number"
                                inputProps={{ min: 0.01, step: 0.01, style: { textAlign: 'center' } }}
                                placeholder="% Amount Discounted"
                                {...field}
                                disabled={!item?.isEdit}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="discountValue" component="div" className="error_text" />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ position: 'relative' }}>
                          <Field name="status">
                            {({ field }) => (
                              <SimpleSelectBox
                                options={[
                                  { id: 'active', name: 'Yes' },
                                  { id: 'inactive', name: 'No' },
                                ]}
                                {...field}
                                disabled={!item?.isEdit}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="status" component="div" className="error_text" />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {item?.isEdit ? (
                          <GreenBtn
                            title="Save"
                            loading={loading}
                            loadingText="Saving..."
                            onClick={handleSubmit}
                            btnWidth="100%"
                          />
                        ) : (
                          <DarkBtn
                            title="Edit"
                            onClick={() => {
                              // do not edit if any row is in edit mode
                              if (discountList?.some((row) => row?.isEdit)) {
                                notify('Please save the current code first', 'error');
                                return;
                              }
                              setDiscountList(
                                discountList?.map((row, idx) => {
                                  if (idx === index) {
                                    return { ...row, isEdit: true };
                                  }
                                  return row;
                                }),
                              );
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </Formik>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isShowPagination && (
        <PaginationBox
          count={discountData?.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}
    </div>
  );
};
