import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Avatar, Button, Card } from '@mui/material';
import { MsgPreviewBoxSkeleton } from '../Skeleton/MsgPreviewBoxSkeleton';
import MsgPreviewBox from '../universal/MsgPreviewBox';
import MsgForm from './MsgForm';
import notify from '../../utils/notify';
import GrayBtn from '../universal/Buttons/GrayBtn';

const MsgPreview = ({ isLoading, data, getSellerMessages, updateLatestMessage, senderType }) => {
  const { userDetails } = useSelector((state) => state.user);
  const handleReplyEmail = (email) => {
    if (email) {
      const mailtoLink = `mailto:${email}`;

      window.location.href = mailtoLink;
    } else {
      notify('Email address not found!', 'error');
    }
  };

  return (
    <>
      <div className="messagesbox" id="msg-preview-child">
        {data?.messages?.map((msg, i) => {
          return !isLoading ? (
            <>
              <div className="msg_avatar">
                {userDetails?.user?.id !== msg?.i_sender_id && (
                  <div className="profile-img">
                    <Avatar src={data?.chat?.receiver?.v_image} className="avatar_new-img" />
                  </div>
                )}
                <MsgPreviewBox
                  senderType={senderType}
                  senderId={msg?.i_sender_id}
                  senderName={msg?.sender?.v_fname}
                  senderDescription={msg?.l_message}
                  sendTime={moment.utc(msg?.created_at).local().format('HH:mm')}
                  sendDate={moment.utc(msg?.created_at).local().format('DD/MM/YY')}
                  key={msg?.id}
                />

                {userDetails?.user?.id === msg?.i_sender_id && (
                  <div className="profile-img">
                    <Avatar src={data?.chat?.seller?.v_image} className="avatar_new-img" />
                  </div>
                )}
              </div>
              {senderType === 'guest' && data.messages.length - 1 === i && (
                <div className="reply_section">
                  <GrayBtn
                    className="reply_email"
                    title="Reply via email"
                    onClick={() => handleReplyEmail(data?.chat?.user?.v_email)}
                  />
                </div>
              )}
            </>
          ) : (
            <MsgPreviewBoxSkeleton key={i} />
          );
        })}
      </div>
      {senderType !== 'guest' && (
        <MsgForm data={data} getSellerMessages={getSellerMessages} updateLatestMessage={updateLatestMessage} />
      )}
    </>
  );
};

export default MsgPreview;
