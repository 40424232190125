import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { images } from '../../../../assets';
import { SimpleSelectBox } from '../../../../components/InputTypeText/SelectBox';
import { OrderPopUp } from '../../../../components/order_detail/OrderPopUp';
import { OrderGrid } from '../../../../components/order_detail/orderGrid';
import DarkBtn from '../../../../components/universal/Buttons/DarkBtn';
import { GreenBtn } from '../../../../components/universal/Buttons/GreenBtn';
import WhiteBtn from '../../../../components/universal/Buttons/whiteBtn';
import { CardContainer } from '../../../../components/universal/CardContainer';
import CustomTable from '../../../../components/universal/CustomTable';
import { baseURL, globalWindow } from '../../../../core/constant';
import { formatDate } from '../../../../hooks/formatDate';
import AxiosAll from '../../../../services/axiosAll';
import { request } from '../../../../services/request';
import notify from '../../../../utils/notify';

function createData(ref, artwork, value, date, status) {
  return { ref, artwork, value, date, status };
}

const OrderDetails = () => {
  const [order, setOrder] = useState([]);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [dropdowns, setDropdowns] = useState([]);
  const [sellerAddress, setSellerAddress] = useState([]);
  const [reasonId, setReasonId] = useState('');
  const params = useParams();
  const { userDetails } = useSelector((state) => state.user);
  const [open, setOpen] = useState({
    trackingLinkDispatch: false,
    shippingLabel: false,
    dispatchModal: false,
    accept: false,
    declinePopup: false,
    declineConfirm: false,
  });

  const rows = order?.get_items?.map((orderItem) => {
    return createData(
      orderItem?.v_order_item_id,
      orderItem?.get_artwork?.v_title,
      orderItem?.f_price,
      // moment(orderItem?.created_at).format('DD/MM/YYYY'),
      formatDate(orderItem?.created_at),
      orderItem?.v_order_status,
    );
  });

  const headCells = [
    { label: 'Order Ref' },
    { label: 'Artworks' },
    { label: 'Value' },
    { label: 'Date' },
    { label: 'Status' },
  ];

  const navigate = useNavigate();

  const handleClickOpen = (openState) => {
    setOpen({ ...open, [openState]: true });
  };
  const handleClose = (openState) => {
    setOpen({ ...open, [openState]: false });
    if (openState === 'accept' || openState === 'declineConfirm') {
      getOrder();
    }
  };

  const getDropdowns = async () => {
    try {
      const res = await request({
        url: '/dropdowns',
        method: 'GET',
        params: {
          type: ['order_decline_reasons'],
        },
        token: userDetails?.token,
      });

      const data = res?.data;
      setDropdowns(data);
      setReasonId(data?.order_decline_reasons?.[0].id);
    } catch (error) {
      console.error('Error fetching dropdowns: ', error);
    }
  };

  const getAddress = async () => {
    try {
      const res = await AxiosAll('get', 'addresses?address_type=3', '', userDetails.token);
      const data = res?.data?.data;
      setSellerAddress(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptOrReject = async (status) => {
    try {
      const acceptPayload = { status };
      const declinePayload = { status, reason_id: reasonId };
      const res = await AxiosAll(
        'post',
        `acceptOrDecline/${params.orderId}`,
        status === 'accept' ? acceptPayload : declinePayload,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        if (status === 'accept') {
          setOpen({ ...open, accept: true });
        } else {
          setOpen({ ...open, declineConfirm: true });
        }
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadShippingLabel = async () => {
    try {
      globalWindow?.open(`${baseURL}artist/downloadLabel/${params.orderId}`, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  const RequestShippingLabel = async () => {
    try {
      const res = await AxiosAll('post', `artist/requestLabel/${params.orderId}`, '', userDetails?.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setOpen({ ...open, shippingLabel: false });
        getOrder();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ConfirmPickupAddress = async () => {
    try {
      const res = await AxiosAll(
        'post',
        `artist/confirmPickupAddress/${params.orderId}/${sellerAddress?.id}`,
        '',
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setOpen({ ...open, shippingLabel: false });
        getOrder();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDispatch = async (e, number, companyId, pickup, trackingLink) => {
    try {
      e.preventDefault();
      const res = await AxiosAll(
        'put',
        `orders/${params.orderId}`,
        {
          tracking_number: number,
          shipping_company_id: companyId,
          isReadyToPickup: pickup || '0',
          tracking_link: trackingLink,
        },
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setOpen({ ...open, dispatchModal: false, trackingLinkDispatch: false });
        getOrder();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkAsDelivered = async (pickup) => {
    try {
      const res = await AxiosAll(
        'post',
        `orders/mark-as-delivered/${params.orderId}`,
        { isReadyToPickup: pickup || '0' },
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        getOrder();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrder = useCallback(async () => {
    setLoadingOrder(true);
    try {
      const res = await request({
        url: `/artist/getMyOrderDetail/${params.orderId}`,
        method: 'GET',
        token: userDetails?.token,
      });
      setOrder(res?.data);
      setLoadingOrder(false);
    } catch (error) {
      setLoadingOrder(false);
    }
  }, [userDetails?.token, params.orderId]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  useEffect(() => {
    getAddress();
    getDropdowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateHandler = () => {
    getOrder();
  };

  const acceptMsg = 'Thank You!<br/><br/>Keep an eye on your inbox for more updates shortly.';
  const declineTopMsg = 'We’re Sorry To Hear That You Are Unable to Accept This Order...';
  const declineMsg = 'Please, Let Us Know Your Reason For Declining';
  const thankYouMsg =
    'Thank You<br/><br/> We can confirm that your order has now been declined and the buyer will be fully refunded.';

  return (
    <div className="order-detail">
      <div className="order-wrap">
        <WhiteBtn title="Return to Order History" onClick={() => navigate('/sales/orders')} />
        {(order?.is_pod_order === 1 ||
          (order?.v_order_status === 'Processing' && order?.v_order_accepted === 'Pending')) && (
          <CardContainer className="order-required">
            <div className="order-left">
              <Typography variant="h4" className="action">
                {order?.is_pod_order === 1 ? 'Print On Demand' : 'Action Required'}
              </Typography>
              <Typography paragraph>
                {order?.is_pod_order === 1
                  ? `This is only to let you know that you have received a 'Print On Demand' order and that no action is required. Team Cosimo will handle order processing, and you will receive a commission.`
                  : `In order to process your order you must accept it within 48 hours of purchase. Failure to do this will
                result in a full refund to the buyer and cancellation of your order.`}
              </Typography>
            </div>
            {order?.is_pod_order === 1 ? null : (
              <div className="order-right">
                {/* <GrayBtn title="Decline" /> */}
                <OrderPopUp
                  open={open.declinePopup}
                  openState="declinePopup"
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  className="graybtn"
                  btnTitle="Decline"
                  headBgColor="#D3D3D3"
                >
                  <div className="decline-box">
                    <Typography
                      paragraph
                      className="decline-heading"
                      dangerouslySetInnerHTML={{ __html: declineTopMsg }}
                    />
                    <Typography paragraph className="decline-msg" dangerouslySetInnerHTML={{ __html: declineMsg }} />
                    <SimpleSelectBox
                      options={dropdowns?.order_decline_reasons?.map((odr) => {
                        return { id: odr?.id, name: odr?.title };
                      })}
                      value={reasonId}
                      onChange={(e) => setReasonId(e.target.value)}
                      className="multi-select"
                    />
                    <OrderPopUp
                      open={open.declineConfirm}
                      openState="declineConfirm"
                      onClick={() => handleAcceptOrReject('reject')}
                      handleClose={handleClose}
                      className="darkbtn"
                      btnTitle="Submit"
                      headBgColor="#D3D3D3"
                    >
                      <div className="confirm-decline">
                        <Typography
                          paragraph
                          className="decline-msg-confirm"
                          dangerouslySetInnerHTML={{ __html: thankYouMsg }}
                        />
                        <DarkBtn
                          title="done"
                          onClick={() => {
                            setOpen({ ...open, declineConfirm: false, declinePopup: false });
                            getOrder();
                            globalWindow?.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                        />
                      </div>
                    </OrderPopUp>
                  </div>
                </OrderPopUp>
                <OrderPopUp
                  open={open.accept}
                  openState="accept"
                  onClick={() => handleAcceptOrReject('accept')}
                  handleClose={handleClose}
                  className="greenbtn"
                  btnTitle="Accept"
                >
                  <div className="accept-box">
                    <Typography paragraph className="top-heading" dangerouslySetInnerHTML={{ __html: acceptMsg }} />
                    <img src={images.kidWithArt} alt="" />
                    <GreenBtn
                      title="Done"
                      onClick={() => {
                        setOpen({ ...open, accept: false });
                        getOrder();
                        globalWindow?.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                    />
                  </div>
                </OrderPopUp>
              </div>
            )}
          </CardContainer>
        )}
        {/* {rows?.length > 0 && ( */}
        <CustomTable rows={[order]} order headCells={headCells} isEmpty={rows?.length < 1} isDetailPage />
        {/* )} */}
        {/* <OrderTable tableBoolen={dataStatus} /> */}
        <OrderGrid
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          order={order}
          loadingOrder={loadingOrder}
          sellerAddress={sellerAddress}
          handleDispatch={handleDispatch}
          handleMarkAsDelivered={handleMarkAsDelivered}
          RequestShippingLabel={RequestShippingLabel}
          downloadShippingLabel={downloadShippingLabel}
          ConfirmPickupAddress={ConfirmPickupAddress}
          update={updateHandler}
        />
      </div>
    </div>
  );
};

export default OrderDetails;
