import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { SubcriptionDetails } from '../../components/subscription/SubcriptionDetails';
import { SubcriptionCard } from '../../components/subscription/subcriptionCard';
import { ToggleButtons } from '../../components/universal/SubcriptionToggle';

import AxiosAll from '../../services/axiosAll';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import notify from '../../utils/notify';
import { Loader } from '../../components/universal/Loader';
import { setSubscription } from '../../redux/actions/subscription';
import { openWindow } from '../../hooks/popupFrame';
import { globalWindow } from '../../core/constant';

export const Offers = () => {
  const btnTitle = 'Continue';

  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [subscriptionMonthyPlans, setsubscriptionMonthyPlans] = useState({});
  const [subscriptionYearlyPlans, setsubscriptionYearlyPlans] = useState({});
  const [activePlan, setactivePlan] = useState({});
  const [offerId, setofferId] = useState();
  const [activeSubscription, setactiveSubscription] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingOffers, setLoadingOffers] = useState(true);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [IsCardAdd, setIsCardAdd] = useState(false);
  const [havePaidPlan, sethavePaidPlan] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [FreePLanINOnCurrenttype, setFreePLanINOnCurrenttype] = useState({});
  const [loadingtext, setLoadingtext] = useState('Subscription Plans are Loading....');

  const [plansType, setplansType] = useState('monthly');

  const getSubscriptions = async (type) => {
    setLoadingtext('Subscription Plans are Loading....');
    setLoadingPlans(true);
    try {
      const res = await AxiosAll('get', 'artist/subscriptionPlans', '', userDetails.token);
      const data = res?.data?.data;
      // setsubscriptionMonthyPlans(data?.monthly);
      setsubscriptionYearlyPlans(type === 'monthly' ? data?.monthly : data?.yearly);
      setactivePlan(data?.customer_current_subscription?.active_plan);
      setactiveSubscription(data?.customer_current_subscription?.active_subscription);
      setIsCardAdd(res?.data?.data?.customer_payment_method_count > 0);
      setLoading(false);
      setLoadingPlans(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoadingPlans(false);
    }
  };

  const getSubscriptionsOffer = async () => {
    setLoadingtext('Subscription Plans are Loading....');
    setLoadingOffers(true);
    try {
      const res = await AxiosAll('get', 'artist/mySpecialOffer', '', userDetails.token);
      const data = res?.data?.data;
      setsubscriptionMonthyPlans(data?.monthly || data?.yearly);
      setplansType(data?.monthly ? 'monthly' : 'yearly');
      getSubscriptions(data?.monthly ? 'monthly' : 'yearly');
      setofferId(data?.special_offer_id);

      setLoading(false);
      setLoadingOffers(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoadingOffers(false);
    }
  };

  useEffect(() => {
    !loading && sethavePaidPlan(activePlan?.plan_type !== 'free');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansType, subscriptionMonthyPlans, subscriptionYearlyPlans]);

  useEffect(() => {
    getSubscriptionsOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentSubscription = async () => {
    const res = await AxiosAll('GET', 'artist/artist_current_active_subscription_plan_detail', null, userDetails.token);
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
    }
  };

  const PurchaseSubscription = async (id, type) => {
    setLoadingtext('adding Subscription Plans for you ...');
    setLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        type === 'upgrade' ? 'artist/customerSubscriptionUpgradeOrDowngrade' : 'artist/customerSubscriptions',
        { plan_id: id, special_offer_id: offerId, redirect_url: globalWindow?.location?.origin },
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        if (res?.data?.data['3dStatus'] && res?.data?.data['3DSecureURL']) {
          window?.open(res?.data?.data['3DSecureURL'], '_self');
          setLoadingtext('Confirming Authorization Status... ');
          setLoading(false);
          // getCurrentSubscription();
          // navigate('/paymentStatus?status=success');
        } else {
          handelPopupLoad();
        }
      } else {
        notify(res?.data?.message, 'error');
        !IsCardAdd && navigate(`/checkout?subscription-plan=${id}&method=false`);
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoading(false);
    }
  };
  const handelPopupLoad = () => {
    getSubscriptions();
    setLoading(false);
    getCurrentSubscription();
    navigate('/my-account/subscription-history');
  };

  const CancelSubscription = async (id, data) => {
    setLoadingtext('Cancelling Your Subscription Plans...');
    setLoading(true);
    if (data?.cancelType === 'immediately') {
      CancelNow(id, data);
    } else {
      CancelLater(id, data);
    }
  };

  const CancelNow = async (id, data) => {
    setLoadingtext('Cancelling Your Subscription Plans...');
    setLoading(true);
    try {
      const payload = {
        feedback: data?.reason,
      };
      const res = await AxiosAll(
        'delete',
        `artist/customerSubscriptions/${activeSubscription?.id}`,
        payload,
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setLoading(false);
        navigate('/my-account/subscription-history');
      } else {
        notify(res?.data?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoading(false);
    }
  };

  const CancelLater = async (id, data) => {
    setLoadingtext('Cancelling Your Subscription Plans...');
    setLoading(true);
    try {
      const payload = {
        feedback: data?.reason,
        cancel_at_period_end: 1,
      };
      const res = await AxiosAll(
        'put',
        `artist/customerSubscriptions/${activeSubscription?.id}`,
        payload,
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setTimeout(() => {
          handelPopupLoad();
        }, 10000);
      } else {
        notify(res?.data?.message, 'error');
        setLoading(false);
        getSubscriptions();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoading(false);
    }
  };

  return (
    <Container className="mysubscription all-screen-container">
      <Loader loading={loading || loadingPlans || loadingOffers} message={loadingtext} />
      {loadingPlans || loadingOffers ? (
        'Loading....'
      ) : (
        <>
          <Typography variant="h2">Select Your Plan</Typography>
          <div className="btn-toggle-section">
            <WhiteBtn title="Return to Dashboard" onClick={() => navigate('/dashboard')} />
            <div> </div>
          </div>
          <div className="plans">
            {subscriptionMonthyPlans?.length > 0
              ? subscriptionMonthyPlans?.map((plans, index) => {
                  return (
                    <SubcriptionCard
                      planId={plans?.id}
                      planType={plans.plan_type}
                      key={plans?.id}
                      title={plans?.v_display_name || plans?.v_name}
                      mostPopular={plans?.most_popular || 1}
                      btnTitle={btnTitle}
                      currency={plans?.v_currency}
                      actualPrice={plans?.v_amount}
                      paraDetail={plans?.l_description}
                      stripPrice={plans?.v_sale_amount}
                      type={plans?.v_recurring_interval}
                      isCurrentlyActive={activePlan?.id === plans?.id ? activeSubscription : '' || false}
                      isCancelRequested={activeSubscription}
                      PurchaseSubscription={PurchaseSubscription}
                      CancelSubscription={CancelSubscription}
                      IsCardAdd={IsCardAdd}
                      alreadyHaveActivePlan={havePaidPlan}
                      isOffer={1}
                      offerId={offerId}
                    />
                  );
                })
              : 'No special offers available for you at the moment. Please try again in a while. '}
          </div>

          <SubcriptionDetails type={plansType} data={subscriptionYearlyPlans} />
        </>
      )}{' '}
    </Container>
  );
};
