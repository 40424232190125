import currentURL from '../env';

export const baseURL = currentURL.currentEnv;
export const message = 'welcome to dashboard ';
export const globalWindow = typeof window !== 'undefined' && window;

export const getScreenSize = () => {
  if (globalWindow.innerWidth <= 450) {
    return 'mobile';
  }
  if (globalWindow.innerWidth <= 820) {
    return 'tablet';
  }
  return 'web';
};

export const getScreenSizeImagDimention = () => {
  if (globalWindow.innerWidth <= 450) {
    return '400x400';
  }
  if (globalWindow.innerWidth <= 768) {
    return '400x400';
  }
  return '500x500';
};

export const truncateMessage = (msg, length) => {
  if (msg?.length <= length) {
    return msg;
  }
  const truncated = msg?.slice(0, length);
  // const lastSpaceIndex = truncated?.lastIndexOf(' ');
  // if (lastSpaceIndex !== -1) {
  //   truncated = truncated?.slice(0, lastSpaceIndex);
  // }
  return `${truncated}...`;
};

export const pageLimitOptions = [
  { name: '12 Results Per Page', id: 12 },
  { name: '24 Results Per Page', id: 24 },
  { name: '36 Results Per Page', id: 36 },
];

export const slugify = (str) =>
  str
    ? str
        .toLowerCase()
        .trim()
        .replace(/\//g, '-')
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    : 'title';

export const SiteMapLinks = [
  {
    title: 'General',
    links: [
      { title: 'Home', link: '/', external: true },
      { title: 'About Us', link: '/about-us', external: true },
      { title: 'Contact Us', link: '/contact-us', external: true },
      { title: 'Blog', link: '/blog', external: true },
      { title: 'FAQs', link: '/faqs', external: true },
      { title: 'Investors', link: '/investors', external: true },
      // { title: 'Meet The Team', link: '/our-team', external: true },
    ],
  },
  // {
  //   title: 'Search Categories',
  //   links: [
  //     { title: 'Matchmaker', link: '/match-maker', external: true },
  //     { title: 'Painting', link: '/painting', external: true },
  //     { title: 'Printmaking', link: '/printmaking', external: true },
  //     { title: 'Drawing', link: '/drawing', external: true },
  //     { title: 'Photography', link: '/photography', external: true },
  //     { title: 'Sculpture', link: '/sculpture', external: true },
  //     { title: 'Discover', link: '/discover', external: true },
  //   ],
  // },
  {
    title: 'User',
    links: [
      { title: 'Sign Up Artist', link: '/signup', restricted: true, external: false },
      { title: 'Sign Up Buyer', link: '/signup', external: true },
      { title: 'Login', link: '/login', restricted: true, external: false },
      { title: 'Terms & Conditions', link: '/terms-and-conditions', external: false },
      { title: 'Blog', link: '/blog', external: true },
      { title: 'Privacy Policy', link: '/privacy-policy', external: false },
      { title: 'Cookie Policy', link: '/cookie-policy', external: false },
      { title: 'Return Policy', link: '/return-policy', external: false },
    ],
  },
];

export const SideOption = (plan, cardList) => {
  const options = [
    {
      // title: 'Dashboard',
      title: 'Home',
      slug: '/dashboard',
      allowed: true,
      img: require('../assets/images/home/home.webp'),
    },
    {
      title: 'My Account',
      slug: '/my-account/bio',
      type: 'accordion',
      img: require('../assets/images/home/account_new.webp'),
      subMenu: [
        // {
        //   title: 'My Bio',
        //   slug: '/my-account/bio',
        //   allowed: true,
        // },
        // {
        //   title: 'Settings',
        //   slug: '/my-account/settings',
        //   allowed: true,
        // },
        {
          title: 'Personal Info',
          slug: '/my-account/settings',
          allowed: true,
        },
        {
          title: 'Membership',
          slug: '/my-subscription',
          allowed: true,
        },
        // {
        //   title: 'Subscription History',
        //   slug: '/my-account/subscription-history',
        //   allowed: true,
        // },
        {
          title: 'Billing & Payments',
          slug: '/my-account/subscription-history',
          allowed: true,
        },
      ],
    },
    {
      title: 'Portfolio Tools',
      slug: '/portfolio/artworks',
      type: 'accordion',
      img: require('../assets/images/home/portfolio.webp'),
      subMenu: [
        {
          title: 'Portfolio Settings',
          slug: '/portfolio/portfolio-settings',
          allowed: true,
        },
        {
          title: 'Artworks',
          slug: '/portfolio/artworks',
          allowed: true,
        },
        {
          title: 'Collections',
          slug: '/portfolio/collections',
          allowed: true,
        },
        {
          title: 'CV Builder',
          slug: '/portfolio/cv-builder',
          allowed: true,
        },
        {
          title: 'Artist Statement',
          slug: '/portfolio/artist-statement',
          allowed: true,
        },
        // {
        //   title: 'Certificates',
        //   slug: '/portfolio/certificates',
        //   allowed: plan?.active_plan?.v_certificate_of_autheticity !== 'No',
        // },
      ],
    },
    {
      title: 'Sales Manager',
      slug: '/sales',
      type: 'accordion',
      img: require('../assets/images/home/salesmanager.webp'),
      subMenu: [
        {
          title: 'Orders',
          slug: '/sales/orders',
          allowed: true,
        },
        {
          title: 'Shipping',
          slug: '/sales/shipping',
          allowed: true,
        },
        {
          title: 'Payments',
          slug: '/sales/payments',
          allowed: true,
        },
        {
          title: 'Discounts',
          slug: '/sales/discount',
          allowed: true,
        },
        // {
        //   title: 'Reviews',
        //   slug: '/sales/reviews',
        //   allowed: true,
        // },
      ],
    },
    {
      title: 'Academy',
      slug: '/academy',
      type: 'accordion',
      img: require('../assets/images/home/academy.webp'),

      subMenu: [
        {
          title: 'Pricing Calculator',
          slug: '/academy/pricing-tool',
          allowed: plan?.active_plan?.v_pricing_calculator !== 'No',
        },
        {
          title: 'Video Library',
          slug: '/academy/video-library',
          allowed: true,
        },
        {
          title: 'Directory',
          slug: '/academy/directory',
          allowed: true,
        },

        // {
        //   title: 'Mentoring',
        //   slug: '/academy/academy',
        //   allowed: true,
        // },
        // {
        //   title: 'Academy',
        //   slug: '/academy/academy',
        //   allowed: plan?.active_plan?.plan_type === 'paid',
        // },
      ],
    },
    // {
    //   title: 'Community',
    //   slug: '/community',
    //   type: 'accordion',
    //   subMenu: [
    //     // {
    //     //   title: 'Meet-Up',
    //     //   slug: '/community/meet-up',
    //     //   allowed: true,
    //     // },
    //     {
    //       title: 'Messages',
    //       slug: '/community/inbox',
    //       allowed: true,
    //     },
    //     {
    //       title: 'Forum',
    //       slug: '/community/forum',
    //       allowed: true,
    //     },
    //     // {
    //     //   title: 'Followers',
    //     //   slug: '/community/followers',
    //     //   allowed: true,
    //     // },
    //     // {
    //     //   title: 'Referral Scheme',
    //     //   slug: '/community/referral-scheme',
    //     //   allowed: true,
    //     // },
    //     // {
    //     //   title: 'Referral Logs',
    //     //   slug: '/community/referral-logs',
    //     //   allowed: true,
    //     // },
    //   ],
    // },

    {
      title: 'Messages',
      slug: '/messages',
      allowed: true,
      img: require('../assets/images/home/message.png'),
    },
    {
      title: 'Support',
      slug: '/support',
      allowed: true,
      img: require('../assets/images/home/support.webp'),
    },
  ];
  // cardList?.length > 0 &&
  //   options[1].subMenu.push({
  //     title: 'Saved Cards',
  //     slug: '/my-account/my-card',
  //     allowed: true,
  //   });
  // if (plan === 'free') {
  //   return options.filter((option) => option.allowed);
  // }
  return options;
};

// dev

// export const stripkey =
//   'pk_test_51MyEQbEg8JGrO0uV5U9jRqQ3KXpOOnLSysXn5Xp2CJTRI4Nkb96nJj7t3uAiFs1lG2qoSh5K5IDXSb1SwDw4VqhK00lHK5q4CA';

// Production
export const stripkey =
  'pk_live_51IG3V2DtlMghTdluBg25i1vs1eGSshqV7tirRQ19eQfOecY6rECU3t4zcoIkxlgMRVQbvZJ0DkUW2V8lKyPqgDhL00MklPSiw2';

/*
 * Quill modules to attach to editor
 */
export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 */
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export const EuropeanCountries = [
  { id: 'United Kingdom', name: 'United Kingdom' },
  { id: 'England', name: 'England' },
  { id: 'Wales', name: 'Wales' },
  { id: 'Scotland', name: 'Scotland' },
  { id: 'Northern Ireland', name: 'Northern Ireland' },
];

export const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

export const notifyMessages = {
  uniqueTags: 'Tags must be unique',
  enterMsg: 'Please enter a message',
  loginSuccess: 'User logged in successfully',
  signupSuccess: 'User registered successfully',
  completePersonalization: 'Please complete the personalization process first',
  fileTooLarge: 'File size should be less than 2MB',
  fileFormat: 'File type should be jpeg, jpg or png',
  primaryFileUploadLimit: 'You can only upload 1 primary image',
  secondaryFileUploadLimit: 'You can upload maximum 3 secondary images',
  profileArtworkMsg: 'Please complete your profile to add artwork',
  profileCollectionMsg: 'Please complete your profile to add artwork collection',
  addArtworkMsg: 'Please add artwork first',
  emptyFieldsMsg: 'Please fill all the fields',
  completeProfileMsg: 'Please complete you seller profile first',
  duplicateErrorMsg: 'You can duplicate one artwork at a time.',
};

export const CVBuilderDropDown = {
  ft_pt_hobby: {
    values: [
      {
        id: 3,
        v_label_name: 'Full-Time Artist',
        f_label_value: 1.2,
      },
      {
        id: 2,
        v_label_name: 'Part-Time Artist',
        f_label_value: 1.1,
      },
      {
        id: 1,
        v_label_name: 'Hobbyist',
        f_label_value: 1,
      },
    ],
  },
  years_practicing: {
    values: [
      {
        id: 10,
        v_label_name: '35',
        f_label_value: 67.25,
      },
      {
        id: 11,
        v_label_name: '40',
        f_label_value: 67.25,
      },
      {
        id: 12,
        v_label_name: '45',
        f_label_value: 67.25,
      },
      {
        id: 13,
        v_label_name: '50',
        f_label_value: 67.25,
      },
      {
        id: 9,
        v_label_name: '30',
        f_label_value: 48.04,
      },
      {
        id: 8,
        v_label_name: '25',
        f_label_value: 34.31,
      },
      {
        id: 7,
        v_label_name: '15',
        f_label_value: 24.51,
      },
      {
        id: 6,
        v_label_name: '10',
        f_label_value: 17.51,
      },
      {
        id: 5,
        v_label_name: '5',
        f_label_value: 12.5,
      },
      {
        id: 4,
        v_label_name: '2',
        f_label_value: 10.42,
      },
    ],
  },
  salesyr: {
    values: [
      {
        id: 23,
        v_label_name: '350',
        f_label_value: 1.1,
      },
      {
        id: 22,
        v_label_name: '300',
        f_label_value: 1.09,
      },
      {
        id: 21,
        v_label_name: '250',
        f_label_value: 1.08,
      },
      {
        id: 20,
        v_label_name: '200',
        f_label_value: 1.07,
      },
      {
        id: 19,
        v_label_name: '150',
        f_label_value: 1.06,
      },
      {
        id: 18,
        v_label_name: '100',
        f_label_value: 1.05,
      },
      {
        id: 17,
        v_label_name: '50',
        f_label_value: 1.04,
      },
      {
        id: 16,
        v_label_name: '20',
        f_label_value: 1.03,
      },
      {
        id: 15,
        v_label_name: '10',
        f_label_value: 1.02,
      },
      {
        id: 14,
        v_label_name: '5',
        f_label_value: 1.01,
      },
    ],
  },
  exhibitions: {
    values: [
      {
        id: 32,
        v_label_name: 'Biennial',
        f_label_value: 1.7,
      },
      {
        id: 85,
        v_label_name: 'Retrospective',
        f_label_value: 1.5,
      },
      {
        id: 31,
        v_label_name: 'Solo Show',
        f_label_value: 1.4,
      },
      {
        id: 30,
        v_label_name: 'Group Show',
        f_label_value: 1.3,
      },
      {
        id: 29,
        v_label_name: 'MA Show',
        f_label_value: 1.25,
      },
      {
        id: 28,
        v_label_name: 'BA Show',
        f_label_value: 1.2,
      },
      {
        id: 27,
        v_label_name: 'Art Trail',
        f_label_value: 1.15,
      },
      {
        id: 26,
        v_label_name: 'Market',
        f_label_value: 1.1,
      },
      {
        id: 25,
        v_label_name: 'Online',
        f_label_value: 1,
      },
      {
        id: 24,
        v_label_name: 'Never',
        f_label_value: 0.9,
      },
    ],
  },
  education_level: {
    values: [
      {
        id: 40,
        v_label_name: 'Post Doctoral',
        f_label_value: 1.75,
      },
      {
        id: 39,
        v_label_name: 'Dr',
        f_label_value: 1.6,
      },
      {
        id: 84,
        v_label_name: 'PhD',
        f_label_value: 1.6,
      },
      {
        id: 38,
        v_label_name: 'MA',
        f_label_value: 1.4,
      },
      {
        id: 37,
        v_label_name: 'BA',
        f_label_value: 1.2,
      },
      {
        id: 35,
        v_label_name: 'A Level',
        f_label_value: 1.1,
      },
      {
        id: 36,
        v_label_name: 'Foundation',
        f_label_value: 1.1,
      },
      {
        id: 34,
        v_label_name: 'GCSE',
        f_label_value: 1.05,
      },
      {
        id: 33,
        v_label_name: 'Self Taught',
        f_label_value: 1,
      },
    ],
  },
  size_cmsq: {
    values: [
      {
        id: 50,
        v_label_name: '5mx5m',
        f_label_value: 2.5,
      },
      {
        id: 49,
        v_label_name: '4mx4m',
        f_label_value: 2.25,
      },
      {
        id: 48,
        v_label_name: '3.5mx3m',
        f_label_value: 2,
      },
      {
        id: 47,
        v_label_name: '2.5mx3m',
        f_label_value: 1.8,
      },
      {
        id: 46,
        v_label_name: '2mx2.5m',
        f_label_value: 1.6,
      },
      {
        id: 45,
        v_label_name: '1mx2.5m',
        f_label_value: 1.5,
      },
      {
        id: 44,
        v_label_name: '1mx1m',
        f_label_value: 1.4,
      },
      {
        id: 43,
        v_label_name: 'A2',
        f_label_value: 1.3,
      },
      {
        id: 42,
        v_label_name: 'A3',
        f_label_value: 1.2,
      },
      {
        id: 41,
        v_label_name: 'A4',
        f_label_value: 1.1,
      },
    ],
  },
  hours_to_make: {
    values: [
      {
        id: 60,
        v_label_name: '150',
        f_label_value: 150,
      },
      {
        id: 59,
        v_label_name: '100',
        f_label_value: 100,
      },
      {
        id: 58,
        v_label_name: '75',
        f_label_value: 75,
      },
      {
        id: 57,
        v_label_name: '50',
        f_label_value: 50,
      },
      {
        id: 56,
        v_label_name: '40',
        f_label_value: 40,
      },
      {
        id: 55,
        v_label_name: '30',
        f_label_value: 30,
      },
      {
        id: 54,
        v_label_name: '20',
        f_label_value: 20,
      },
      {
        id: 53,
        v_label_name: '10',
        f_label_value: 10,
      },
      {
        id: 52,
        v_label_name: '5',
        f_label_value: 5,
      },
      {
        id: 51,
        v_label_name: '2',
        f_label_value: 2,
      },
    ],
  },
  edition_type: {
    values: [
      {
        id: 63,
        v_label_name: 'One of One',
        f_label_value: 1.3,
      },
      {
        id: 62,
        v_label_name: 'Limited Edition',
        f_label_value: 1.1,
      },
      {
        id: 61,
        v_label_name: 'Open Edition',
        f_label_value: 0.75,
      },
    ],
  },
  edition_size: {
    values: [
      {
        id: 64,
        v_label_name: '1',
        f_label_value: 1,
      },
      {
        id: 71,
        v_label_name: '150',
        f_label_value: 0.75,
      },
      {
        id: 65,
        v_label_name: '5',
        f_label_value: 0.6,
      },
      {
        id: 66,
        v_label_name: '10',
        f_label_value: 0.5,
      },
      {
        id: 67,
        v_label_name: '25',
        f_label_value: 0.3,
      },
      {
        id: 68,
        v_label_name: '50',
        f_label_value: 0.2,
      },
      {
        id: 69,
        v_label_name: '75',
        f_label_value: 0.15,
      },
      {
        id: 70,
        v_label_name: '100',
        f_label_value: 0.1,
      },
      {
        id: 72,
        v_label_name: '250',
        f_label_value: 0.05,
      },
      {
        id: 73,
        v_label_name: '500',
        f_label_value: 0.05,
      },
    ],
  },
  medium: {
    values: [
      {
        id: 82,
        v_label_name: 'Oil',
        f_label_value: 1.3,
      },
      {
        id: 83,
        v_label_name: 'Pottery',
        f_label_value: 1.3,
      },
      {
        id: 81,
        v_label_name: 'Sculpture',
        f_label_value: 1.2,
      },
      {
        id: 79,
        v_label_name: 'Acrylic',
        f_label_value: 1.15,
      },
      {
        id: 80,
        v_label_name: 'Mixed Med',
        f_label_value: 1.15,
      },
      {
        id: 78,
        v_label_name: 'Drawing',
        f_label_value: 1.1,
      },
      {
        id: 76,
        v_label_name: 'Photography',
        f_label_value: 1.1,
      },
      {
        id: 77,
        v_label_name: 'Printmaking',
        f_label_value: 1.1,
      },
      {
        id: 75,
        v_label_name: 'Collage',
        f_label_value: 1,
      },
      {
        id: 74,
        v_label_name: 'Digital',
        f_label_value: 0.9,
      },
    ],
  },
};
