import * as Yup from 'yup';

const emojiRegex =
  /([\u2700-\u27bf]|[\uE000-\uF8FF]|[\uD83C-\uDBFF][\uDC00-\uDFFF]|\uD83D[\uDC00-\uDE4F\uDE80-\uDEFF]|\uD83E[\uDD00-\uDDFF])/; // Regular expression to detect emojis
const yearRegex = /^[0-9]{4}$/;
const yearDigitsOnlyRegex = /^[0-9]+$/;
const yearLengthRegex = /^.{4}$/;

export const validationSchema = Yup.object().shape({
  whenDidYouStart: Yup.string().required('Experience is required'),
  areYouAFullTime: Yup.string().required('Employment status is required'),
  howManySalesPerYear: Yup.string().required('Sales per year is required'),

  // Education validation matching educationColumn fields
  education: Yup.array().of(
    Yup.object().shape({
      educationInstitution: Yup.string()
        .test('educationInstitution-required', 'Institution is required', function (value) {
          const { educationLevelOfStudy, educationYear } = this.parent;
          return value || !(educationLevelOfStudy || educationYear);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      educationLevelOfStudy: Yup.string()
        .test('educationLevelOfStudy-required', 'Level of Study is required', function (value) {
          const { educationInstitution, educationYear } = this.parent;
          return value || !(educationInstitution || educationYear);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      educationYear: Yup.string()
        .test('digits-only', 'Year must contain only digits', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('educationYear-required', 'Year is required', function (value) {
          const { educationInstitution, educationLevelOfStudy } = this.parent;
          return value || !(educationInstitution || educationLevelOfStudy);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Awards validation matching awardColumns fields
  awards: Yup.array().of(
    Yup.object().shape({
      awardName: Yup.string()
        .test('awardName-required', 'Award/Grant Name is required', function (value) {
          const { awardYear } = this.parent;
          return value || !awardYear;
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      awardYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('awardYear-required', 'Year Received is required', function (value) {
          const { awardName } = this.parent;
          return value || !awardName;
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Press validation matching pressColumn fields
  press: Yup.array().of(
    Yup.object().shape({
      pressTitle: Yup.string()
        .test('pressTitle-required', 'Title is required', function (value) {
          const { pressYear, pressPublicationLink } = this.parent;
          return value || !(pressYear || pressPublicationLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      pressYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('pressYear-required', 'Year is required', function (value) {
          const { pressTitle, pressPublicationLink } = this.parent;
          return value || !(pressTitle || pressPublicationLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      pressPublicationLink: Yup.string()
        .url('Must be a valid URL')
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Commission validation matching commissionColumn fields
  commissions: Yup.array().of(
    Yup.object().shape({
      commissionProjectTitle: Yup.string()
        .test('commissionProjectTitle-required', 'Project Title is required', function (value) {
          const { commissionYear, commissionLink } = this.parent;
          return value || !(commissionYear || commissionLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      commissionYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('commissionYear-required', 'Year is required', function (value) {
          const { commissionProjectTitle, commissionLink } = this.parent;
          return value || !(commissionProjectTitle || commissionLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      commissionLink: Yup.string()
        .url('Must be a valid URL')
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Member Affiliates validation matching memberAffiliatesColumn fields
  memberAffiliates: Yup.array().of(
    Yup.object().shape({
      memberAffiliateOrganisationName: Yup.string()
        .test('memberAffiliateOrganisationName-required', 'Organisation Name is required', function (value) {
          const { memberAffiliateYear } = this.parent;
          return value || !memberAffiliateYear;
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      memberAffiliateYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('memberAffiliateYear-required', 'Year Joined is required', function (value) {
          const { memberAffiliateOrganisationName } = this.parent;
          return value || !memberAffiliateOrganisationName;
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Exhibition validation matching exhibitionColumn fields
  exhibitions: Yup.array().of(
    Yup.object().shape({
      exhibitionTitle: Yup.string()
        .test('exhibitionTitle-required', 'Exhibition Title is required', function (value) {
          const { exhibitionVenue, exhibitionYear, exhibitionLink, exhibitionType } = this.parent;
          return value || !(exhibitionVenue || exhibitionYear || exhibitionLink || exhibitionType);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      exhibitionType: Yup.string()
        .test('exhibitionType-required', 'Exhibition Type is required', function (value) {
          const { exhibitionTitle, exhibitionVenue, exhibitionYear, exhibitionLink } = this.parent;
          return value || !(exhibitionTitle || exhibitionVenue || exhibitionYear || exhibitionLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      exhibitionVenue: Yup.string()
        .test('exhibitionVenue-required', 'Venue is required', function (value) {
          const { exhibitionTitle, exhibitionYear, exhibitionLink, exhibitionType } = this.parent;
          return value || !(exhibitionTitle || exhibitionYear || exhibitionLink || exhibitionType);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      exhibitionYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('exhibitionYear-required', 'Year is required', function (value) {
          const { exhibitionTitle, exhibitionVenue, exhibitionLink, exhibitionType } = this.parent;
          return value || !(exhibitionTitle || exhibitionVenue || exhibitionLink || exhibitionType);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      exhibitionLink: Yup.string()
        .url('Must be a valid URL')
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Other Experiences validation matching otherExperiencesColumn fields
  otherExperiences: Yup.array().of(
    Yup.object().shape({
      otherExperienceTitle: Yup.string()
        .test('otherExperienceTitle-required', 'Title is required', function (value) {
          const { otherExperienceYear, otherExperienceLink } = this.parent;
          return value || !(otherExperienceYear || otherExperienceLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      otherExperienceYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('otherExperienceYear-required', 'Year is required', function (value) {
          const { otherExperienceTitle, otherExperienceLink } = this.parent;
          return value || !(otherExperienceTitle || otherExperienceLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      otherExperienceLink: Yup.string()
        .url('Must be a valid URL')
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Residency validation matching residencyColumn fields
  residencies: Yup.array().of(
    Yup.object().shape({
      residencyName: Yup.string()
        .test('residencyName-required', 'Residency Name is required', function (value) {
          const { residencyYear } = this.parent;
          return value || !residencyYear;
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      residencyYear: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('residencyYear-required', 'Year is required', function (value) {
          const { residencyName } = this.parent;
          return value || !residencyName;
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),

  // Notable Collectors validation matching NotableCollectorColumn fields
  notableCollectors: Yup.array().of(
    Yup.object().shape({
      notableCollectorName: Yup.string()
        .test('notableCollectorName-required', 'Collector Name is required', function (value) {
          const { notableDateAcquired, notableCollectionLink } = this.parent;
          return value || !(notableDateAcquired || notableCollectionLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      notableDateAcquired: Yup.string()
        .test('digits-only', 'Year must contain only digits ', (value) => !value || yearDigitsOnlyRegex.test(value))
        .test('length-four', 'Year must be exactly 4 digits long', (value) => !value || yearLengthRegex.test(value))
        .test('notableDateAcquired-required', 'Date Acquired is required', function (value) {
          const { notableCollectorName, notableCollectionLink } = this.parent;
          return value || !(notableCollectorName || notableCollectionLink);
        })
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),

      notableCollectionLink: Yup.string()
        .url('Must be a valid URL')
        .test('no-emojis', 'Emojis are not allowed', (value) => !emojiRegex.test(value)),
    }),
  ),
});
