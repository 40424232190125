import { Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import CustomTable from '../../components/universal/CustomTable';
import PaginationBox from '../../components/universal/PaginationBox';
import { pageLimitOptions } from '../../core/constant';
import { request } from '../../services/request';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import AddCardDailog from '../../components/AddCard';
import { SavedAddressSkeleton } from '../../components/Skeleton/SavedAddressSkeleton';
import { cardTypeImage } from '../../hooks/cardtypes';
import sweatAlertBox from '../../components/universal/SweetAlertBox';

const SubscriptionHistory = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');

  const { userDetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [Subscriptions, setSubscriptions] = useState([]);
  const [CurrentSubscriptions, setCurrentSubscriptions] = useState({});
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(12);
  const [parameters, setParameters] = useState({});
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const planActive = subscriptionDetails?.active_plan;
  const headCells = [
    { label: 'Amount' },
    { label: 'Description' },
    { label: 'Date' },
    { label: 'Status' },
    { label: 'Action' },
  ];

  // saved-cards function code-------------------------
  const [CardList, setCardList] = useState([]);
  const [isCardListUpdated, setIsCardListUpdated] = useState(false);
  const [confirmDel, setConfirmDel] = useState(false);
  const [idToDel, setIdToDel] = useState();
  const [loading, setLoading] = useState(true);

  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;
  // saved-cards function code-------------------------

  const isShowPagination = !isLoading && Subscriptions?.total > limit;

  const getSubscriptions = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: 'artist/customerSubscriptions',
        method: 'GET',
        params: {
          page,
          limit,
        },
        token: userDetails?.token,
      });

      setSubscriptions(res?.data);
      setCurrentSubscriptions(res?.data?.invoices[0]?.subscription_detail);
      setIsLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setIsLoading(false);
    }
  }, [page, limit, userDetails?.token]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  const calculatePlanDuration = (plan) => {
    const interval = plan?.v_current_period_start;
    const intervalEnd = plan?.v_current_period_end;
    if (!interval && !intervalEnd) return '';
    const duration = moment.duration(moment(intervalEnd).diff(moment(interval)));
    const days = duration.asDays();
    const months = duration.asMonths();
    const roundedMonths = Math.round(months);

    return roundedMonths >= 12 ? `Yearly` : (days >= 30 || roundedMonths < 12) && `Monthly`;
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  // saved-cards function code-------------------------
  const getIsCardListUpdated = (val) => {
    setIsCardListUpdated(!val);
  };

  const getCards = async () => {
    try {
      const res = await AxiosAll('get', 'artist/paymentMethods', '', userDetails.token);
      const data = res?.data?.data;
      setCardList(data);
      setIsCardListUpdated(false);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardListUpdated]);

  const deleteCard = async (id) => {
    try {
      const res = await AxiosAll('delete', `artist/paymentMethods/${id}`, '', userDetails.token);
      notify(res.data.message, res?.data?.statusCode === 200 ? 'success' : 'error');
      setConfirmDel(false);
      getCards();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  useEffect(() => {
    confirmDel && deleteCard(idToDel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDel]);

  const makeDefault = async (id) => {
    try {
      const res = await AxiosAll('put', `artist/paymentMethods/${id}?`, { is_default: 1 }, userDetails.token);
      notify(res.data.message, 'success');
      getCards();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // saved-cards function code-------------------------
  return (
    <div className="saved-addresses">
      <div className="saved-card">
        <div className="card-text main-heading font25px">Your Plan</div>
        {/* <Typography variant="h4">Subscription Credits :{Subscriptions?.stripe_customer_balance?.toFixed(2)}</Typography> */}
      </div>
      {/* <div>Invoice Credit Balance :{Math.abs(Subscriptions?.stripe_customer_balance)?.toFixed(2)}</div>
      <div>Current Subscription </div> */}
      <CardContainer className="activity gray_theme_update" topMargin="0px" bottomMargin="0px">
        <div className="address-details">
          {Subscriptions?.invoices?.length === 0 ? (
            <div className="address-text">
              <Typography variant="body1" className="font14px">
                Name: {planActive?.v_name}
                <span style={{ color: '#80C86E' }}> (Active) </span>
                Free
              </Typography>
              <Typography>Billing Invoice on : No Future Invoices</Typography>
            </div>
          ) : (
            <div className="address-text">
              <Typography variant="body1" className="font14px">
                Name: {CurrentSubscriptions?.plan_detail?.v_name}
                <span style={{ color: '#80C86E' }}> (Active) </span>
                {calculatePlanDuration(CurrentSubscriptions)}
              </Typography>
              {CurrentSubscriptions?.i_cancel_at_period_end && (
                <Typography>
                  Cancels on : {moment(CurrentSubscriptions?.v_current_period_end)?.format('D MMMM YYYY')}
                </Typography>
              )}
              <Typography>
                Billing Invoice on :{' '}
                {CurrentSubscriptions?.i_cancel_at_period_end
                  ? 'No Future Invoices'
                  : moment(CurrentSubscriptions?.v_current_period_end)?.format('D MMMM YYYY')}
              </Typography>
            </div>
          )}
        </div>
        <DarkBtn
          title={Subscriptions?.invoices?.length == 0 ? 'Upgrade Plan' : 'Change Plan'}
          onClick={() => navigate('/my-subscription')}
        />
      </CardContainer>
      <CustomTable
        styleName="gray_theme_update"
        isEmpty={Subscriptions?.invoices?.length < 1}
        rows={Subscriptions?.invoices}
        headCells={headCells}
        heading="Payment History"
        routePath="/sales/Subscriptions/detail/"
        isButton
        buttonLabel="Invoice"
        isShowHeading
        invoiceText="Credit Balance"
        balanceText={Math.abs(Subscriptions?.stripe_customer_balance?.toFixed(2))}
        additionalText="Current Subscription "
        imgInfo={images.Info}
      />
      {isShowPagination && (
        <PaginationBox
          count={Subscriptions?.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}

      <div className="save-cards">
        <div className="saved-card">
          <div className="card-text main-heading font25px">saved cards</div>
          <AddCardDailog add getIsCardListUpdated={getIsCardListUpdated} />
        </div>
        <div className="details">
          {loading ? (
            <SavedAddressSkeleton />
          ) : CardList?.length > 0 ? (
            CardList?.map((card, index) => {
              return (
                <CardContainer key={card} className="bottomMargin gray_theme_update">
                  <div className="address-details">
                    <div className="address-text">
                      {cardTypeImage(card?.v_card_brand, 30, 60)}
                      <Typography variant="body1" className="font14px">
                        **** **** **** {card?.v_card_last4}
                      </Typography>
                    </div>
                    <div className="delete-section">
                      {card.is_default === 0 && (
                        <div tabIndex={0} role="button" className="markbtn" onClick={() => makeDefault(card?.id)}>
                          <img
                            src={isToSmall ? '/icons/bookmark-black.svg' : '/icons/bookmark-white.svg'}
                            alt=""
                            width={isToSmall ? 24 : 15}
                            height={isToSmall ? 24 : 15}
                          />
                          <Typography variant="body1">Make Default</Typography>
                        </div>
                      )}
                      {CardList?.length > 1 ? (
                        <div
                          tabIndex={0}
                          role="button"
                          className="trashbtn"
                          onClick={() => (
                            sweatAlertBox(
                              'Are You Sure?',
                              'You are about to delete this card. You cannot undo this action once confirmed.',
                              setConfirmDel,
                              'Confirm',
                              'Cancel',
                              // eslint-disable-next-line no-sequences
                            ),
                            setIdToDel(card?.id)
                          )}
                        >
                          <img
                            src={isToSmall ? '/icons/trash-black.svg' : '/icons/trash-white.svg'}
                            alt=""
                            width={isToSmall ? 24 : 15}
                            height={isToSmall ? 24 : 15}
                            className="delete-icon"
                          />
                          <Typography variant="body1">Delete</Typography>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </CardContainer>
              );
            })
          ) : (
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>No Saved Cards found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHistory;
