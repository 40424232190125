import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../assets';

export const AccordionLink = ({ option, setState, anchor, isOpen, onToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const mainMenuSelected =
    option?.slug === location?.pathname || location?.pathname?.includes(option?.slug.split('/')[1]);

  return (
    <li style={{ padding: 0, marginBottom: 0 }}>
      <Accordion expanded={isOpen} style={{ borderRadius: 0, background: 'transparent', boxShadow: 'none' }}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" onClick={onToggle} className="main-head">
          <img src={option.img} alt="" className="location_img new_sidebar_icon" />
          <Link href style={{ color: '#fff', textDecoration: 'none' }}>
            {option?.title}
          </Link>
        </AccordionSummary>
        {option?.subMenu?.map((subMenu, index) => (
          <AccordionDetails
            key={index}
            className={`subheading ${location?.pathname.includes(subMenu.slug) ? 'subheading-2' : ''}`}
            style={{
              // background: location?.pathname.includes(subMenu.slug) ? '#FFBBC5' : '#fff',
              background: '#495559',
            }}
            onClick={() => {
              navigate(subMenu?.allowed ? subMenu?.slug : '/my-subscription');
              setState && setState((prev) => ({ ...prev, [anchor]: false }));
            }}
          >
            <Link
              href
              // href={subMenu?.slug}
              // onClick={() => setState && setState(prev => ({ ...prev, [anchor]: false }))}
              // to={subMenu?.slug}
              className={`${subMenu?.allowed ? '' : 'hoverimg'} ${
                location?.pathname.includes(subMenu.slug) ? 'new-sub-link' : ''
              }`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              {subMenu?.title}
              {/* {subMenu?.allowed ? '' : <img src="/icons/lock.svg" alt="" width={20} height={26} />} */}
            </Link>
          </AccordionDetails>
        ))}
      </Accordion>
    </li>
  );
};
