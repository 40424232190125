import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Select, MenuItem, Checkbox, Grid, FormHelperText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import { GreenBtn } from '../../../components/universal/Buttons/GreenBtn';
import notify from '../../../utils/notify';
import { request } from '../../../services/request';

const emojiRegex =
  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]+/gu;

const PersonalisedShippingTable = forwardRef(
  (
    { rows, setRows, countries, getPersonaliseShipping, onSelectedRowsChange, hasUnsavedRow, setHasUnsavedRow },
    ref,
  ) => {
    const { userDetails } = useSelector((state) => state.user);
    const [selectedRows, setSelectedRows] = useState([]);
    const [errors, setErrors] = useState({});

    const handleDuplicate = async (selectedIds) => {
      if (hasUnsavedRow) {
        notify('Please save the current row before duplicating', 'error');
        return;
      }

      if (selectedIds.length > 1) {
        notify('Please select only one item to duplicate', 'error');
        return;
      }

      const rowToDuplicate = rows.find((row) => row.id === selectedIds[0]);
      if (!rowToDuplicate) {
        notify('Selected row not found', 'error');
        return;
      }

      const duplicatedRow = {
        size: rowToDuplicate.shipping_name || rowToDuplicate.size,
        price: rowToDuplicate.shipping_price || rowToDuplicate.price,
        geography: rowToDuplicate.shipping_country || rowToDuplicate.geography,
        editing: true,
      };

      setRows([...rows, duplicatedRow]);
      setHasUnsavedRow(true);
      setSelectedRows([]);
      onSelectedRowsChange([]);
    };

    // Expose handleDuplicate to parent component
    useImperativeHandle(ref, () => ({
      handleDuplicate,
    }));

    const handleEdit = (id) => {
      setRows(rows.map((row) => (row.id === id ? { ...row, editing: true } : row)));
    };

    const validateField = (field, value) => {
      const isEmpty = !value?.toString()?.trim();

      switch (field) {
        case 'size':
          return isEmpty ? 'The shipping size field is required' : '';
        case 'price':
          if (isEmpty) return 'The shipping price field is required';
          if (parseFloat(value) <= 0) return 'Price must be greater than 0';
          return '';
        case 'geography':
          return isEmpty ? 'The shipping country is required' : '';
        default:
          return '';
      }
    };

    const validateEditFields = (row) => {
      const fieldErrors = {};

      // Validate size field
      if ((!('size' in row) && !row.shipping_name) || (row.size !== undefined && !row.size?.toString().trim())) {
        fieldErrors.size = 'The shipping size field is required';
      }

      // Validate price field
      if ((!('price' in row) && !row.shipping_price) || (row.price !== undefined && !row.price?.toString().trim())) {
        fieldErrors.price = 'The shipping price field is required';
      } else if (row.price !== undefined && parseFloat(row.price) <= 0) {
        fieldErrors.price = 'Price must be greater than 0';
      }

      // Validate geography field
      if (
        (!('geography' in row) && !row.shipping_country) ||
        (row.geography !== undefined && !row.geography?.toString().trim())
      ) {
        fieldErrors.geography = 'The shipping country is required';
      }

      return fieldErrors;
    };

    const handleUpdate = async (id) => {
      const row = rows.find((element) => element.id === id);
      if (!row) return;

      // Validate current values without falling back to previous values
      const fieldErrors = validateEditFields(row);

      if (Object.keys(fieldErrors).length > 0) {
        setErrors((prev) => ({
          ...prev,
          [id]: fieldErrors,
        }));
        return;
      }

      try {
        const requestData = {
          shipping_name: row.size || row.shipping_name,
          shipping_price: row.price || row.shipping_price,
          shipping_country: row.geography || row.shipping_country,
        };

        const res = await request({
          url: `/artist/personalisedShippingPrice/${id}?_method=PUT`,
          method: 'PUT',
          data: requestData,
          token: userDetails?.token,
        });

        if (res?.statusCode === 200) {
          notify('Shipping option updated successfully', 'success');
          setRows(rows.map((element) => (element.id === id ? { ...element, editing: false } : element)));
          setHasUnsavedRow(false);
          getPersonaliseShipping();
        }
      } catch (error) {
        notify(error?.data?.message, 'error');
      }
    };

    const handleSave = async (id) => {
      const row = rows.find((element) => !element.id);

      if (!row) return;

      // Check only empty or invalid fields
      const fieldErrors = {};
      // Only add errors for fields that fail validation
      if (!row.size?.trim()) {
        fieldErrors.size = 'The shipping size field is required';
      }

      if (!row.price?.toString().trim()) {
        fieldErrors.price = 'The shipping price field is required';
      } else if (row.price && parseFloat(row.price) <= 0) {
        // Only validate price if it exists
        fieldErrors.price = 'Price must be greater than 0';
      }

      if (!row.geography?.toString().trim()) {
        fieldErrors.geography = 'The shipping country is required';
      }

      // Only set errors and show notification if there are actual validation errors
      if (Object.keys(fieldErrors).length > 0) {
        setErrors((prev) => ({
          ...prev,
          [id]: fieldErrors,
        }));
        return;
      }

      try {
        const requestData = {
          shipping_name: row.size,
          shipping_price: row.price,
          shipping_country: row.geography,
        };

        const res = await request({
          url: '/artist/personalisedShippingPrice',
          method: 'POST',
          data: requestData,
          token: userDetails?.token,
        });

        if (res?.statusCode === 200) {
          notify('Shipping option added successfully', 'success');
          setHasUnsavedRow(false);
          await getPersonaliseShipping();
          setSelectedRows([]);
          setErrors({}); // Clear any existing errors on success
        }
      } catch (error) {
        notify(error?.data?.message || 'Failed to save shipping option', 'error');
      }
    };

    const handleChange = (id, field, inputValue) => {
      const currentRow = rows.find((row) => row.id === id);

      // Only check for emojis if there's a value
      if (inputValue && emojiRegex.test(inputValue)) {
        setErrors((prev) => ({
          ...prev,
          [id]: {
            ...(prev[id] || {}),
            [field]: `Emojis are not allowed in ${field}`,
          },
        }));
        notify(`Emojis are not allowed in ${field}`, 'error');
        return;
      }

      let processedValue = inputValue;

      // Price specific validation
      if (field === 'price') {
        processedValue = inputValue.replace(/[^\d.]/g, '');
        if ((processedValue.match(/\./g) || []).length > 1) return;
        if (processedValue.startsWith('.')) {
          processedValue = `0${processedValue}`;
        }
        if (processedValue.includes('.')) {
          const parts = processedValue.split('.');
          if (parts[1]?.length > 2) return;
        }
      }

      // Update the row
      setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, [field]: processedValue } : row)));

      // Only validate empty fields or the currently changing field
      const error = validateField(field, processedValue);

      setErrors((prev) => {
        const newErrors = { ...prev };

        if (error) {
          // Add new error
          newErrors[id] = {
            ...(newErrors[id] || {}),
            [field]: error,
          };
        } else if (newErrors[id]) {
          // Clear error and clean up
          delete newErrors[id][field];
          if (Object.keys(newErrors[id]).length === 0) {
            delete newErrors[id];
          }
        }

        return newErrors;
      });
    };

    const handleCheckbox = (id) => {
      // Only allow selection of saved rows that exist in the current rows
      if (!id || !rows.find((row) => row.id === id)) {
        return;
      }

      const newSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter((rowId) => rowId !== id)
        : [...selectedRows, id];

      setSelectedRows(newSelectedRows);
      onSelectedRowsChange(newSelectedRows);
    };

    // Modify the handleDelete function
    const handleDelete = async (row) => {
      // For newly added rows (they won't have an id)
      if (!row.id) {
        setRows((prevRows) => prevRows.filter((r) => r !== row)); // Filter by reference
        setHasUnsavedRow(false);
        notify('Shipping option deleted successfully', 'success');
        return;
      }

      // For existing rows with IDs
      try {
        const res = await request({
          url: `/artist/personalisedShippingPrice/${row.id}`,
          method: 'DELETE',
          token: userDetails?.token,
        });

        if (res?.statusCode === 200) {
          // Clear selection for the deleted row
          setSelectedRows((prev) => prev.filter((id) => id !== row.id));
          onSelectedRowsChange((prev) => prev.filter((id) => id !== row.id));

          notify('Shipping option deleted successfully', 'success');
          getPersonaliseShipping();
        } else {
          notify('Failed to delete shipping option', 'error');
        }
      } catch (error) {
        notify(error?.message, 'error');
      }
    };

    // Add cleanup effect when rows change
    useEffect(() => {
      // Clean up any selected rows that no longer exist
      const validSelectedRows = selectedRows.filter((id) => rows.some((row) => row.id === id));
      if (validSelectedRows.length !== selectedRows.length) {
        setSelectedRows(validSelectedRows);
        onSelectedRowsChange(validSelectedRows);
      }
    }, [rows]);

    return (
      <Grid container direction="column" spacing={2} className="personalise-table">
        {rows?.map((row) => (
          <Grid container item spacing={1} alignItems="center" key={row.id || 'new'} sx={{ mt: 0 }}>
            <Grid item>
              <Checkbox checked={selectedRows.includes(row.id)} onChange={() => handleCheckbox(row.id)} />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                value={row.editing ? row.size : row.shipping_name}
                onChange={(e) => handleChange(row.id, 'size', e.target.value)}
                placeholder="Size *"
                required
                disabled={row.id && !row.editing}
                error={Boolean(errors[row.id]?.size)}
                helperText={errors[row.id]?.size || ''}
                inputProps={{
                  maxLength: 50, // Add reasonable max length
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                value={row.editing ? row.price : row.shipping_price}
                onChange={(e) => handleChange(row.id, 'price', e.target.value)}
                placeholder="£ (Price) *"
                required
                type="text" // Changed from "number" to "text" for better control
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '^[0-9]*\\.?[0-9]*$',
                }}
                disabled={row.id && !row.editing}
                error={Boolean(errors[row.id]?.price)}
                helperText={errors[row.id]?.price || ''}
                onKeyPress={(e) => {
                  // Prevent minus sign
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                  // Prevent spaces
                  if (e.key === ' ') {
                    e.preventDefault();
                  }
                  // Prevent multiple decimal points
                  if (e.key === '.' && e.target.value.includes('.')) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Select
                size="small"
                value={row.geography || row.shipping_country || ''}
                onChange={(e) => handleChange(row.id, 'geography', e.target.value)}
                disabled={row.id && !row.editing}
                error={Boolean(errors[row.id]?.geography)}
                required
                displayEmpty
                sx={{
                  color: row.geography || row.shipping_country ? 'inherit' : '#ccc', // Placeholder color
                }}
              >
                <MenuItem value="" disabled sx={{ color: '#ccc', opacity: 0.5 }} className="test_data">
                  Select Country *
                </MenuItem>
                {countries?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item?.v_title}
                  </MenuItem>
                ))}
              </Select>
              {errors[row.id]?.geography && <FormHelperText error>{errors[row.id]?.geography}</FormHelperText>}
            </Grid>
            <Grid item>
              <div style={{ display: 'flex', gap: '8px' }}>
                {row.id ? (
                  !row.editing ? (
                    <DarkBtn title="Edit" onClick={() => handleEdit(row.id)} />
                  ) : (
                    <GreenBtn
                      title="Save"
                      onClick={() => handleUpdate(row.id)}
                      // Disable button if there are any errors or empty required fields
                      disabled={
                        Boolean(errors[row.id]) ||
                        !row.size?.trim() ||
                        !row.price?.toString().trim() ||
                        !row.geography?.toString().trim()
                      }
                    />
                  )
                ) : (
                  <>
                    <GreenBtn title="Save" onClick={() => handleSave(row.id)} disabled={Boolean(errors[row.id])} />
                    <IconButton onClick={() => handleDelete(row)} className="delete-icon-btn">
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  },
);

export default PersonalisedShippingTable;
