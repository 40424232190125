import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router';
import OutlineBtn from '../../universal/Buttons/OutLineBtn';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import { globalWindow } from '../../../core/constant';

// eslint-disable-next-line react/function-component-definition
export default function DeleteMyAccount({ open, setOpen, handleDelete, loading }) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const handleNavigate = () => {
    navigate('/support');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="new_contact_dialogbox"
    >
      <DialogContent className="contact_inner">
        <div className="font20px message_text">Are You Sure?</div>
        <div className="font12px para_text">
          If you delete your account your page and artworks will no longer be visible and your data will be deleted. Our
          team is on hand if you wish to talk to us to find out more about how to make the most of your Cosimo
          membership.
        </div>
        <Stack direction="column" spacing={2}>
          <OutlineBtn title="Yes, Delete My Account" onClick={handleDelete} disabled={loading} />
          <DarkBtn title="Speak to the Team" onClick={handleNavigate} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
