import React, { useState, useEffect } from 'react';
import { Box, Grid, Skeleton, Stack, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { images } from '../../assets';

import DirectoryFilter from '../../components/universal/DirectoryFilter';
import DirectoryMobileFilter from '../../components/dialogs/DirectoryMobileFilter';

import { Loader } from '../../components/universal/Loader';
import DirectoryFilterCard from '../../components/universal/DirectoryCardNew';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';
import AxiosAll from '../../services/axiosAll';
import navigator from '../../utils/navigator';
import PaginationBox from '../../components/universal/PaginationBox';
// import { pageLimitOptions } from '../../core/constant';

const pageLimitOptions = [
  { name: '10 Results Per Page', id: 10 },
  { name: '20 Results Per Page', id: 20 },
  { name: '30 Results Per Page', id: 30 },
];

const Directory = () => {
  const [searchInput, setSearchInput] = useState('');
  const [directories, setDirectories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');
  const [parameters, setParameters] = useState({});
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(10);
  const [datas, setdatas] = useState();
  const isShowPagination = datas?.total > 10;
  console.log('total', datas?.total);
  console.log('limit', limit);
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [filterstate, setFilterState] = useState({
    locations: [],
    offerings: [],
    trusted: [],
  });
  // const [locations, setLocation] = useState([]);
  // const [offering, setOffering] = useState([]);
  // const [trusted, setTrusted] = useState([]);

  // console.log('location', filterstate);

  const fetchDirectories = async (filterdata) => {
    setLoading(true);

    const Locations = filterstate.locations.join(',');
    const Offerings = filterstate.offerings.join(',');
    const Trusted = filterstate.trusted.join(',');

    try {
      const response = await AxiosAll(
        'get',
        `artist/directories-list?location=${Locations}&offering=${Offerings}&trusted=${Trusted}&per_page=${limit}&page=${page}&query=${searchInput}`,
        '',
        userDetails.token,
      );
      setDirectories(response.data.data.data);
      setdatas(response.data.data);

      console.log('datas', response.data.data.data);
    } catch (error) {
      console.error('Error fetching directories:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch directories when searchInput changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchDirectories();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, filterstate, page, limit, userDetails.token]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };

  return (
    <div className="directory_filter_screen">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h2 className="mainheadart font20px">Cosimo Directory</h2>
        <div className="mobileshow mobileshow_new">
          <DirectoryMobileFilter filterstate={filterstate} setFilterState={setFilterState} />
        </div>
      </Stack>
      {/* Desktop search bar  */}

      <div className="topsearchdiv " style={{ position: 'relative' }}>
        <TextField
          placeholder="Search galleries, art suppliers, framers & more..."
          type="text"
          className="topsearchbar"
          value={searchInput}
          onChange={(e) => {
            onPageChange(e, 1);
            setSearchInput(e.target.value);
          }}
        />
        <GrayBtn title="Search" onClick={fetchDirectories} />
      </div>

      <Grid container spacing={2} sx={{ ml: 0 }}>
        <Grid item xs={12} sm={2.5} className="padd0 mobilehide">
          <div className="filter_box">
            <DirectoryFilter filterstate={filterstate} setFilterState={setFilterState} />
          </div>
        </Grid>
        <Grid item xs={12} sm={9} className="directory_card_section">
          {loading ? (
            <div>
              <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: '1.5vw', mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: '1.5vw', mb: 2 }} />

              <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: '1.5vw', mb: 2 }} />
            </div>
          ) : directories.length > 0 ? (
            directories.map((directory) => <DirectoryFilterCard key={directory.id} directory={directory} />)
          ) : (
            <p>No results found</p>
          )}
          {isShowPagination && (
            <Box sx={{ mt: 3 }}>
              <PaginationBox
                count={directories?.last_page}
                page={page}
                onChange={onPageChange}
                value={limit}
                pageLimit={pageLimitOptions}
                onChangePageLimit={onPageLimitChange}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Directory;
