import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { formatDate } from '../../../hooks/formatDate';
import DarkBtn from '../Buttons/DarkBtn';
import { useWindowSize } from '../../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../../models/breakpoints.model';

export const NotificationPopUp = ({ cardWidth, radius, item, readNotification }) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    readNotification && readNotification(item?.id);
  };
  const handleClose = () => setOpen(false);

  const nextStepRedirect = (data) => {
    switch (true) {
      case data?.e_type === 'create_artwork':
        return '/portfolio/artworks';
      case data?.e_type === 'follow' || data?.e_type === 'unfollow':
        return '/community/followers';
      case (data?.e_type === 'message' || data?.e_type === 'sendMessage') && !isMobile && !!data?.model_id:
        return `/messages?msgId=${data?.model_id}`;
      case (data?.e_type === 'message' || data?.e_type === 'sendMessage') && isMobile && !!data?.model_id:
        return `/messages/${data?.model_id}`;
      case (data?.e_type === 'message' || data?.e_type === 'sendMessage') && !data?.model_id:
        return `/messages`;
      case (data?.e_type === 'order_received' ||
        data?.e_type === 'dispatch_order' ||
        data?.e_type === 'cancel_order' ||
        data?.e_type === 'order_artwork') &&
        !!data?.model_id:
        return `/sales/orders/detail/${data?.model_id}`;
      case (data?.e_type === 'order_received' ||
        data?.e_type === 'dispatch_order' ||
        data?.e_type === 'cancel_order' ||
        data?.e_type === 'order_artwork') &&
        !data?.model_id:
        return `/sales/orders`;
      case data?.e_type === 'create_account':
        return '/my-account/settings';

      default:
        return '';
    }
  };

  return (
    <div>
      <DarkBtn title="See Notification" onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: cardWidth || 690,
            zIndex: 12,
            background: '#fff',
            borderRadius: radius || 20,
          }}
        >
          <div className="noficationcontainer">
            <Typography variant="h6" component="h2" className="heading-text">
              Notification
            </Typography>
            <Typography
              variant="h6"
              className="cross"
              style={{
                position: 'absolute',
                top: '40%',
                right: 37,
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              X
            </Typography>
          </div>
          <div className="notification-box">
            <Typography variant="h2" className="main-heading">
              {item?.v_title}
            </Typography>
            <Typography
              variant="body1"
              className="top-heading"
              dangerouslySetInnerHTML={{ __html: item?.l_message }}
              style={{ textAlign: 'center' }}
            />

            <Typography variant="body1" className="date">
              Date: <span> {formatDate(item?.created_at)} </span>
            </Typography>
            {item?.e_type !== 'stripe_connected' && (
              <DarkBtn title="Next Steps" onClick={() => navigate(nextStepRedirect(item))} />
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
