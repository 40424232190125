import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { setMessageCount } from '../../redux/actions/messages';

export const SingleMsgCard = ({ border, OnPress, id, img, buyerName, subject, date, time, alt, msg }) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);

  const getMessagesCount = async () => {
    try {
      const res = await AxiosAll('get', 'chatUnreadMessagesCount?loggedAs=seller', '', userDetails?.token);
      const data = res?.data?.data?.unreadCount;

      dispatch(setMessageCount(data));
    } catch (error) {
      console.error('Error fetching message count:', error);
    }
  };

  const handleMessageClick = async () => {
    try {
      const res = await AxiosAll('post', 'chatMessageMarkRead', { chat_id: id }, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        await getMessagesCount(); // Update message count after marking as read
        OnPress(id); // Call the original OnPress handler
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div tabIndex={0} role="button" className="message-card" style={border} key={id} onClick={handleMessageClick}>
      <div className="profile-img">
        <img src={img} width={42} height={42} alt={alt} />
      </div>
      <div className="buyer-details">
        <div className="buyer">
          <h3>{buyerName}</h3>
        </div>
        <p className="msg-date">
          Last message: {time} {date}
        </p>
      </div>
    </div>
  );
};
