import { Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { updateUser } from '../../../redux/actions/user';
import AxiosAll from '../../../services/axiosAll';
import { request } from '../../../services/request';
import notify from '../../../utils/notify';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import TextFieldInput from '../../universal/InputTypeText/TextField';
import sweatAlertBox from '../../universal/SweetAlertBox';

export const EmailUpdate = ({ emailAddress }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isPasswordNewShow, setIsPasswordNewShow] = useState(true);
  const { userDetails } = useSelector((state) => state.user);
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [timerActive, setTimerActive] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsPasswordShow(false);
    setOpen(false);
  };

  const title = 'Verify Email';

  const initialValues = {
    email: emailAddress || '',
    code: '',
  };

  const validationSchema = Yup.object().shape(
    isPasswordShow
      ? {
          email: Yup.string()
            .email('Invalid email address')
            // check if email domain is valid
            .matches(
              // eslint-disable-next-line max-len
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'Invalid email address',
            )
            .required('Email is required')
            .trim(),
          code: Yup.string().required('Code is required.'),
        }
      : {
          email: Yup.string()
            .email('Invalid email address')
            // check if email domain is valid
            .matches(
              // eslint-disable-next-line max-len
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'Invalid email address',
            )
            .required('Email is required')
            .trim(),
        },
  );

  const VerifyEmail = async (values) => {
    setLoading(true);
    const payload = {
      type: 'submit',
      email: values?.email,
      otp: `${values?.code}`,
    };
    const response = await AxiosAll('post', `verifyAppleEmail`, payload, userDetails?.token);
    const data = await response?.data;
    if (data?.statusCode === 200) {
      notify(data?.message, 'success');
      handleClose();
      formik.resetForm();
      setLoading(false);
      getUserProfile();
      // logOut();
    } else {
      notify(data?.message, 'error');
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (timer > 0 && timerActive) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timer, timerActive]);

  const handleResendClick = (value) => {
    // Logic to trigger OTP resend goes here
    // For example: sendOTPRequest();
    RequestCode(value);
    // Reset the timer and start counting down again
    setTimer(60);
    setTimerActive(true);
  };

  const RequestCode = async (payload) => {
    setLoading(true);
    const response = await AxiosAll('post', `verifyAppleEmail`, payload, userDetails?.token);
    const data = await response?.data;
    if (data?.statusCode === 200) {
      notify(data?.message, 'success');
      // handleClose();
      // formik.resetForm();
      setLoading(false);
      setIsPasswordShow(true);
      // logOut();
    } else {
      notify(data?.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    !isPasswordShow
      ? sweatAlertBox(
          'Once you click the proceed button, you will get a confirmation code on this email.',
          '',
          () =>
            RequestCode({
              email: values?.email,
              type: 'sendOtp',
            }),
          'Proceed',
          'Cancel',
        )
      : VerifyEmail(values);
  };

  const getUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      dispatch(updateUser(res.data));
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleClickOpen = () => {
    setOpen(true);
    formik.resetForm();
  };

  return (
    <div>
      <Dialog open={open} style={{ zIndex: 100 }}>
        <div className="reset-password">
          <DialogTitle align="center" className="main-title">
            {title}
          </DialogTitle>
          <DialogContent className="pop-content">
            <DialogContentText>
              <form className="reset-form" onSubmit={formik?.handleSubmit}>
                <TextFieldInput
                  placeholder="Enter Email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {!isPasswordShow ? (
                  <DarkBtn
                    loading={loading}
                    type="submit"
                    title="Verify Email"
                    // onClick={() => setIsPasswordShow(true)}
                  />
                ) : (
                  <>
                    <TextFieldInput
                      placeholder="Enter Code"
                      name="code"
                      id="code"
                      value={formik.values.code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="number"
                      error={formik.touched.code && Boolean(formik.errors.code)}
                      helperText={formik.touched.code && formik.errors.code}
                    />
                    <div className="resend-otp">
                      {timer > 0 ? (
                        <>Resend OTP in {timer} seconds</>
                      ) : (
                        <>
                          Didn’t receive the OTP?
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            className="resend-achor-tag"
                            onClick={() =>
                              RequestCode({
                                email: formik.values?.email,
                                type: 'sendOtp',
                              })
                            }
                            type="button"
                          >
                            Resend OTP
                          </Link>
                        </>
                      )}
                    </div>
                    <DarkBtn loading={loading} type="submit" title="Submit" />
                  </>
                )}
              </form>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
