import React, { useRef } from 'react';

const VeedPlayer = ({ videoURl }) => {
  const iframeRef = useRef(null);

  return (
    <iframe
      ref={iframeRef}
      src={videoURl}
      width="744"
      height="504"
      frameBorder="0"
      title="Veed.io Video"
      allowFullScreen
    />
  );
};

export default VeedPlayer;
