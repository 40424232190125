import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@mui/material';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Changed to arrow function
const MultipleSelectBox = ({
  options = [],
  value,
  onChange,
  onBlur,
  error,
  helperText,
  name,
  className,
  lableName, // Add this prop
}) => {
  const getSelectedNames = (selectedIds) => {
    if (!selectedIds || !Array.isArray(selectedIds)) return [];
    return options
      .filter((option) => selectedIds.includes(option.id.toString()))
      .map((option) => option.v_label_name || option.name);
  };

  const handleChange = (event) => {
    const selectedIds = event.target.value;
    const syntheticEvent = {
      target: {
        name,
        value: Array.isArray(selectedIds) ? selectedIds : [selectedIds],
      },
    };
    onChange(syntheticEvent);
  };

  const normalizedValue = Array.isArray(value) ? value : value ? [value] : [];

  return (
    <div className="multiselect">
      <FormControl sx={{ m: 1, minWidth: 300 }} className="selectboxwidth">
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={normalizedValue}
          onChange={handleChange}
          onBlur={onBlur}
          name={name}
          renderValue={(selected) => {
            // If no values are selected, show the label name as placeholder
            if (selected.length === 0) {
              return lableName || 'Select Options';
            }
            return getSelectedNames(selected).join(', ');
          }}
          displayEmpty // Add this to show placeholder when empty
          MenuProps={MenuProps}
          className={`multi-select-field ${className}`}
          error={error}
        >
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id.toString()}>
              <Checkbox checked={normalizedValue.includes(option.id.toString())} />
              <ListItemText primary={option.v_label_name} />
            </MenuItem>
          ))}
        </Select>
        {error && helperText && <div className="error_text">{helperText}</div>}
      </FormControl>
    </div>
  );
};

export default MultipleSelectBox;
