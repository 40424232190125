import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { SimpleSelectBox } from '../InputTypeText/SelectBox';
import { CardContainer } from '../universal/CardContainer';
import { TagAddInput } from './TagAddInput';
import { SelectBoxLabel } from '../InputTypeText/SelectBoxLabel';
import { images } from '../../assets';

export const ExtraTag = ({
  dropdowns,
  tagKeywords,
  setArtworkTags,
  artworkTagsRef,
  fetchedData,
  setGoodToGo,
  plan,
  onVisiblityChange,
}) => {
  const initialValues = {
    tags: [],
    is_adult_content: '0',
    add_to_collection: '',
    visible_to_public: 'disabled',
    colours: [],
  };
  const onVisiblityTypeChange = (e) => {
    formik.setFieldValue('visible_to_public', e.target.value);
    onVisiblityChange(e.target.value);
  };
  const validationSchema = Yup.object().shape({
    tags: Yup.array()
      .of(Yup.string())
      .when('visible_to_public', {
        is: 'enabled',
        then: () => Yup.array().min(1, 'At least one tag is required'),
        otherwise: () => Yup.array().notRequired(),
      }),
    add_to_collection: Yup.string(),
    visible_to_public: Yup.string().required('Listing status is required'),
    colours: Yup.array().of(Yup.string()),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setGoodToGo((prev) => ({ ...prev, tags: true }));
      setArtworkTags(values);
    },
  });

  const error = document?.querySelector('.error_text');

  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setGoodToGo((prev) => ({ ...prev, tags: false }));
  }, [error, setGoodToGo]);

  useEffect(() => {
    if (fetchedData?.artworkTag) {
      const data = {
        ...fetchedData?.artworkTag,
      };
      formik.setValues(data);
      setArtworkTags(data);
    }
  }, [fetchedData]);
  return (
    <CardContainer className="extra-tag">
      <Typography paragraph>Extras</Typography>
      <div className="presets">
        <div className="tags">
          <Typography paragraph>Tags (Add Up To Three) *</Typography>
          <TagAddInput placeholder="text" formik={formik} tagKeywords={tagKeywords} />
        </div>
        {(plan?.v_no_of_sub_collections > 0 || plan?.v_no_of_sub_collections === 'Unlimited') && (
          <SelectBoxLabel
            lableName="Add to Collection"
            name="add_to_collection"
            className="collection"
            options={dropdowns?.my_collections?.map((cat) => {
              return { id: cat?.id, v_label_name: cat?.title };
            })}
            value={formik?.values?.add_to_collection}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={formik?.touched?.add_to_collection && formik?.errors?.add_to_collection}
            helperText={formik?.touched?.add_to_collection && formik?.errors?.add_to_collection}
            // defaultOption="Add to Collection"
            // defaultValue={0}
          />
        )}
        <SelectBoxLabel
          lableName="Listing Status"
          // isRequired
          name="visible_to_public"
          className="public"
          options={[
            { id: 'disabled', v_label_name: 'Draft' },
            { id: 'enabled', v_label_name: 'Public' },
          ]}
          value={formik?.values?.visible_to_public}
          onChange={onVisiblityTypeChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.visible_to_public && formik?.errors?.visible_to_public}
          helperText={formik?.touched?.visible_to_public && formik?.errors?.visible_to_public}
          // defaultOption="Visible to Public?"
          // defaultValue={0}
        />
        <div className="color-pick">
          <Typography paragraph>Colour</Typography>
          {dropdowns?.colours?.map((color, index) => {
            return (
              <FormControlLabel
                key={`colours${index}`}
                name="colours"
                style={{
                  background: color?.v_colour_code,
                  border:
                    (color?.v_colour_code === '#fff' || color?.v_colour_code === '#ffffff') && '1px solid #D0CFCF ',
                }}
                className={formik?.values?.colours?.includes(color?.id.toString()) ? null : 'colordiv'}
                control={
                  <Checkbox
                    name="colours"
                    value={color?.id}
                    checked={formik?.values?.colours?.includes(color?.id.toString())}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.colours && formik?.errors?.colours}
                    // helperText={formik?.touched?.colours && formik?.errors?.colours}
                  />
                }
              />
            );
          })}
        </div>
      </div>
      <input hidden onClick={formik?.handleSubmit} ref={artworkTagsRef} />
    </CardContainer>
  );
};
