import { Box, Skeleton } from '@mui/material';
import React from 'react';

const VideoLibrarySkeleton = () => {
  // Number of module skeletons to show
  const moduleCount = 8;

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', p: 4 }}>
      {/* Title Skeleton */}
      <Skeleton variant="text" width="200px" height={40} sx={{ mb: 4 }} />

      {/* Module Skeletons */}
      {[...Array(moduleCount)].map((_, index) => (
        <Box
          key={index}
          sx={{
            mb: 4,
            backgroundColor: 'white',
            borderRadius: 4,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Module Title Skeleton */}
            <Skeleton variant="text" width="150px" height={38} />

            {/* Arrow Icon Skeleton */}
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default VideoLibrarySkeleton;
