import { Skeleton, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../assets';
import { AddArtwork } from '../../components/MyArtworks/AddArtwork';
import { ProfileCardSkeleton } from '../../components/Skeleton/ProfileCardSkeleton';
import ArtworkCollectionCard from '../../components/universal/ArtworkCollectionCard/Index';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { EmptyText } from '../../components/universal/EmptyText';
import PaginationBox from '../../components/universal/PaginationBox';
import sweatAlertBox from '../../components/universal/SweetAlertBox';
import { globalWindow, notifyMessages, pageLimitOptions } from '../../core/constant';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import { request } from '../../services/request';
import navigator from '../../utils/navigator';
import notify from '../../utils/notify';
import { ArtistCardSkeleton } from '../../components/Skeleton/ArtistCardSkeleton';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';
import AxiosAll from '../../services/axiosAll';
import { CollectionButton } from '../../components/MyCollection/AddCollection';
import { setSubscription } from '../../redux/actions/subscription';
import OutlineBtn from '../../components/universal/Buttons/OutLineBtn';
import { ArtistTable } from '../../components/artistTable/ArtistTable';
import { CollectionTable } from '../../components/collectionTable';
import UnderLineBtn from '../../components/universal/Buttons/UnderLineBtn';
import { TableSkeleton } from '../../components/Skeleton/TableSkeleton';

const MyCollections = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');
  const orderByParams = searchParam.get('order_by');
  const dispatch = useDispatch();

  const [AccountStatusPoint, setAccountStatusPoint] = useState({});
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [artworkData, setArtworkData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(12);
  const [isLoading, setIsLoading] = useState(true);
  const [parameters, setParameters] = useState({});
  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;

  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  const isShowPagination = !isLoading && collectionData?.data?.filter((e) => e.allow_access === 1)?.length > limit;

  const getArtworkCollections = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: '/artist/collections?is_artist_dashboard=1',
        method: 'GET',
        params: {
          page,
          limit,
          search_keyword: search,
          type: 'my-collection',
          order_by: 'created_at',
        },
        token: userDetails?.token,
      });
      setCollectionData(res?.data);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, limit, search, userDetails?.token]);

  const getArtwork = async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: '/artist/artworks?is_artist_dashboard=1',
        method: 'GET',
        params: {
          artist: userDetails?.user?.id,
          artistId: userDetails?.user?.id,
          slug: userDetails?.user?.v_artists_slug,
          page,
          limit,
          order_by: orderByParams,
          search_keyword: search,
          //   type: 'favourite_artworks',
        },
        token: userDetails?.token,
      });
      document.getElementById('top').scrollIntoView({
        behavior: 'smooth',
      });
      setArtworkData(res?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAccountStatus = async () => {
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      setAccountStatusPoint(data);
    }
  };

  const validateSteps = (data) => {
    if (
      !data?.account_detail ||
      !data?.tell_us_about_youself ||
      !data?.accept_payments ||
      !data?.organise_shipping ||
      !data?.list_your_artwork
    ) {
      notify(notifyMessages?.profileCollectionMsg, 'error');
    } else {
      navigate('/portfolio/collections/add-collection');
    }
  };

  const handleSelect = (item) => {
    if (selectedItems.includes(item?.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== item.id));
    } else {
      setSelectedItems([...selectedItems, item.id]);
    }
  };

  const getCurrentSubscription = async () => {
    const res = await AxiosAll(
      'GET',
      'artist/artist_current_active_subscription_plan_detail',
      null,
      userDetails?.token,
    );
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
    }
  };
  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const handleAction = async (status) => {
    try {
      const res = await request({
        url: status === 'Deactivate' ? '/artist/collection-deactivate' : '/artist/collection-activate',
        method: 'POST',
        data: {
          ids: selectedItems,
        },
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        setSelectedItems([]);
        getArtworkCollections();
      } else {
        notify(res?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await request({
        url: '/artist/collections',
        method: 'DELETE',
        data: {
          ids: selectedItems,
        },
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        setConfirmDel(false);
        setSelectedItems([]);
        getArtworkCollections();
        notify(res?.message, 'success');
      }
    } catch (error) {
      setConfirmDel(false);
      notify(error?.data?.message, 'error');
    }
  };

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 0) {
        setPage(1);
      } else {
        setPage(pageParams ? +pageParams : 1);
      }
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    confirmDel && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDel]);

  useEffect(() => {
    getArtwork();
    getAccountStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getArtworkCollections();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [getArtworkCollections]);

  useEffect(() => {
    document.getElementById('top').scrollIntoView({
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams, limit]);

  const handleNavigate = () => {
    navigate('/my-subscription');
  };
  return (
    <div className="artworkscollection newartistpage" id="top">
      <div className="artrightsection">
        <div className="new-btn-area">
          <Typography
            variant="h1"
            // style={{ whiteSpace: 'nowrap' }}
          >
            Collections
          </Typography>
          <div>
            {selectedItems.length > 0 ? (
              <div className="btn-group">
                <OutlineBtn title="Deactivate" onClick={() => selectedItems.length > 0 && handleAction('Deactivate')} />
                <OutlineBtn title="Activate" onClick={() => selectedItems.length > 0 && handleAction('Activate')} />
                <OutlineBtn
                  title="Delete"
                  onClick={() => {
                    selectedItems.length > 0 &&
                      sweatAlertBox(
                        'Are You Sure?',
                        `You are about to delete ${
                          selectedItems.length > 1 ? 'these collections.' : 'this collection.'
                        } You cannot undo this action once confirmed.`,
                        setConfirmDel,
                        'Confirm',
                        'Cancel',
                      );
                  }}
                />
                {/* {plan?.v_display_name !== 'Community' ? ( */}
                <DarkBtn
                  title="Add New +"
                  onClick={() => {
                    navigate('/portfolio/collections/add-collection');
                  }}
                />
                {/* ) : (
                  <DarkBtn
                    title="Upgrade plan"
                    onClick={() => {
                      navigate('/my-subscription');
                    }}
                  />
                )} */}
              </div>
            ) : (
              <div className="btn-group">
                <GrayBtn title="Deactivate" />
                <GrayBtn title="Activate" />
                <GrayBtn disabled title="Delete" />
                {/* {plan?.v_display_name !== 'Community' ? ( */}
                <DarkBtn
                  title="Add New +"
                  onClick={() => {
                    navigate('/portfolio/collections/add-collection');
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <Typography className="para-art" variant="body1">
          You currently have {collectionData?.total_active_collection} collections active collection out of a possible{' '}
          {collectionData?.total_allow_access}. You can <UnderLineBtn onClick={handleNavigate} title="upgrade" /> your
          account to list more collection.
          {/* {plan?.plan_type === 'paid' ? (
            `You have ${collectionData?.total_active_collection} active collections. You can create an ${plan?.v_no_of_sub_collections} number of collections on your plan.`
          ) : (
            <>
              You have {collectionData?.total_active_collection} active collections out of total of{' '}
              {plan?.v_no_of_sub_collections} available collections. To be able to create unlimited collections,{' '}
              <UnderLineBtn onClick={handleNavigate} title="upgrade" /> your plan.
            </>
          )} */}
        </Typography>

        <div className="artcardarea">
          {!isLoading ? (
            <CollectionTable
              dataMap={collectionData?.collections?.data}
              tableAlignment="center"
              allowedAccess={artworkData?.data?.allow_access}
              handleSelect={handleSelect}
              selected={selectedItems}
              artworks
            />
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCollections;
