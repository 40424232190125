import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { truncateMessage } from '../../../core/constant';

const MsgPreviewBox = ({ senderName, senderId, senderDescription, sendDate, sendTime }) => {
  const [readMore, setReadMore] = useState(false);
  const { userDetails } = useSelector((state) => state.user);

  const formatMessage = (message) => {
    return message?.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  };

  return (
    <div className="message-preview-box">
      <h3 className="message-text font20px">
        {userDetails?.user?.id === senderId ? 'You' : senderName}:
        <span>
          {senderDescription?.length > 100 && !readMore
            ? formatMessage(truncateMessage(senderDescription, 100))
            : formatMessage(senderDescription)}
        </span>
        <br />
        {senderDescription?.length > 100 && (
          <button type="button" className="read-morebtn" onClick={() => setReadMore(!readMore)}>
            {readMore ? 'Show less' : 'Read more'}
          </button>
        )}
      </h3>
      <p className="date-text">
        {sendTime} {sendDate}
      </p>
    </div>
  );
};

export default MsgPreviewBox;
