/* eslint-disable camelcase */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import * as React from 'react';
import { useSelector } from 'react-redux';
import currentURL from '../../../env';
import notify from '../../../utils/notify';
import MostPopular from '../../UnlockCourseCard/MostPopular';

function removeApiFromEnd(url) {
  return url.replace(/\/api\/?$/, '');
}
// eslint-disable-next-line react/function-component-definition
export default function UnlockCourseDialogs({ setOpen, open, data, module }) {
  const {
    userDetails: { user },
  } = useSelector((state) => state.user);

  const handleSubscribe = async (subscriptionData) => {
    try {
      const { v_price_id } = subscriptionData;
      const url = removeApiFromEnd(currentURL.currentEnv);
      const urlWithoutParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

      window.location.href = `${url}/stripe/checkout?seller_id=${user?.id}&module_id=${module?.id}&plan_id=${v_price_id}&returnUrl=${urlWithoutParams}`;
    } catch (error) {
      notify(error.message, 'error');
      console.log('🚀 ~ handleSubscribe ~ error:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="unlock_course_dialogbox"
    >
      <DialogContent>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }} className="close_icon_most_popular">
          <CloseIcon onClick={handleClose} />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          {data &&
            data?.map(
              (item, index) =>
                item?.v_recurring_interval !== 'year' && (
                  <MostPopular data={item} key={index} onSubscribe={handleSubscribe} module={module} />
                ),
            )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
