/* eslint-disable no-shadow */
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import * as React from 'react';

// Sample response from API

// eslint-disable-next-line react/function-component-definition
export default function ContinuousSlider({ data = [], onChange, fixedValue, disabled }) {
  const [value, setValue] = React.useState(fixedValue || 0);

  const values = data.map((item) => parseInt(item.v_label_name, 10));

  const max = React.useMemo(() => (Math.max(...values) === Infinity ? 0 : Math.max(...values)), [values]);

  React.useEffect(() => {
    if (fixedValue !== undefined) {
      setValue(fixedValue);
    }
  }, [fixedValue]);

  const handleChange = (_event, newValue) => {
    if (disabled) return;

    // Snap to nearest value from `data`
    const snappedValue = values.reduce((prev, curr) =>
      Math.abs(curr - newValue) < Math.abs(prev - newValue) ? curr : prev,
    );
    setValue(snappedValue);
    const selectedValue = data?.find((e) => Number(e?.v_label_name) === snappedValue);
    if (onChange) onChange(selectedValue || {});
  };

  return (
    <Box sx={{ minWidth: 200 }} className="slide_data">
      <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
        <Slider
          aria-label="Dynamic Slider"
          value={value}
          onChange={handleChange}
          min={0}
          max={max}
          step={null}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => value}
          marks={values.map((value) => ({
            value,
          }))}
          disabled={disabled}
        />
        <Typography>{value}</Typography>
      </Stack>
    </Box>
  );
}
