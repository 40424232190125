/* eslint-disable react/function-component-definition */
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { globalWindow } from '../../../core/constant';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import AccountMenu from '../../universal/header/AccountMenu/AccountMenu';

export default function LoginSignInDialog() {
  const [open, setOpen] = React.useState(false);
  const { userDetails } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {userDetails?.token ? (
        <AccountMenu />
      ) : (
        <>
          <div className="loginbtntop mobilehide">
            <Button className="loginbtn " onClick={handleClickOpen}>
              Join or Sign In
            </Button>
          </div>
          <div tabIndex={0} onClick={handleClickOpen} role="button">
            <img src="icons/user.svg" alt="user" width={28} height={28} className="mobileshow" />
          </div>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="loginsignin"
      >
        <div className="clear-icon">
          <ClearIcon
            onClick={handleClose}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
        <DialogTitle id="alert-dialog-title" className="font25px dialogHeading mobilehide">
          How Would You Describe Yourself?
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" className="font25px mobileshow">
          Welcome to Cosimo!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="mobileshow font25px aretext">Are you an...</div>
            <div className="btn-section">
              <div className="artistbtnset">
                <DarkBtn
                  title="Artist"
                  onClick={() => {
                    setOpen(false);
                    navigate('/signup', { state: location?.state });
                  }}
                />
              </div>
              <div className="or_text mobileshow">or</div>
              <div>
                <DarkBtn
                  title="Art Lover"
                  onClick={() => {
                    setOpen(false);
                    globalWindow.location.href = `${process.env.REACT_APP_USER_APP_URL}dashboard`;
                  }}
                />
              </div>
            </div>
            <div
              role="button"
              tabIndex={0}
              className="font16px already-text"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(false);
                navigate('/login', { state: location?.state });
              }}
            >
              Already Have an Account? Log In Here
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
