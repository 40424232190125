import { Skeleton, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../assets';
import { AddArtwork } from '../../components/MyArtworks/AddArtwork';
import { ArtistCardSkeleton } from '../../components/Skeleton/ArtistCardSkeleton';
import ArtworkCollectionCard from '../../components/universal/ArtworkCollectionCard/Index';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';
import { EmptyText } from '../../components/universal/EmptyText';
import PaginationBox from '../../components/universal/PaginationBox';
import sweatAlertBox from '../../components/universal/SweetAlertBox';
import { globalWindow, notifyMessages, pageLimitOptions } from '../../core/constant';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import navigator from '../../utils/navigator';
import notify from '../../utils/notify';
import { nextStep } from '../dashboard';
import UnderLineBtn from '../../components/universal/Buttons/UnderLineBtn';
import { setSubscription } from '../../redux/actions/subscription';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import OutlineBtn from '../../components/universal/Buttons/OutLineBtn';
import { ArtistTable } from '../../components/artistTable/ArtistTable';
import { TableSkeleton } from '../../components/Skeleton/TableSkeleton';

const MyArtworks = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');
  const orderByParams = searchParam.get('order_by');

  const [AccountStatusPoint, setAccountStatusPoint] = useState({});
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [artworkData, setArtworkData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [accountStatusFetching, setAccountStatusFetched] = useState(false);
  const [parameters, setParameters] = useState({});
  const isShowPagination = !isLoading && artworkData?.data?.filter((e) => e.allow_access === 1)?.length > limit;
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;

  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;
  const getArtwork = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: '/artist/artworks?is_artist_dashboard=1',
        method: 'GET',
        params: {
          artist: userDetails?.user?.id,
          artistId: userDetails?.user?.id,
          slug: userDetails?.user?.v_artists_slug,
          page,
          limit,
          order_by: 'most-recent',
          search_keyword: search,
          //   type: 'favourite_artworks',
        },
        token: userDetails?.token,
      });
      setArtworkData(res?.data);
      AccountStatusPoint?.list_your_artwork === 0 && getAccountStatus();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [orderByParams, page, limit, search, userDetails?.token]);

  const handleSelect = (item) => {
    if (selectedItems.includes(item.id)) {
      setSelectedItems(selectedItems.filter((i) => i !== item.id));
    } else {
      setSelectedItems([...selectedItems, item.id]);
    }
  };

  const handleAction = async (status) => {
    try {
      const res = await request({
        url: status === 'Deactivate' ? '/artist/artwork-deactivate' : '/artist/artwork-activate',
        method: 'POST',
        data: {
          ids: selectedItems,
        },
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        setSelectedItems([]);
        getArtwork();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await request({
        url: '/artist/artworks',
        method: 'DELETE',
        data: {
          ids: selectedItems,
        },
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        setConfirmDel(false);
        setSelectedItems([]);
        getArtwork();
        notify(res?.message, 'success');
      }
    } catch (error) {
      setConfirmDel(false);
      notify(error?.data?.message, 'error');
    }
  };

  const handleDuplicate = async () => {
    if (selectedItems?.length === 1) {
      try {
        const res = await request({
          url: `/artist/artwork-duplicate/${selectedItems[0]}`,
          method: 'POST',

          token: userDetails?.token,
        });
        if (res?.statusCode === 200) {
          setConfirmDel(false);
          setSelectedItems([]);
          getArtwork();
          notify(res?.message, 'success');
        }
      } catch (error) {
        setConfirmDel(false);
        notify(error?.data?.message, 'error');
      }
    } else {
      notify(notifyMessages?.duplicateErrorMsg, 'error');
    }
  };

  const getAccountStatus = async () => {
    setAccountStatusFetched(true);
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      setAccountStatusPoint(data);
      setAccountStatusFetched(false);
    } else {
      setAccountStatusFetched(false);
    }
  };

  const getCurrentSubscription = async () => {
    const res = await AxiosAll(
      'GET',
      'artist/artist_current_active_subscription_plan_detail',
      null,
      userDetails?.token,
    );
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
    }
  };
  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  const validateSteps = (data) => {
    // if (!data?.account_detail || !data?.tell_us_about_youself) {
    //   return notify(notifyMessages?.profileArtworkMsg, 'error');
    // }
    // return artworkData?.data?.filter(e=>e.allow_access === 1)?.length  < plan?.v_no_of_artworks || plan?.v_no_of_artworks > 25
    //   ?

    navigate('/portfolio/artworks/add-artwork', {
      state: { planCount: artworkData?.data?.filter((e) => e.allow_access === 1)?.length >= plan?.v_no_of_artworks },
    });
    // : navigate('/my-subscription');
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 0) {
        setPage(1);
      } else {
        setPage(pageParams ? +pageParams : 1);
      }
      setSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  useEffect(() => {
    confirmDel && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDel]);

  useEffect(() => {
    getArtwork();
  }, [getArtwork]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getAccountStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.getElementById('top').scrollIntoView({
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams, limit]);

  const handleNavigate = () => {
    navigate('/my-subscription');
  };

  return (
    <div className="savedartworkspage newartistpage">
      <div className="artrightsection" id="top">
        <div className="new-btn-area">
          <Typography variant="h1">Artworks</Typography>
          <div>
            {selectedItems.length > 0 ? (
              <div className="btn-group">
                {/* onClick={() => selectedItems.length > 0 && handleAction('Deactivate')} */}
                <OutlineBtn title="Duplicate" onClick={handleDuplicate} />
                <OutlineBtn
                  title="Delete"
                  onClick={() => {
                    selectedItems.length > 0 &&
                      sweatAlertBox(
                        'Are You Sure?',
                        `You are about to delete ${
                          selectedItems.length > 1 ? 'these artworks.' : 'this artwork.'
                        } You cannot undo this action once confirmed.`,
                        setConfirmDel,
                        'Confirm',
                        'Cancel',
                      );
                  }}
                />
                <DarkBtn
                  title="Add New +"
                  onClick={() => {
                    navigate('/portfolio/artworks/add-artwork');
                  }}
                />
              </div>
            ) : (
              <div className="btn-group">
                <GrayBtn disabled title="Duplicate" />
                <GrayBtn disabled title="Delete" />
                <DarkBtn
                  title="Add New +"
                  onClick={() => {
                    navigate('/portfolio/artworks/add-artwork');
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <Typography className="para-art" variant="body1">
          You currently have {artworkData?.total_enabled} artworks active artworks out of a possible{' '}
          {artworkData?.total_access_allowed}. You can <UnderLineBtn onClick={handleNavigate} title="upgrade" /> your
          account to list more
          {/* {plan?.plan_type === 'paid' ? (

            
            `You have ${artworkData?.total_enabled} active artworks. You can create an ${plan?.v_no_of_artworks} number of artworks on your plan.`
          ) : (
            <>
              You have {artworkData?.total_enabled} active artworks out of a total of {plan?.v_no_of_artworks} available
              artworks. To create unlimited artworks,
              <UnderLineBtn onClick={handleNavigate} title="upgrade" /> your plan.
            </>
          )} */}
        </Typography>

        <div className="artcardarea">
          {!isLoading ? (
            <ArtistTable
              StyleName="gray_theme_update"
              dataMap={artworkData?.artworks?.data}
              tableAlignment="center"
              allowedAccess={artworkData?.data?.allow_access}
              handleSelect={handleSelect}
              selected={selectedItems}
              artworks
              getArtwork={getArtwork}
              allowedViews={plan?.v_analytics_tools === 'Advanced'}
            />
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyArtworks;
