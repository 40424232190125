/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { Grid, Tooltip, tooltipClasses } from '@mui/material';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation, useParams } from 'react-router';

import { Link, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/system';

import { images } from '../../../../assets';

import { globalWindow, notifyMessages } from '../../../../core/constant';
import notify from '../../../../utils/notify';
import LoginSignInDialog from '../../../dialogs/JoinOrSignIn';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: '#fff',
      maxWidth: 450,
      left: '60px',
      marginTop: '36px !important',
      Padding: '0px !important',
    },
    [`& .${tooltipClasses.arrow}`]: {
      borderTopColor: 'yellow',
      color: '#fff',
      left: '-36px !important',
    },
  }),
);

export const DesktopViewHeader = ({ headerData, search, setSearch, searchQuery, userDetails }) => {
  // const navigate = useRouter();
  const params = useParams();

  const router = useSearchParams();
  const { pathname } = useLocation();
  // const active_index=router.get("main_category")||""
  // const pathnamenew = usePathname();

  return (
    <div className="mainsection mobilehide">
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          if (pathname.includes('personalize') && !userDetails?.user?.is_completed_seller) {
            notify(notifyMessages?.completePersonalization, 'error');
            return;
          }
          globalWindow.location.href = `${process.env.REACT_APP_USER_APP_URL}`;
        }}
        className="mainlogodiv"
      >
        <img src={images?.mainLogo} alt="top bar" className="mainlogo" />
      </div>
      {!pathname.includes('personalize') && (
        <div className="mainmenu">
          {userDetails?.token ? (
            <div className="navbar">
              <div className="subnav subnavbtn">
                <Link href="/dashboard" to="/dashboard" className="menumaintext">
                  Dashboard
                </Link>
              </div>
              <div className="subnav subnavbtn">
                <Link
                  href={`${process.env.REACT_APP_USER_APP_URL}artist/${
                    userDetails?.user?.v_artist_name || `${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}`
                  }/${userDetails?.user?.id}`}
                  to={`${process.env.REACT_APP_USER_APP_URL}artist/${
                    userDetails?.user?.v_artist_name || `${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}`
                  }/${userDetails?.user?.id}`}
                  className="menumaintext"
                >
                  View Portfolio
                </Link>
              </div>
              <div className="subnav subnavbtn">
                <Link
                  href={`${process.env.REACT_APP_USER_APP_URL}match-maker`}
                  to={`${process.env.REACT_APP_USER_APP_URL}match-maker`}
                  className="menumaintext"
                >
                  Explore
                </Link>
              </div>
            </div>
          ) : (
            // new header implementation

            <div className="navbar">
              <div className="subnav subnavbtn">
                <Link to={`${process.env.REACT_APP_USER_APP_URL}about-us`} className="menumaintext">
                  About Us
                </Link>
              </div>
              <div className="subnav subnavbtn">
                <Link to={`${process.env.REACT_APP_USER_APP_URL}how-it-works`} className="menumaintext">
                  How it Works
                </Link>
              </div>
              <div className="subnav subnavbtn">
                <Link to={`${process.env.REACT_APP_USER_APP_URL}search/artists/search-all`} className="menumaintext">
                  Our Community
                </Link>
              </div>
            </div>
          )}
        </div>
      )}

      <Grid item xs={1.4} sm={5} className="signbtnright alignmentcart">
        <LoginSignInDialog />

        {!userDetails?.token && (
          <div className="carticontop">
            <Link href={`${process.env.REACT_APP_USER_APP_URL}cart`} to={`${process.env.REACT_APP_USER_APP_URL}cart`}>
              <img src={images.cartIcon} alt="carticon" className="valuecart" />
              <span className="valuecart">(0)</span>
            </Link>
          </div>
        )}
      </Grid>
    </div>
  );
};
