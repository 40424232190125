import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-circular-progressbar/dist/styles.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';
import InfoTooltip from '../ToolTipComponent';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    width: '5vw',
    height: '1.7vw',
    left: '20px !important',
    top: '-15px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F8F8F8',
    color: 'black',
    minWidth: '32.96vw',
    boxShadow: ' 0px 4px 4px 0px #00000040',
    borderRadius: '20px',
    padding: '16px 20px',
  },
}));
const PortfolioProgress = ({ para, title, heading, percentage, onPress, portfolio, sales }) => {
  return (
    <div className="progress_data">
      <p
        className="font14px"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px', marginTop: 10 }}
      >
        {title}
        <InfoTooltip portfolio={portfolio} sales={sales} />
      </p>

      <div style={{ width: 100, margin: 'auto' }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}`}
          styles={buildStyles({
            pathColor: '#80C86E',
            textColor: '#80C86E',
            trailColor: '#f4f4f4',
            textSize: '20px',
          })}
        />
      </div>
      <div className="text_center_line">
        <button
          type="submit"
          className="font14px"
          style={{
            textAlign: 'center',
            marginTop: 10,
            textDecoration: 'underline',
            cursor: percentage === 100 ? 'auto' : 'pointer',
          }}
          onClick={onPress}
        >
          {percentage === 100 ? 'Completed' : para}
        </button>
      </div>
    </div>
  );
};

export default PortfolioProgress;
