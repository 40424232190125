import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Backdrop, Button, CircularProgress, FormHelperText, Typography, Tooltip } from '@mui/material';
import { Image } from '@mui/icons-material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import TextAreaBox from '../../components/universal/InputTypeText/TextareaBox';
import Editor from '../../components/InputTypeText/Editor';
import { CardContainer } from '../../components/universal/CardContainer';
import { formats, modules, notifyMessages, baseURL, globalWindow } from '../../core/constant';
import notify from '../../utils/notify';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import Share from '../../components/universal/Share';
import { formatAIResponseToHTML, formatAIResponseToHTMLBio } from '../../utils/formatAIResponse';
import { images } from '../../assets';
import { StudioPopup } from '../../components/ArtistStatement/StudioPopup';
import { CVPopup } from '../../components/ArtistStatement/CVpopup';

function htmlToPlainText(htmlString) {
  const tempElement = document.createElement('div');

  tempElement.innerHTML = htmlString;

  return tempElement.textContent || tempElement.innerText || '';
}
const ArtistStatement = () => {
  const [loading, setLoading] = useState({ initial: false, bio: false, statement: false, submit: false });
  const [loadingBio, setLoadingBio] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [bioContent, setBioContent] = useState('');
  const [bioStatement, setBioStatement] = useState('');
  const [previousBioContent, setPreviousBioContent] = useState('');
  const [previousStatementContent, setPreviousStatementContent] = useState('');
  const [isDirtyBio, setIsDirtyBio] = useState(false);
  const [isDirtyStatement, setIsDirtyStatement] = useState(false);
  const { userDetails } = useSelector((state) => state.user);
  const [profileData, setProfileData] = useState({});
  const [showBioUndo, setShowBioUndo] = useState(false);
  const [showStatementUndo, setShowStatementUndo] = useState(false);
  const [showStudioModal, setShowStudioModal] = useState(false);
  const [showCvPopup, setShowCvPopup] = useState(false);
  const [editingBioLink, setEditingBioLink] = useState(false);
  const [editingStatementLink, setEditingStatementLink] = useState(false);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  const [storedBioLink, setStoredBioLink] = useState(() => {
    const savedLink = localStorage.getItem('biostudioLink');
    return savedLink && savedLink !== '[object Object]' ? savedLink : profileData?.user_info?.artist_bio_url || null;
  });
  const [showStudioStatementModal, setShowStudioStatementModal] = useState(false);
  const [storedStatementLink, setStoredStatementLink] = useState(() => {
    const savedLink = localStorage.getItem('statementstudioLink');
    return savedLink && savedLink !== '[object Object]' ? savedLink : null;
  });
  const artistName = userDetails?.user?.v_artist_name || `${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}`;

  // Initialize Formik with validation schema

  const initialValues = {
    shortBio: profileData?.user_info?.t_short_bio || '',
    fullStatement: profileData?.user_info?.l_full_artist_statement || '',
    plainText: htmlToPlainText(profileData?.user_info?.l_full_artist_statement),
  };

  // Remove the combined validation schema and create separate ones
  const bioValidationSchema = Yup.object().shape({
    shortBio: Yup.string().required('Short bio is required').max(350, 'Short bio must not exceed 350 characters'),
  });

  const statementValidationSchema = Yup.object().shape({
    fullStatement: Yup.string().required('Full artist statement is required'),
    plainText: Yup.string()
      .required('Full artist statement is required')
      .max(3500, 'Full artist statement must not exceed 3500 characters'),
  });

  const onSubmit = async (values) => {
    try {
      const isBioSubmit = values?.shortBio !== formik.initialValues.shortBio;
      const isStatementSubmit = values?.fullStatement !== formik.initialValues.fullStatement;

      if (isBioSubmit) {
        setLoading({ initial: false, bio: true });
        try {
          await bioValidationSchema.validate({ shortBio: values.shortBio }, { abortEarly: false });

          const response = await AxiosAll(
            'post',
            `updateProfile`,
            { t_short_bio: values.shortBio },
            userDetails?.token,
          );
          const data = await response?.data;
          if (data?.statusCode === 200) {
            notify(data?.message, 'success');
            await getUserProfile();
          } else {
            notify(data?.message, 'error');
          }
        } catch (validationError) {
          formik.setErrors({ shortBio: validationError.errors[0] });
          return;
        }
      }

      if (isStatementSubmit) {
        setLoading({ initial: false, statement: true });
        try {
          await statementValidationSchema.validate(
            { fullStatement: values.fullStatement, plainText: values.plainText },
            { abortEarly: false },
          );

          const response = await AxiosAll(
            'post',
            `updateProfile`,
            { l_full_artist_statement: values.fullStatement },
            userDetails?.token,
          );
          const data = await response?.data;
          if (data?.statusCode === 200) {
            notify(data?.message, 'success');
            await getUserProfile();
          } else {
            notify(data?.message, 'error');
          }
        } catch (validationError) {
          const errors = {};
          if (validationError.inner.length > 0) {
            validationError.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          } else {
            const [errorMessage] = validationError.errors;
            errors.fullStatement = errorMessage;
            errors.plainText = errorMessage;
          }
          formik.setErrors(errors);
          return;
        }
      }
    } catch (error) {
      notify(error?.message, 'error');
    } finally {
      setLoading({ initial: false, bio: false, statement: false, submit: false });
    }
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  const handleStudioBio = async (linkFromModal) => {
    try {
      setLoadingBio(true);
      const response = await AxiosAll(
        'post',
        `artist/generate-artist-bio-open-api`,
        { url: linkFromModal || storedBioLink },
        userDetails?.token,
      );

      const data = await response?.data;

      if (data?.statusCode === 200) {
        // Update profile with bio and URL
        const updateResponse = await AxiosAll(
          'post',
          `updateProfile`,
          {
            artist_bio_url: linkFromModal || storedBioLink,
          },
          userDetails?.token,
        );

        if (updateResponse?.data?.statusCode === 200) {
          await getUserProfile();
        }

        if (linkFromModal) {
          localStorage.setItem('biostudioLink', linkFromModal);
          setStoredBioLink(linkFromModal);
        }
        notify(data?.message, 'success');
        const formattedHTML = formatAIResponseToHTMLBio(data?.data?.currentText);
        setBioContent(formattedHTML);
        formik.setFieldValue('shortBio', formattedHTML);
        setShowBioUndo(true);
        if (data?.data?.previousText) {
          const formattedPrevious = formatAIResponseToHTMLBio(data?.data?.previousText);
          setPreviousBioContent(formattedPrevious);
        }
        setShowStudioModal(false);
      } else {
        notify(data?.message, 'error');
      }
    } catch (error) {
      notify(error?.message, 'error');
    } finally {
      setLoadingBio(false);
    }
  };

  const handleStudioStatement = async (linkFromModal) => {
    try {
      setLoadingState(true);
      const response = await AxiosAll(
        'post',
        `artist/generate-artist-statement-open-api`,
        { url: linkFromModal || storedStatementLink },
        userDetails?.token,
      );
      const data = await response?.data;

      if (data?.statusCode === 200) {
        const updateResponse = await AxiosAll(
          'post',
          `updateProfile`,
          {
            artist_statement_url: linkFromModal || storedStatementLink,
          },
          userDetails?.token,
        );

        if (updateResponse?.data?.statusCode === 200) {
          await getUserProfile();
        }

        if (linkFromModal) {
          localStorage.setItem('statementstudioLink', linkFromModal);
          setStoredStatementLink(linkFromModal);
        }
        notify(data?.message, 'success');
        const formattedHTML = formatAIResponseToHTML(data?.data?.currentText);
        setBioStatement(formattedHTML);
        formik.setFieldValue('fullStatement', formattedHTML);
        formik.setFieldValue('plainText', data?.data?.currentText?.replace(/<[^>]*>/g, ''));
        setShowStatementUndo(true);
        if (data?.data?.previousText) {
          const formattedPrevious = formatAIResponseToHTML(data?.data?.previousText);
          setPreviousStatementContent(formattedPrevious);
        }
      } else {
        notify(data?.message, 'error');
      }
    } catch (error) {
      const errorMessage = error?.data?.message;
      notify(errorMessage, 'error');
    } finally {
      setLoadingState(false);
      setShowStudioStatementModal(false);
    }
  };

  // Update useEffect to set links from profile data
  useEffect(() => {
    if (profileData?.user_info) {
      if (!storedBioLink && profileData.user_info.artist_bio_url) {
        setStoredBioLink(profileData.user_info.artist_bio_url);
      }
      if (!storedStatementLink && profileData.user_info.artist_statement_url) {
        setStoredStatementLink(profileData.user_info.artist_statement_url);
      }
    }
  }, [profileData]);
  const handleUndoBio = () => {
    // Clear the content if previousBioContent doesn't exist
    formik.setFieldValue('shortBio', previousBioContent || '');
    setBioContent(previousBioContent || '');
    setShowBioUndo(false);
  };

  const handleUndoStatement = () => {
    // Clear the content if previousStatementContent doesn't exist
    formik.setFieldValue('fullStatement', previousStatementContent || '');
    formik.setFieldValue('plainText', (previousStatementContent || '').replace(/<[^>]*>/g, ''));
    setShowStatementUndo(false);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validateOnChange: true, // Change this to false
    validateOnBlur: true, // Change this to false
  });

  useEffect(() => {
    setIsDirtyBio(formik.values.shortBio !== formik.initialValues.shortBio);
  }, [formik.values.shortBio, formik.initialValues.shortBio]);

  useEffect(() => {
    const currentStatement = formik.values.fullStatement || '';
    const profileStatement = profileData?.user_info?.l_full_artist_statement || '';

    const isStatementChanged =
      currentStatement.replaceAll(' ', '') !== profileStatement.replaceAll(' ', '') || currentStatement !== '';

    setIsDirtyStatement(isStatementChanged);
  }, [
    formik.values.fullStatement,
    formik.values.plainText,
    formik.initialValues.fullStatement,
    formik.initialValues.plainText,
  ]);
  const handleStudioButtonClick = () => {
    if (!storedBioLink) {
      setShowStudioModal(true);
    } else {
      handleStudioBio();
    }
  };
  const handleStudioStatementButtonClick = () => {
    if (!storedStatementLink) {
      setShowStudioStatementModal(true);
    } else {
      handleStudioStatement();
    }
  };

  const getUserProfile = async (data) => {
    try {
      setLoading({ initial: true, submit: false });

      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      const userData = res.data;
      setProfileData(userData);
    } catch (error) {
      notify(error?.message, 'error');
    } finally {
      setLoading({ initial: false, submit: false });
    }
  };

  const location = useLocation();

  useEffect(() => {
    // Clear localStorage when component mounts
    localStorage.removeItem('biostudioLink');
    localStorage.removeItem('statementstudioLink');

    // Handle tab/window visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        localStorage.removeItem('biostudioLink');
        localStorage.removeItem('statementstudioLink');
      }
    };

    // Handle navigation away from page
    const handleBeforeUnload = () => {
      localStorage.removeItem('biostudioLink');
      localStorage.removeItem('statementstudioLink');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clear localStorage when component unmounts or location changes
      localStorage.removeItem('biostudioLink');
      localStorage.removeItem('statementstudioLink');
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]); // Add location as dependency to trigger on route changes

  const navigateToSellerStatement = () => {
    const url = `${process.env.REACT_APP_USER_APP_URL}artist/statement/${artistName}/${profileData?.user_info?.i_user_id}`;
    window.open(url, '_blank');
  };

  const downloadPDF = async (data) => {
    try {
      globalWindow?.open(`${profileData?.statement_download_url}`, '_blank');
    } catch (error) {
      console.log('error', error);
    }
  };
  const isDisabled = !profileData?.user_info?.t_short_bio || !profileData?.user_info?.l_full_artist_statement;

  const isPaidUser = plan?.plan_type !== 'free';

  return (
    <form onSubmit={formik.handleSubmit} className="statement_dashboard">
      {loading?.initial && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading?.initial}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="font20px artist_statement_text">Artist Statement</div>
      <CardContainer className="gray_theme_update">
        <Stack width="100%" justifyContent="space-around" spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <DarkBtn
            title="Preview"
            onClick={navigateToSellerStatement}
            styleBtn={isDisabled ? 'disable_darkbtn' : ''}
            disabled={isDisabled}
          />
          <DarkBtn
            title="Download PDF"
            onClick={downloadPDF}
            styleBtn={isDisabled ? 'disable_darkbtn' : ''}
            disabled={isDisabled}
          />

          <Share
            url={`${process.env.REACT_APP_USER_APP_URL}artist/statement/${artistName}/${profileData?.user_info?.i_user_id}`}
            title="Share Link"
            onClick={(handleOpen) => {
              if (!profileData?.user_info?.i_user_id) {
                notify(notifyMessages?.completeProfileMsg, 'error');
                return;
              }
              handleOpen();
            }}
          />
        </Stack>
      </CardContainer>
      <div className="font12px short_text">Short Bio (Max 350 characters)</div>
      <Box className=" button_section card-container gray_theme_update">
        <TextAreaBox
          name="shortBio"
          value={formik.values.shortBio}
          onChange={(e) => {
            const { value } = e.target;
            formik.handleChange(e);
            if (value.length > 350) {
              formik.setFieldTouched('shortBio', true, false);
              formik.setFieldError('shortBio', 'Short bio must not exceed 350 characters');
            } else {
              formik.setFieldError('shortBio', undefined);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.shortBio && formik.errors.shortBio}
        />
        {/* // Replace the existing storedBioLink Typography */}
        {storedBioLink && (
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
            <div className="font12px studio_link_text">
              Studio Link:
              <span style={{ color: 'text.secondary', fontWeight: 400, margin: '0 5px' }}>{storedBioLink}</span>
            </div>
            <Button
              size="small"
              onClick={() => {
                setEditingBioLink(true);
                setShowStudioModal(true);
              }}
              disabled={loadingBio}
              className={!isPaidUser ? 'edit_disable' : ''}
              sx={{ minWidth: 'auto', ml: 0, p: 0 }}
            >
              <EditRoundedIcon sx={{ width: '1.1rem' }} />
            </Button>
          </Stack>
        )}

        {/* Bio section */}
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2} mb={2} justifyContent="flex-end">
          <DarkBtn styleBtn={!isDirtyBio ? 'graybtn' : ''} title="Save" type="submit" loading={loading?.bio}>
            {loading?.bio ? 'Submitting...' : 'Submit'}
          </DarkBtn>

          <Stack direction="row" alignItems="center">
            <Button
              sx={{ minWidth: 'auto', p: 0.5 }}
              type="button"
              onClick={handleStudioButtonClick}
              disabled={loadingBio || !isPaidUser}
              className={!isPaidUser ? 'graybtn' : 'darkbtn'}
            >
              {loadingBio ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <img src={images.hat} alt="test" width={26} height={26} />
              )}
              {loadingBio ? 'Generating...' : 'Studio Assistant'}
            </Button>
            {!isPaidUser && (
              <Tooltip
                title="To access the Studio Assistant, you'll need to subscribe to one of our plans."
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ ml: 1, color: 'action.disabled', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Stack>
          {showBioUndo && <DarkBtn title="Undo" type="button" onClick={handleUndoBio} disabled={!bioContent} />}
        </Stack>
      </Box>
      <div className="font12px short_text">Full Artist Statement (Max 3500 characters)</div>
      <Box className="button_section card-container gray_theme_update">
        <Editor
          modules={modules}
          formats={formats}
          theme="snow"
          placeholder="full statement"
          name="fullStatement"
          value={formik.values.fullStatement}
          error={formik.touched.fullStatement && formik.errors.plainText}
          onBlur={() => formik.handleBlur({ target: { name: 'fullStatement' } })}
          onChange={(e, b, _, editor) => {
            const plainText = editor?.getText();
            const trimPlainText = plainText?.trim();
            formik.setFieldTouched('fullStatement', true, false);
            if (Number(trimPlainText?.length) > 3500) {
              formik.setFieldError('plainText', 'Full artist statement must not exceed 3500 characters');
            } else {
              formik.setFieldError('plainText', '');
            }
            formik.setFieldValue('plainText', trimPlainText);
            formik.setFieldValue('fullStatement', e);
          }}
        />

        {/* // Replace the existing storedStatementLink Typography */}
        {storedStatementLink && (
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
            <div className="font12px studio_link_text">
              Studio Link:
              <span style={{ color: 'text.secondary', fontWeight: 400, margin: '0 5px' }}>{storedStatementLink}</span>
            </div>

            <Button
              size="small"
              onClick={() => {
                setEditingStatementLink(true);
                setShowStudioStatementModal(true);
              }}
              disabled={loadingState}
              sx={{ minWidth: 'auto', p: 0.5 }}
              className={!isPaidUser ? 'edit_disable' : ''}
            >
              <EditRoundedIcon sx={{ width: '1.1rem' }} />
            </Button>
          </Stack>
        )}
        {/* Statement section */}
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2} mb={2} justifyContent="flex-end">
          <DarkBtn
            styleBtn={!isDirtyStatement ? 'disable_darkbtn' : ''}
            title="Save"
            type="submit"
            loading={loading?.statement}
          >
            {loading?.statement ? 'Submitting...' : 'Submit'}
          </DarkBtn>

          <Stack direction="row" alignItems="center">
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: !isPaidUser ? '#e0e0e0' : undefined,
                '&:disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'rgba(0, 0, 0, 0.38)',
                },
              }}
              type="button"
              onClick={handleStudioStatementButtonClick}
              disabled={loadingState || !isPaidUser}
              className={!isPaidUser ? 'graybtn' : 'darkbtn'}
            >
              {loadingState ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <img src={images.hat} alt="test" width={26} height={26} />
              )}
              {loadingState ? 'Generating...' : 'Studio Assistant'}
            </Button>
            {!isPaidUser && (
              <Tooltip
                title="To access the Studio Assistant, you'll need to subscribe to one of our plans."
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ ml: 1, color: 'action.disabled', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Stack>
          {showStatementUndo && (
            <DarkBtn
              styleBtn={!bioStatement ? 'graybtn' : ''}
              title="Undo"
              type="button"
              onClick={handleUndoStatement}
              disabled={!bioStatement}
            />
          )}
        </Stack>
      </Box>
      {/* // Replace the existing StudioPopup components */}
      {showStudioModal && (
        <StudioPopup
          open={showStudioModal}
          setOpen={(value) => {
            setShowStudioModal(value);
            setEditingBioLink(false);
          }}
          message="Enter your studio link"
          handleStudio={(link) => handleStudioBio(link)}
          bottomMessage="This link will be saved for future use"
          initialUrl={editingBioLink ? storedBioLink : ''}
          loading={loadingBio}
        />
      )}

      {showStudioStatementModal && (
        <StudioPopup
          open={showStudioStatementModal}
          setOpen={(value) => {
            setShowStudioStatementModal(value);
            setEditingStatementLink(false);
          }}
          message="Enter your studio link"
          handleStudio={(link) => handleStudioStatement(link)}
          bottomMessage="This link will be saved for future use"
          initialUrl={editingStatementLink ? storedStatementLink : ''}
          loading={loadingState}
        />
      )}
      {/* // Add this near the end of your return statement, alongside other popups */}
      {/* {showCvPopup && <CVPopup open={showCvPopup} setOpen={setShowCvPopup} />}  */}
    </form>
  );
};

export default ArtistStatement;
