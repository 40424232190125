/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, FormHelperText, Grid, TextField } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import packageEnv from '../../../../package.json';
import { images } from '../../../assets';
import AxiosAll from '../../../services/axiosAll';
import notify from '../../../utils/notify';
import DarkBtn from '../Buttons/DarkBtn';
import GrayBtn from '../Buttons/GrayBtn';
import AuthDialogs from '../../dialogs/JoinOrSignIn/AuthDialog';

export const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const [socialLinks, setsocialLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { userDetails } = useSelector((state) => state.user);

  const openDialog = () => {
    setOpen(true);
  };
  // Api call for Footer
  const footerApi = async () => {
    try {
      const res = await AxiosAll('get', `getFooterData`);
      const data = res?.data?.data;
      setFooterData(data);
      setsocialLinks(data?.socialLinks);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    footerApi();
  }, []);

  const { values, handleBlur, handleChange, errors, handleSubmit, resetForm } = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Invalid email address',
        ),
    }),
    onSubmit: async (value) => {
      try {
        setLoading(true);
        const data = await AxiosAll('POST', 'weeklyContentInbox', value);
        if (data.status === 200) {
          notify(data?.data?.message, 'success');
          resetForm();
        } else {
          notify(data?.data?.message, 'error');
        }
      } catch (error) {
        notify(error?.message, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="footersection">
      <Container className="all-screen-container">
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid item xs={6} sm={3}>
                <div className="footermenuone">
                  <h5 className="font25px artists_heading">Explore</h5>
                  <ul className="footermenu">
                    <li>
                      <Link className="link" to={`${process.env.REACT_APP_USER_APP_URL}about-us`}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to={`${process.env.REACT_APP_USER_APP_URL}how-it-works`}>
                        How it Works
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to={userDetails?.token ? '/my-subscription' : '/membership'}>
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to={userDetails?.token ? '/support' : '/contact-us'}>
                        Contact Support
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <div className="socialiconfooter">
              <ul>
                {socialLinks?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a target="_blank" href={item?.l_value} rel="noreferrer">
                        {item?.v_name ? (
                          <img
                            alt={item?.v_name.replaceAll(/SOCIAL_|_LINK/g, '')}
                            src={images[item?.v_name.replaceAll(/SOCIAL_|_LINK/g, '')]}
                            className="socialimgfooter"
                          />
                        ) : (
                          <img
                            alt="Social Media"
                            loading="lazy"
                            src={images.socialIconImg}
                            className="socialimgfooter"
                          />
                        )}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="copyrighttext inspiration_text">Join the Cosimo community!</div>
            <Stack direction="row" justifyContent="flex-end">
              {!userDetails?.token && <GrayBtn title="Register Now" onClick={openDialog} />}
              {open && <AuthDialogs open={open} setOpen={setOpen} />}
            </Stack>
          </Grid>
        </Grid>
        <Grid container className="bootomcopy" spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={6}>
            <ul className="bottomlinks">
              {footerData?.otherLinks?.map((item, index) => {
                return (
                  <li key={index}>
                    <Link target="_blank" to={item?.url}>
                      {item?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="copyrighttext">{footerData?.copyRight}</p>
            {packageEnv?.env === 3 ? null : <p className="copyrighttext">{`V${packageEnv.version}`}</p>}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
