import React, { useState } from 'react';
import { IconButton, Tooltip, tooltipClasses } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/system';

// Custom styles for the tooltip
const useStyles = styled((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.grey[800],
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    width: '5vw',
    height: '1.7vw',
    left: '20px !important',
    top: '-15px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F8F8F8',
    color: 'black',
    minWidth: '32.96vw',
    boxShadow: ' 0px 4px 4px 0px #00000040',
    borderRadius: '20px',
    padding: '16px 20px',
  },
}));

const InfoTooltip = ({ heading, portfolio, sales }) => {
  const classes = useStyles();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  // Check if device is touch-capable
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const handleTooltipOpen = () => {
    if (isTouchDevice()) {
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  return (
    <BootstrapTooltip
      title={
        <>
          <div className="font10px" style={{ marginBottom: '10px' }}>
            {heading}
          </div>

          {portfolio ? (
            <>
              <div className="font10px">Essentials (Required to Go Live):</div>
              <ul className="font10px ul_inner_tooltip">
                <li>Create your Cosimo account</li>
                <li>Add a profile picture and banner image</li>
                <li>Write a short bio</li>
              </ul>
              <div className="font10px">Next Steps (For 100% Completion):</div>
              <ul className="font10px ul_inner_tooltip">
                <li>Add one artwork</li>
                <li>Create one collection</li>
                <li>Fill out your CV</li>
                <li>Write a long artist statement</li>
              </ul>
            </>
          ) : sales ? (
            <>
              <div className="font10px" style={{ marginBottom: '5px' }}>
                Steps to Complete Sales Set-Up
              </div>
              <div className="font10px" style={{ marginBottom: '5px' }}>
                <b> Connect with Ryft :</b> <span>Secure your payments by linking your account.</span>
              </div>
              <div className="font10px" style={{ marginBottom: '5px' }}>
                <b> List an Artwork :</b> <span>Add key details such as title, medium, and price.</span>
              </div>
              <div className="font10px" style={{ marginBottom: '5px' }}>
                <b>Organize Shipping :</b> <span>Choose Cosimo’s in-house shipping or set up your own options.</span>
              </div>
              <div className="font10px" style={{ marginBottom: '5px' }}>
                <b>Make Your First Sale :</b>{' '}
                <span>Once everything is set up, you’re ready to start selling and growing your audience.</span>
              </div>
            </>
          ) : (
            <div className="font10px">
              The Cosimo Academy is your go-to resource for building a successful art career. Access expert-led video
              lessons covering marketing, sales, and professional development. Plus, take advantage of our Pricing
              Calculator to confidently price your work and explore the Cosimo Directory, a curated list of suppliers,
              opportunities, and funding options to support your practice..
            </div>
          )}
        </>
      }
      open={isTouchDevice() ? isTooltipOpen : undefined}
      onClose={() => setIsTooltipOpen(false)}
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <IconButton className="info_btn_new_dashboard" onClick={handleTooltipOpen} aria-label="info" size="small">
        <InfoOutlinedIcon />
      </IconButton>
    </BootstrapTooltip>
  );
};

export default InfoTooltip;
