import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import SideBar from '../../ArtLoverDash/SideBar';

const ArtLoverDashMobileMenu = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className="dash-board-sidebar"
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, background: '#1E282C' }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        tabIndex={0}
        role="button"
        style={{ textAlign: 'end', paddingTop: '10px', paddingRight: '10px' }}
        onClick={toggleDrawer(anchor, false)}
      >
        <CloseIcon style={{ color: '#fff' }} />
      </div>
      <SideBar setState={setState} anchor={anchor} />
    </Box>
  );

  return (
    <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>Menu</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="sidebae_mobile_toggle"
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ArtLoverDashMobileMenu;
