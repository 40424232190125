import { FormHelperText } from '@mui/material';

export function replaceNullWithEmptyString(data) {
  if (Array.isArray(data)) {
    // If it's an array, process each element recursively
    return data.map(replaceNullWithEmptyString);
  }
  if (typeof data === 'object' && data !== null) {
    // If it's an object, iterate over its keys
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = value === null ? '' : replaceNullWithEmptyString(value);
      return acc;
    }, {});
  }

  // For non-object and non-array values, return as-is
  return data;
}
export function removeEmptyFields(data) {
  if (Array.isArray(data)) {
    // Clean each element in the array
    return data
      .map((item) => removeEmptyFields(item)) // Recursively clean each object in the array
      .filter((item) => {
        // Keep objects that have at least one non-empty key
        return (
          item &&
          (typeof item !== 'object' ||
            Object.values(item).some((value) => value !== '' && value !== null && value !== undefined))
        );
      })
      .map((item) => {
        // Create a new object excluding the 'isEdit' field
        if (typeof item === 'object' && item !== null) {
          const cleanedItem = { ...item }; // Create a shallow copy of the object
          delete cleanedItem.isEdit; // Remove the 'isEdit' field from the copied object
          return cleanedItem;
        }
        return item; // Return the item as-is if it's not an object
      });
  }
  if (typeof data === 'object' && data !== null) {
    // Recursively clean object
    const cleanedObject = Object.entries(data).reduce((acc, [key, value]) => {
      const cleanedValue = removeEmptyFields(value); // Recursively clean the value
      if (cleanedValue !== undefined && cleanedValue !== null && cleanedValue !== '') {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});

    // Remove 'isEdit' from the cleaned object
    delete cleanedObject.isEdit;

    // Return cleaned object
    return cleanedObject;
  }

  // Return non-object values as-is
  return data;
}
export const getNestedValue = (obj, path) => {
  return path
    .replace(/\[(\d+)]/g, '.$1') // Convert array syntax to dot notation
    .split('.')
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
};
export const ErrorMessage = ({ name = '', errors = {}, touched = {} }) => {
  const error = getNestedValue(errors, name);
  const isTouched = getNestedValue(touched, name);

  return error && isTouched ? <FormHelperText error>{error}</FormHelperText> : null;
};
export function calculateScore(yearsWorked = 0, scoresData = []) {
  const roundedYearsWorked = Math.round(yearsWorked);

  const sortedData = scoresData
    .map((item) => ({
      ...item,
      v_label_name: Number(item?.v_label_name),
    }))
    .sort((a, b) => a.v_label_name - b.v_label_name);

  for (let i = 0; i < sortedData.length; i++) {
    const upperBound = sortedData[i].v_label_name;
    const lowerBound = i === 0 ? 0 : sortedData[i - 1].v_label_name;

    if (roundedYearsWorked >= lowerBound && roundedYearsWorked <= upperBound) {
      return sortedData[i].f_label_value;
    }
  }

  return sortedData[sortedData.length - 1].f_label_value;
}
export function diffInYears(date) {
  const startDate = new Date(date);

  const currentDate = new Date();

  return currentDate.getFullYear() - startDate.getFullYear();
}

export function scrollToElement(selector) {
  try {
    let element = null;

    if (selector.startsWith('#')) {
      // If the selector starts with "#", treat it as an ID
      element = document.getElementById(selector.substring(1));
    } else if (selector.startsWith('.')) {
      // If the selector starts with ".", treat it as a class
      element = document.querySelector(selector);
    } else {
      throw new Error('Invalid selector. Use "#" for ID or "." for class.');
    }

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      throw new Error('Element not found.');
    }
  } catch (error) {
    console.error(error.message);
  }
}
