import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Accordion, AccordionSummary, AccordionDetails, Stack, Typography, Box, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { images } from '../../assets';
import { request } from '../../services/request';
import UnlockCourseDialogs from '../../components/dialogs/UnlockCourseDialogs';
import AxiosAll from '../../services/axiosAll';
import PaymentStatusDialog from '../../components/dialogs/UnlockCourseDialogs/PaymentStatusDialog';
import VideoLibrarySkeleton from '../../components/Skeleton/VideoLibrarySkeleton';
import notify from '../../utils/notify';

// Component for displaying lesson items
const LessonItem = ({ lesson, isPaidPlan, onNavigate }) => {
  const { id, title, completed, accessibility } = lesson;

  const renderLessonActions = () => {
    if (isPaidPlan || accessibility === 'Free') {
      return (
        <>
          <DarkBtn title="View Lesson" onClick={() => onNavigate(`/academy/video-lesson/${id}`)} />
          <img
            src={completed ? images.checkedNew : images.pendingNew}
            alt={completed ? 'Completed' : 'Pending'}
            className="checkbal"
          />
        </>
      );
    }

    return (
      <Tooltip title="Upgrade" placement="top-start">
        <img src={images.lockBlack} alt="Locked" className="lock_black" />
      </Tooltip>
    );
  };

  return (
    <Stack
      direction={{ xs: 'row', sm: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      sx={{ mb: 1.2 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography className="font12pxNew" variant="body1">
        {title}
      </Typography>
      <Stack direction={{ xs: 'row' }} spacing={3} alignItems="center" className="academy_new_btn">
        {renderLessonActions()}
      </Stack>
    </Stack>
  );
};

// Component for displaying module content
const ModuleContent = ({ module, isPaidPlan, onNavigate, onUnlock }) => {
  const { name, lessons = [], accessibility } = module;

  if (isPaidPlan || accessibility === 'Free') {
    return (
      <Accordion className="line-remove">
        <AccordionSummary
          expandIcon={<ArrowBackIosIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font14pxtable overme"
        >
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {lessons.length === 0 ? (
            <Typography>No Lessons Available</Typography>
          ) : (
            lessons.map((lesson, index) => (
              <LessonItem key={index} lesson={lesson} isPaidPlan={isPaidPlan} onNavigate={onNavigate} />
            ))
          )}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box className="box_design">
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className="font14pxtable overme">{name}</Typography>
        <div className="unlock_course_btn_new">
          <DarkBtn title="Unlock Course" onClick={() => onUnlock(module)} />
          <Tooltip title="Upgrade" placement="top-start">
            <img src={images.lockBlack} alt="Locked" className="lock_black" />
          </Tooltip>
        </div>
      </Stack>
    </Box>
  );
};

const AcademyNew = () => {
  const navigate = useNavigate();
  const [modules, setModules] = useState([]);
  const [academyPlan, setAcademyPlan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({ open: false, data: {} });

  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const isPaidPlan = subscriptionDetails?.active_plan?.plan_type === 'paid';

  useEffect(() => {
    const fetchAcademyPlans = async () => {
      try {
        const response = await AxiosAll('get', 'artist/subscriptionPlans?type=academy', '', userDetails.token);
        if (response?.status === 200) {
          setAcademyPlan(response?.data?.data?.academy || []);
        } else {
          notify(response?.data.message, 'error');
        }
      } catch (error) {
        notify(error?.message, 'error');
        console.error('Error fetching academy plans:', error);
      }
    };

    fetchAcademyPlans();
  }, [userDetails.token]);

  useEffect(() => {
    const fetchModules = async () => {
      setLoading(true);
      try {
        const response = await request({
          url: '/artist/academy/modules',
          method: 'GET',
          token: userDetails?.token,
        });
        if (response?.statusCode === 200) {
          setModules(response?.data?.modules || []);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          notify(response?.message, 'error');
        }
      } catch (error) {
        notify(error?.message, 'error');
        console.error('Error fetching modules:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchModules();
  }, [userDetails?.token]);

  const handleUnlock = (moduleData) => {
    setPopup({ open: true, data: moduleData });
  };
  if (loading) {
    return <VideoLibrarySkeleton />;
  }
  return (
    <div className="academy_new_screen">
      <h2 className="mainheadart font20px">Video Library</h2>

      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        {modules?.map((module, index) => (
          <ModuleContent
            key={index}
            module={module}
            isPaidPlan={isPaidPlan || module.module_has_plan === 1}
            onNavigate={navigate}
            onUnlock={handleUnlock}
          />
        ))}
      </div>

      {popup.open && (
        <UnlockCourseDialogs data={academyPlan} open={popup.open} setOpen={setPopup} module={popup.data} />
      )}
      <PaymentStatusDialog />
    </div>
  );
};

export default AcademyNew;
