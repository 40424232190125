import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import SideBar from '../ArtLoverDash/SideBar';
import ArtLoverDashMobileMenu from '../universal/ArtLoverDashMobileMenu';
import { EmailUpdate } from '../dialogs/EmailUpdate';
import { images } from '../../assets';

const SellerSideBar = ({ paddingX }) => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const location = useLocation();
  const navigate = useNavigate();
  const PaddingZero = isMobile && location.pathname.includes('dashboard') ? 0 : paddingX;

  useLayoutEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard', { replace: true });
    }
  }, [location, navigate]);

  return (
    <div className="dashboard">
      <div className="mobileshow dashmenu testst tabshow">
        <ArtLoverDashMobileMenu />
      </div>
      <Container className="dashboard-layout search_result_container ">
        <Grid container spacing={2}>
          <Grid item xs={2.5} className="sidegridcolor mobilehide tabhide">
            <img src={images?.mainLogoWhite} alt="top bar" className="mainlogo" />
            <SideBar />
          </Grid>
          <Grid item xs={8} sm={12} md={9.5} className="mobile-grid">
            <Container
              className={
                location.pathname === '/messages' ? 'all-screen-container message_container' : 'all-screen-container'
              }
              style={{ marginTop: '0', paddingLeft: PaddingZero, paddingRight: PaddingZero }}
            >
              <Outlet />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SellerSideBar;
