import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';

export const ArtworkDetail = ({ item, seller }) => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  return (
    <div className="artwork-detail">
      <div className="single-artwork-detail">
        <img
          src={item?.get_artwork?.artwork_cover_image?.v_image}
          alt={item?.get_artwork?.v_title}
          width={isMobile ? 115 : 93}
          height={isMobile ? 106 : 99}
        />
        <div className="detail">
          <p>{item?.get_artwork?.v_title}</p>
          <p>{item?.get_seller?.v_artist_name || item?.get_seller?.full_name}</p>
          <p>Quantity: {item?.i_qty}</p>
          {item?.is_pod_order === 1 && <p>Size: {item?.artwork_pod_print_size}</p>}
        </div>
      </div>
    </div>
  );
};
