import { SET_MESSAGE_COUNT } from '../actions/messages';

const initialState = {
  unreadCount: 0,
};
// eslint-disable-next-line default-param-last
const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
};

export default messagesReducer;
