import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Typography } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useNavigate } from 'react-router-dom';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { InputShare } from '../universal/InputUrl';
import TruncatedText from '../truncatedText';

export const CollectionTable = ({
  artworks,
  collections,
  tableAlignment,
  allowedAccess,
  selected,
  handleSelect,
  dataMap,
}) => {
  const navigate = useNavigate();
  return (
    <div style={{ width: '100%' }}>
      {dataMap?.length ? (
        <TableContainer className="main-table gray_theme_update">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align={tableAlignment} className="font14pxtable">
                  {}
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Collection Title
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Visibility
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  No. Available
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Thumbnail
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Share
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  {}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataMap?.map((item, index) => {
                const boolenState = dataMap?.status == 'Deactive';
                return (
                  <TableRow key={index}>
                    <TableCell align={tableAlignment}>
                      {artworks && allowedAccess ? null : (
                        <Checkbox checked={selected.includes(item?.id)} onChange={() => handleSelect(item)} />
                      )}
                    </TableCell>
                    <TableCell align={tableAlignment} className="font14pxtable">
                      <TruncatedText text={item?.title} maxLength={18} />
                    </TableCell>
                    <TableCell align={tableAlignment} className="font14pxtable">
                      {item?.status == 'Deactive' ? 'Draft' : 'Public'}
                    </TableCell>
                    <TableCell align={tableAlignment} className="font14pxtable">
                      {item?.artworks_count}
                    </TableCell>
                    <TableCell align={tableAlignment} className="font14pxtable">
                      <img src={item?.image} alt="test" />
                    </TableCell>
                    <TableCell align={tableAlignment}>
                      <TableCell
                        align={tableAlignment}
                        className="font14pxtable"
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <InputShare
                          title="test"
                          url={`${process.env.REACT_APP_USER_APP_URL}artist/${item?.slug}/${item?.user_id}/collection/${item?.id}`}
                          status={item?.status === 'Deactive'}
                          erroMsg="Only publicly visible works can be shared"
                        />
                      </TableCell>
                    </TableCell>
                    <TableCell align={tableAlignment}>
                      <DarkBtn title="Edit" onClick={() => navigate(`/portfolio/collections/${item?.id}`)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            width: '100%',
            padding: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            borderRadius: 10,
            boxShadow: '3px 5px 10px rgba(0,0,0, 0.2)',
          }}
        >
          <Typography className="empty-text">No Collection Found...</Typography>
        </div>
      )}
    </div>
  );
};
