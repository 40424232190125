import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Button } from '@mui/material';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { AccordionLink } from '../universal/Accordion';
import { images } from '../../assets';
import { SideOption } from '../../core/constant';
import { deleteUser } from '../../redux/actions/user';
import { deleteSubscription } from '../../redux/actions/subscription';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { setMessageCount } from '../../redux/actions/messages';

const SideBar = ({ setState, anchor }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const [cardList, setCardList] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isHeight = isMobile ? 'inherit' : 60;
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const { userDetails } = useSelector((state) => state.user);
  const messageCount = useSelector((state) => state.messages.unreadCount); // Get message count from Redux
  const dispatch = useDispatch();

  const getCards = async () => {
    try {
      const res = await AxiosAll('get', 'artist/paymentMethods', '', userDetails.token);
      const data = res?.data?.data;
      setCardList(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  const getMessagesCount = async () => {
    try {
      const res = await AxiosAll('get', 'chatUnreadMessagesCount?loggedAs=seller', '', userDetails.token);
      const data = res?.data?.data?.unreadCount;
      dispatch(setMessageCount(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCards();
    getMessagesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = () => {
    navigate('/login');
    dispatch(deleteUser());
    dispatch(deleteSubscription());
    localStorage.removeItem('biostudioLink');
    localStorage.removeItem('statementstudioLink');
    const impersonating = localStorage.getItem('impersonating');
    if (impersonating) {
      localStorage.removeItem('impersonating');
    }
  };
  return (
    <div className="sidebar">
      <ul className="sidemenubar">
        {SideOption(subscriptionDetails, cardList)?.map((option, index) => {
          return option?.type === 'accordion' ? (
            <AccordionLink
              key={index}
              option={option}
              setState={setState}
              anchor={anchor}
              isOpen={openAccordion === index}
              onToggle={() => {
                setOpenAccordion(openAccordion === index ? null : index);
              }}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              className={
                option?.slug === location?.pathname || location.pathname.includes(option?.slug)
                  ? 'activesidebar side-bar-height'
                  : 'side-bar-height'
              }
              key={index}
              onClick={() => {
                navigate(option?.allowed ? option?.slug : '/my-subscription');
                setState && setState((prev) => ({ ...prev, [anchor]: false }));
              }}
              style={{ margin: 0, height: isHeight }}
            >
              {option?.title === 'Messages' && messageCount !== 0 ? (
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  badgeContent={messageCount > 99 ? '99+' : messageCount}
                >
                  <img src={option.img} alt="" className="location_img" />
                </Badge>
              ) : (
                <img src={option.img} alt="" className="location_img" />
              )}

              <Link href>
                {option?.title} {option?.allowed ? '' : <img src="/icons/lock.svg" alt="" width={20} height={26} />}
              </Link>
            </li>
          );
        })}
        <li style={{ width: '100%' }} className="log_outbtn">
          <DarkBtn title=" Logout" onClick={() => logOut()} />
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
