import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { SubcriptionDetails } from '../../components/subscription/SubcriptionDetails';
import { SubcriptionCard } from '../../components/subscription/subcriptionCard';
import { ToggleButtons } from '../../components/universal/SubcriptionToggle';

import AxiosAll from '../../services/axiosAll';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import notify from '../../utils/notify';
import { Loader } from '../../components/universal/Loader';

export const MemberShip = () => {
  const btnTitle = 'Continue';
  const policyTitle = 'Student or Recent Graduate? ';
  const policydetail = 'You May Be Eligible For 50% Off Our Paid Plans';
  const policyLink = 'Find Out More Here';

  const navigate = useNavigate();
  const [subscriptionMonthlyPlans, setSubscriptionMonthlyPlans] = useState([]);
  const [subscriptionYearlyPlans, setSubscriptionYearlyPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const [loadingPlans, setLoadingPlans] = useState(true);
  const [loadingText, setLoadingText] = useState('Subscription Plans are Loading....');
  const [plansType, setPlansType] = useState('yearly');

  const getSubscriptions = async () => {
    setLoadingText('Subscription Plans are Loading....');
    setLoadingPlans(true);
    try {
      const res = await AxiosAll('get', 'getSubscriptionPlans');
      const data = res?.data?.data || {};
      setSubscriptionMonthlyPlans(data?.monthly || []);
      setSubscriptionYearlyPlans(data?.yearly || []);
      setLoading(false);
      setLoadingPlans(false);
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
      setLoadingPlans(false);
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <Container className="mysubscription all-screen-container membership_page">
      <Loader loading={loading || loadingPlans} message={loadingText} />
      {loadingPlans ? (
        'Loading....'
      ) : (
        <>
          <div className="btn-toggle-text-section">
            <Typography variant="h6" className="font20pxtypo become_text">
              Become a Cosimo Pro Member Today!
            </Typography>
            <ToggleButtons setplansType={setPlansType} />
          </div>
          <div className="plans">
            {(plansType === 'monthly' ? subscriptionMonthlyPlans : subscriptionYearlyPlans)?.map((plans) => (
              <SubcriptionCard
                planId={plans?.id}
                planType={plans.plan_type}
                key={plans?.id}
                title={plans?.v_display_name || plans?.v_name}
                mostPopular={plans?.most_popular}
                btnTitle={btnTitle}
                currency={plans?.v_currency}
                actualPrice={plans?.v_amount}
                paraDetail={plans?.l_description}
                stripPrice={plans?.v_sale_amount}
                offerDescription={plans?.l_offer_description}
                type={plans?.v_recurring_interval}
                isOffer={plans?.i_is_special_offer}
                isAcademyPlan={plans.i_is_academy_plan}
                isLoggedIn={false}
              />
            ))}
          </div>
        </>
      )}
    </Container>
  );
};
