import { Backdrop, CircularProgress, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SimpleSelectBoxNew } from '../../components/InputTypeText/SelectBoxNew';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { request } from '../../services/request';

import { updateUser } from '../../redux/actions/user';
import notify from '../../utils/notify';

import ContinuousSlider from '../../components/universal/ProgressBar/circularprogress';
import { calculateScore, diffInYears, replaceNullWithEmptyString, scrollToElement } from '../cvbuilder/helperFunctions';
import PricingCalculator from '../../components/dialogs/PricingCalculator';
import { CVBuilderDropDown } from '../../core/constant';

const PricingTool = () => {
  const navigate = useNavigate();
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const [loading, setLoading] = useState({ initial: false, submit: false });
  const [loadingProfileData, setLoadingProfileData] = useState(false);
  const [CVDetails, setCVDetails] = useState({});
  const [isCVDataEmpty, setIsCVDataEmpty] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const plan = subscriptionDetails?.active_plan;
  const [pricing, SetPricing] = useState(CVBuilderDropDown);
  const { userDetails } = useSelector((state) => state.user);

  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  const [selectedValues, setSelectedValues] = useState({
    size: 0,
    hoursToMake: 0,
    editionType: 0,
    editionSize: 0,
    mainMedium: 0,
  });

  const [errors, setErrors] = useState({
    size: false,
    salesPerYear: false,
    educationLevel: false,
    exhibitionsAt: false,
    hoursToMake: false,
    editionType: false,
    editionSize: false,
    mainMedium: false,
    ftpthobby: false,
    exhibitions: false,
  });

  const getCvBuilderData = async () => {
    try {
      setLoading({ initial: true, submit: false });
      const res = await request({
        url: '/artist/cvBuilder',
        method: 'GET',
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        const cvData = replaceNullWithEmptyString(res?.data);
        setCVDetails(cvData);
        // Check if essential CV fields are empty
        const isEmpty =
          !cvData.howManySalesPerYear ||
          !cvData.educations?.length ||
          !cvData.exhibitions?.length ||
          !cvData.whenDidYouStart ||
          !cvData.areYouAFullTime;
        setIsCVDataEmpty(isEmpty);
      } else {
        notify(res.message, 'error');
      }
    } catch (error) {
      notify(error.message, 'error');

      console.log('🚀 ~ file: CvBuilder.js:16 ~ getCvBuilderData ~ error:', error);
    } finally {
      setLoading({ initial: false, submit: false });
    }
  };

  const handleSelectChange = (name, value) => {
    setSelectedValues((prevState) => {
      const newState = { ...prevState, [name]: value };

      if (name === 'editionType') {
        const editionTypeValue = value?.v_label_name?.toLowerCase();
        if (editionTypeValue === 'open edition') {
          newState.editionSize = { f_label_value: 0.05, v_label_name: '500' };
        } else if (editionTypeValue === 'one of one' || editionTypeValue === 'limited edition') {
          newState.editionSize = { f_label_value: 1, v_label_name: '1' };
        }
      }

      return newState;
    });
  };

  const getPricing = async (data) => {
    try {
      setLoading({ initial: true, submit: false });
      const res = await request({
        url: '/artist/getCalcValues',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      SetPricing(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ initial: false, submit: false });
    }
  };
  const getUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      setProfileData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPricing();
    getCvBuilderData();
    getUserProfile();
  }, []);

  const updateUserProfile = async () => {
    setLoadingProfileData(true);
    const body = { v_use_price_calc: 1 };
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data: body,
        token: userDetails?.token,
      });
      await getUserProfile();
      notify(res?.message, 'success');
      setLoadingProfileData(false);
    } catch (error) {
      setLoadingProfileData(false);
    }
  };

  const handleCalculation = async () => {
    if (profileData?.v_use_price_calc === 1 && profileData?.subscription_detail?.plan_detail?.i_is_academy_plan === 1) {
      setOpen(true);
      return;
    }
    let hasError = false;
    const newErrors = {};

    // Check if any required field is empty
    if (!selectedValues.size) {
      newErrors.size = true;
      hasError = true;
    }
    if (!selectedValues.hoursToMake) {
      newErrors.hoursToMake = true;
      hasError = true;
    }
    if (!selectedValues.editionType) {
      newErrors.editionType = true;
      hasError = true;
    }
    if (!selectedValues.editionSize) {
      newErrors.editionSize = true;
      hasError = true;
    }
    if (!selectedValues.mainMedium) {
      newErrors.mainMedium = true;
      hasError = true;
    }

    // Update errors state with new error values
    setErrors(newErrors);

    // If there are errors, return and do not proceed with calculation
    if (hasError) {
      return;
    }
    const { howManySalesPerYear, educations, exhibitions, areYouAFullTime } = CVDetails;

    // Find the highest education level from educations array
    const highestEducationLevel = educations?.length > 0 ? Math.max(...educations.map((edu) => edu.f_label_value)) : 0;
    const highestExhibitionLevel =
      exhibitions?.length > 0 ? Math.max(...exhibitions.map((exb) => exb.f_label_value)) : 0;
    const yearOfPracticing = Number(CVDetails?.whenDidYouStart?.v_label_name) || 0;

    const salesPerYear = howManySalesPerYear?.f_label_value || 0;
    const educationLevel = highestEducationLevel || 0;
    const exhibitionsLevel = highestExhibitionLevel || 0;
    const exhibitionsAt = calculateScore(yearOfPracticing || 0, pricing?.years_practicing?.values || []);
    const ftpthobby = areYouAFullTime?.f_label_value || 0;
    // If no errors, proceed with the calculation
    const { editionType, hoursToMake, editionSize, mainMedium, size } = selectedValues;

    const editionTypeData = editionType?.f_label_value;
    const hoursToMakeData = hoursToMake?.f_label_value;
    const editionSizeData = editionSize?.f_label_value;
    const mainMediumData = mainMedium?.f_label_value;
    const sizeData = size?.f_label_value;

    const calculation =
      sizeData *
      educationLevel *
      hoursToMakeData *
      salesPerYear *
      editionTypeData *
      editionSizeData *
      mainMediumData *
      exhibitionsLevel *
      ftpthobby *
      exhibitionsAt;
    console.log({
      sizeData,
      educationLevel,
      hoursToMakeData,
      salesPerYear,
      editionTypeData,
      editionSizeData,
      mainMediumData,
      exhibitionsLevel,
      ftpthobby,
      yearOfPracticing: exhibitionsAt,
    }); // to verify the calculation
    // Do something with the calculation result
    const Minvalue = calculation * 0.9;
    const Maxvalue = Minvalue * 1.2;

    const MinroundedValue = Math.round(Minvalue / 25) * 25;
    const MaXroundedValue = Math.round(Maxvalue / 25) * 25;

    setMax(MaXroundedValue);
    setMin(MinroundedValue);
    scrollToElement('#uniqueSection');
    await updateUserProfile();
  };

  return (
    <div className="pricing-tool-section">
      {loading?.initial && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading?.initial}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        className="my-account-new"
      >
        <h2 className="font25px maintitle">Pricing Calculator</h2>
      </Stack>

      <div className="profilesection">
        <Typography variant="body1" className="font20pxtypo" sx={{ mb: { xs: 1, sm: 3 } }}>
          Tell Us About Your Artwork...
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: 'center' }} className="grid_property_hide">
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Size of Artwork:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.size_cmsq?.values}
                defaultOption="Size of Artwork"
                defaultValue={0}
                value={selectedValues?.size}
                onChange={(e) => {
                  handleSelectChange('size', e.target.value);
                }}
                error={errors?.size}
                helperText={selectedValues?.size === 0 ? 'Please select a  Size of Artwork' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Hours to Make:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <ContinuousSlider
                data={pricing?.hours_to_make?.values || []}
                onChange={(e) => handleSelectChange('hoursToMake', e)}
              />
              {errors?.editionSize && (
                <FormHelperText error className="error_text_updated">
                  {selectedValues?.hoursToMake === 0 ? 'Please select a Hours to Make ' : ''}
                </FormHelperText>
              )}
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: 3.5 }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Edition Type:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3.5 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.edition_type?.values}
                defaultOption="  Edition Type"
                defaultValue={0}
                value={selectedValues?.editionType}
                onChange={(e) => {
                  handleSelectChange('editionType', e.target.value);
                }}
                error={errors?.editionType}
                helperText={selectedValues?.editionType === 0 ? 'Please select a Edition Type ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Edition Size:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <ContinuousSlider
                data={pricing?.edition_size?.values || []}
                onChange={(e) => handleSelectChange('editionSize', e)}
                fixedValue={
                  selectedValues?.editionType?.v_label_name?.toLowerCase() === 'open edition'
                    ? 500
                    : selectedValues?.editionType?.v_label_name?.toLowerCase() === 'one of one'
                    ? 1
                    : selectedValues?.editionSize?.v_label_name
                    ? parseInt(selectedValues?.editionSize?.v_label_name, 10)
                    : undefined
                }
                disabled={
                  selectedValues?.editionType?.v_label_name?.toLowerCase() === 'open edition' ||
                  selectedValues?.editionType?.v_label_name?.toLowerCase() === 'one of one'
                }
              />
              {errors?.editionSize && (
                <FormHelperText error className="error_text_updated">
                  {selectedValues?.editionSize === 0 ? 'Please select a Edition Size ' : ''}
                </FormHelperText>
              )}
            </div>
          </Grid>

          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Main Medium:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.medium?.values}
                defaultOption="  Main Medium"
                defaultValue={0}
                value={selectedValues?.mainMedium}
                onChange={(e) => {
                  handleSelectChange('mainMedium', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.mainMedium}
                helperText={selectedValues?.mainMedium === 0 ? 'Please select a Main Medium ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }} className="mobilehide" />

          <Grid xs={12} sm={8} sx={{ mb: { xs: 3, sm: 3 } }} className="btnlast-section">
            {plan?.v_pricing_calculator !== 'No' ? (
              <>
                <DarkBtn title="Calculate" type="submit" onClick={handleCalculation} disabled={isCVDataEmpty} />
                {isCVDataEmpty && (
                  <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    Please fill in the required fields on the CV Builder page to access the pricing calculator.
                  </Typography>
                )}
              </>
            ) : (
              <DarkBtn
                title="Upgrade plan"
                onClick={() => {
                  navigate('/my-subscription');
                }}
              />
            )}

            {open && <PricingCalculator open={open} setOpen={setOpen} loading={loading} />}
          </Grid>
        </Grid>
      </div>

      <div className="profilesection" id="uniqueSection">
        <Typography
          variant="body1"
          className="font20pxtypo lastpara_text"
          sx={{ mb: { xs: 2, sm: 3 }, textAlign: 'center' }}
        >
          Based on what you’ve told us we believe that a fair price range for your work would be...
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: 'center' }} className="grid_property_hide">
          <Grid item xs={12} sm={1.5} sx={{ mb: { xs: 0, sm: 3 } }}>
            <Typography variant="body1" className="font30pxtypo colorheadblack">
              Min:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="max-min-text">
              {min !== null ? (
                <Typography variant="h4" className="35px">
                  £{min !== 0 && min > 50 ? min : 35}
                </Typography>
              ) : (
                <Typography variant="h4" className="35px">
                  £0
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={1.5} sx={{ mb: { xs: 0, sm: 3 } }}>
            <Typography variant="body1" className="font30pxtypo colorheadblack">
              Max:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ mb: { xs: 4, sm: 3 } }}>
            <div className="max-min-text">
              {max !== null ? (
                <Typography variant="h4" className="35px">
                  £{max !== 0 && max > 85 ? max : 85}
                </Typography>
              ) : (
                <Typography variant="h4" className="35px">
                  £0
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Typography variant="body2" className="font14px lastpara_text" sx={{ textAlign: 'center' }}>
          Remember, this is just a guide. Your work may be worth significantly more, or less than this. We’d always
          encourage artists to sell their work for as much as they’re able to. However, our data suggests that this
          would be a good starting point for you if you are currently unsure of how to price your work.
        </Typography>
      </div>
    </div>
  );
};

export default PricingTool;
