import { Button, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useNavigate } from 'react-router';
import { formatDate } from '../../../hooks/formatDate';
import DarkBtn from '../Buttons/DarkBtn';
import { NoDataContainer } from '../NoDataContainer';

const CustomTable = ({
  order,
  isEmpty,
  rows,
  headCells,
  referralLog,
  creditBalanceLog,
  hideCreditBalance,
  isShowHeading,
  heading,
  routePath,
  isButton,
  buttonLabel,
  isDetailPage,
  invoiceText,
  balanceText,
  imgInfo,
  styleName,
}) => {
  const navigate = useNavigate();

  const convertCreditBalance = (amount) => {
    if (amount < 0) {
      return `£${Math.abs(amount)?.toFixed(2)}`;
    }
    return `-£${amount?.toFixed(2)}`;
  };

  return (
    <div className="order-history">
      <div className="top-heading">
        {isShowHeading && (
          <Typography variant="h3" className="font25px">
            {heading || 'Table Heading'}
          </Typography>
        )}
        {!hideCreditBalance && (
          <div className="only-invoice">
            <Typography variant="h5">{invoiceText}</Typography>
            <Button className="infobtn">
              <Tooltip title="Credit Balance will be adjusted in your future invoices" placement="top">
                <img src={imgInfo} alt="" />
              </Tooltip>
            </Button>
            <Typography variant="h4">: £ {balanceText?.toFixed(2)}</Typography>
          </div>
        )}
      </div>
      {!isEmpty ? (
        <TableContainer className={`order-table ${styleName}`}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="order-head">
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell key={index} align="center">
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="order-body">
              {rows?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {row?.v_order_item_id
                        ? row?.v_order_item_id
                        : row?.v_amount_paid || row?.v_amount_paid == '0'
                        ? `£${row?.v_amount_paid?.toFixed(2)}`
                        : row?.f_credit_balance || row?.f_credit_balance == '0'
                        ? `£${row?.f_credit_balance?.toFixed(2)}`
                        : convertCreditBalance(row?.f_stripe_amount)}
                    </TableCell>
                    {isDetailPage && <TableCell align="center">1</TableCell>}
                    <TableCell align="center">
                      {row?.f_order_total || row?.f_order_total == '0'
                        ? `£${row?.f_order_total?.toFixed(2)}`
                        : row?.v_billing_reason
                        ? row?.v_billing_reason?.replace('_', ' ')
                        : row?.v_note
                        ? row?.v_note
                        : row?.v_stripe_description}
                    </TableCell>

                    {!referralLog && creditBalanceLog && (
                      <TableCell align="center">{row?.v_stripe_type?.replace(/_/g, ' ')}</TableCell>
                    )}
                    {referralLog && !creditBalanceLog && <TableCell align="center">{row?.v_referral_code}</TableCell>}
                    <TableCell align="center">{formatDate(row?.created_at)}</TableCell>
                    {!referralLog && !creditBalanceLog && (
                      <TableCell align="center">
                        {order
                          ? row?.v_order_status === 'Processing'
                            ? row?.v_order_accepted
                            : row?.v_order_status === 'Dispatched' && row?.is_in_person_delivery == '1'
                            ? 'Ready To Collect'
                            : row?.v_order_status === 'Completed' && row?.is_in_person_delivery == '1'
                            ? 'Collected'
                            : row?.v_order_status
                          : row?.v_status}
                        {row?.is_pod_order === 1 && <span className="on-demand-text"> (On Demand)</span>}
                      </TableCell>
                    )}
                    {isButton && (
                      <TableCell align="center" className="btn-detail">
                        <DarkBtn
                          title={buttonLabel}
                          onClick={() => {
                            row?.v_invoice_pdf
                              ? window?.open(row?.v_invoice_pdf, '_self')
                              : navigate(`${routePath}${row?.id}`);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataContainer cardHeight="auto" msgText="No data" className="col-flex gray_theme_update" />
      )}
    </div>
  );
};

export default CustomTable;
