/* eslint-disable react/function-component-definition */
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { LoginSocialApple, LoginSocialGoogle } from 'reactjs-social-login';
import * as Yup from 'yup';
import { images } from '../../../assets';
import { globalWindow, notifyMessages } from '../../../core/constant';
import { setSubscription } from '../../../redux/actions/subscription';
import { setUser } from '../../../redux/actions/user';
import AxiosAll from '../../../services/axiosAll';
import notify from '../../../utils/notify';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import TextFieldInput from '../../universal/InputTypeText/TextField';
import { Loader } from '../../universal/Loader';
// import { addLogin } from '../../redux/actions/login';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(globalWindow?.location?.search);
  const isImpersonate = params.get('isImpersonate');
  const tokenQuery = params.get('token');
  const keyQuery = params.get('key');
  const showSubscription = params.get('showSubscription');
  const [loading, setLoading] = useState(false);
  const [impersonating, setImpersonating] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(true);
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState(null);
  const location = useLocation();

  const REDIRECT_URI = `${process.env.REACT_APP_SELLER_APP_URL}login`;

  const { userDetails } = useSelector((state) => state.user);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      // check if email domain is valid
      .matches(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address',
      )
      .required('Email is required')
      .trim(),
    password: Yup.string().required('Password is required.').min(8, 'Password must be at least 8 characters'),
  });

  const login = async (values, impersonate) => {
    setLoading(true);
    const res = await AxiosAll('post', 'login', values);
    if (res?.data?.statusCode === 200) {
      impersonate && localStorage.setItem('impersonating', true);
      notify(notifyMessages?.loginSuccess, 'success');
      formik.resetForm();
      dispatch(
        setUser({
          token: res?.data?.data?.token,
          user: res?.data?.data?.user,
        }),
      );
      getCurrentSubscription(res?.data?.data?.token);
      setImpersonating(false);
      if (res?.data?.data?.user?.is_skip_seller === 0 && res?.data?.data?.user?.is_completed_seller === 0) {
        navigate(`/personalize?showSubscription=${showSubscription || location?.state?.isFromMembership ? 1 : 0}`);
      } else if (location?.state?.isFromMembership || showSubscription === '1') {
        navigate('/my-subscription');
      } else {
        navigate('/dashboard');
      }
      setLoading(false);
    } else {
      // notify('You have entered an incorrect Email or Password', 'error');
      notify(res?.data?.message, 'error');
      setImpersonating(false);
      setLoading(false);
    }
  };

  const getCurrentSubscription = async (token) => {
    const res = await AxiosAll('GET', 'artist/artist_current_active_subscription_plan_detail', null, token);
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
    }
  };

  const onSubmit = async (values) => {
    const data = {
      email: values?.email.trim(),
      password: values?.password,
    };
    login(data);
  };

  const socialLogin = async (data) => {
    const res = await AxiosAll('post', 'socialLogin', data);

    if (res?.data?.statusCode === 200) {
      notify(notifyMessages?.loginSuccess, 'success');
      dispatch(
        setUser({
          token: res?.data?.data?.token,
          user: res?.data?.data?.user,
          social_type: data?.social_type,
        }),
      );

      if (res?.data?.data?.user?.skip === 0) {
        setProfile(null);
        setProvider('');
        navigate(`/personalize?showSubscription=${showSubscription || location?.state?.isFromMembership ? 1 : 0}`);
      } else if (location?.state?.isFromMembership || showSubscription === '1') {
        navigate('/my-subscription');
      } else {
        navigate('/');
      }
    } else {
      notify(res.error, 'error');
    }
  };

  const handleImpersonate = React.useCallback(() => {
    if (isImpersonate === 'true' && tokenQuery && keyQuery) {
      setImpersonating(true);
      const data = {
        token: tokenQuery,
        key: keyQuery,
      };
      login(data, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenQuery, isImpersonate, keyQuery]);

  useEffect(() => {
    if (!userDetails?.token && provider === 'google') {
      const googleData = {
        v_fname: profile.given_name,
        v_lname: profile.family_name,
        v_email: profile.email,
        social_id: profile.sub,
        social_type: provider,
        e_type: 'seller',
      };
      socialLogin(googleData);
    } else if (!userDetails?.token && provider === 'apple') {
      const idTokenParts = profile?.authorization?.id_token?.split('.');
      const idTokenPayload = JSON.parse(atob(idTokenParts[1]));
      const userId = idTokenPayload?.sub;
      const appleData = {
        v_fname: profile?.user?.name?.firstName,
        v_lname: profile?.user?.name?.lastName,
        v_email: profile?.user?.email,
        social_id: userId,
        social_type: provider,
        e_type: 'seller',
      };
      socialLogin(appleData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.token, provider, profile]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    globalWindow?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    handleImpersonate();
  }, [handleImpersonate]);

  return (
    <>
      <Loader loading={impersonating} message="Logging you in..." />
      <div className="signuptab">
        <form onSubmit={formik?.handleSubmit}>
          <TextFieldInput
            placeholder="Email"
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextFieldInput
            password
            placeholder="Password"
            name="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={isPasswordShow ? 'password' : 'text'}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            isPasswordShow={isPasswordShow}
            setIsPasswordShow={setIsPasswordShow}
          />
          <DarkBtn loading={loading} title="Log In" type="submit" />
          <p className="ortext">or</p>
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_GOOGLE_APP_ID || ''}
            scope="openid profile email"
            redirect_uri={`${REDIRECT_URI}`}
            onResolve={(resolver) => {
              setProvider(resolver?.provider);
              setProfile(resolver?.data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            {/* <GoogleLoginButton /> */}
            <div className="whitebtn">
              <img src={images.google} alt="search icon" className="google-icon" />
              Continue with Google
            </div>
          </LoginSocialGoogle>
          <LoginSocialApple
            client_id={process.env.REACT_APP_APPLE_ID || ''}
            scope="name email"
            redirect_uri={`${REDIRECT_URI}`}
            onResolve={(resolver) => {
              setProvider(resolver?.provider);
              setProfile(resolver?.data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            {/* <AppleLoginButton /> */}
            <div className="whitebtn">
              <img src={images.apple} alt="search icon" className="google-icon" />
              Continue with Apple
            </div>
          </LoginSocialApple>
          <p className="ortext forgot-text">
            <a href="/forgot-password">Forgotten Password?</a>
          </p>
        </form>
      </div>
    </>
  );
}
