import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ArtworkTooltip = ({ heading, portfolio, sales }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const handleTooltipOpen = () => {
    if (isTouchDevice()) {
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  return (
    <Tooltip
      className="tool_tip_cosimo"
      title="This feature is unfortunately  not yet available to artists in your region."
      open={isTouchDevice() ? isTooltipOpen : undefined}
      onClose={() => setIsTooltipOpen(false)}
      arrow
    >
      <IconButton className="info_btn_new_dashboard" onClick={handleTooltipOpen} aria-label="info" size="small">
        <InfoOutlinedIcon fontSize="small" sx={{ ml: 0.5 }} />
      </IconButton>
    </Tooltip>
  );
};

export default ArtworkTooltip;
