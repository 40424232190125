import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, FormGroup, Tooltip, Typography, Radio } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { Stack, width } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SelectBoxLabel } from '../InputTypeText/SelectBoxLabel';
import { UpgradePlanContainer } from '../universal/BlurContainer';
import WhiteBtn from '../universal/Buttons/whiteBtn';
import { CardContainer } from '../universal/CardContainer';
import TextFieldInputLabel from '../universal/InputTypeText/TextFieldLabel';
import sweatAlertBox from '../universal/SweetAlertBox';
import OutlineBtn from '../universal/Buttons/OutLineBtn';
import ArtworkTooltip from './ArtworkToolTip';
import MultipleSelectBox from '../universal/InputTypeText/MultiSelect';
import { request } from '../../services/request';
import notify from '../../utils/notify';

const AVAILABILITY_TYPES = {
  NOT_FOR_SALE: 1,
  OPEN_FOR_ENQUIRIES: 2,
  FOR_SALE_COSIMO: 3,
  FOR_SALE_ELSEWHERE: 4,
  SOLD: 5,
};

const AvailabilitySection = ({ index, formik, size }) => {
  const handleAvailabilityChange = (type) => (event) => {
    if (event.target.checked) {
      // Set the new availability type
      formik.setFieldValue(`artworkSize[${index}].availability_type`, type);

      // Always reset openForEnquiries when changing availability type
      // Specifically disable for SOLD and NOT_FOR_SALE
      if (type === AVAILABILITY_TYPES.SOLD || type === AVAILABILITY_TYPES.NOT_FOR_SALE) {
        formik.setFieldValue(`artworkSize[${index}].openForEnquiries`, false);
        formik.setFieldValue(`artworkSize[${index}].open_for_enquiries`, false);
      }

      // Handle quantity for SOLD status
      if (type === AVAILABILITY_TYPES.SOLD) {
        formik.setFieldValue(`artworkSize[${index}].quantityAvailable`, 0);
      }

      // Reset external link when changing from FOR_SALE_ELSEWHERE
      if (type !== AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE) {
        formik.setFieldValue(`artworkSize[${index}].external_sale_link`, '');
        formik.setFieldTouched(`artworkSize[${index}].external_sale_link`, false);
      }
    }
  };

  const handleEnquiryChange = (event) => {
    const isChecked = event.target.checked;

    // Always update the openForEnquiries field
    formik.setFieldValue(`artworkSize[${index}].openForEnquiries`, isChecked);
    formik.setFieldValue(`artworkSize[${index}].open_for_enquiries`, isChecked); // Update API field too

    // If checking and no other availability type is selected
    if (isChecked && !size.availability_type) {
      formik.setFieldValue(`artworkSize[${index}].availability_type`, AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES);
    }
    // If unchecking and only Open for Enquiries was selected
    else if (!isChecked && size.availability_type === AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES) {
      formik.setFieldValue(`artworkSize[${index}].availability_type`, '');
    }
  };

  const currentType = size.availability_type;

  // Determine if Open for Enquiries should be disabled
  const isEnquiryDisabled = currentType === AVAILABILITY_TYPES.SOLD || currentType === AVAILABILITY_TYPES.NOT_FOR_SALE;

  // If no availability type is selected, enquiry should be enabled
  const showEnquiryEnabled = !currentType;

  // Add useEffect for scrolling
  useEffect(() => {
    if (
      (formik.touched?.artworkSize?.[index]?.availability_type || formik.submitCount > 0) &&
      formik.errors?.artworkSize?.[index]?.availability_type
    ) {
      document.getElementById(`availability-section-${index}`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [
    formik.errors?.artworkSize?.[index]?.availability_type,
    formik.touched?.artworkSize?.[index]?.availability_type,
    formik.submitCount,
    index,
  ]);

  return (
    <div className="availability-section" id={`availability-section-${index}`}>
      <Typography variant="subtitle1" className="font20pxtypo">
        Availability
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              checked={currentType === AVAILABILITY_TYPES.NOT_FOR_SALE}
              onChange={handleAvailabilityChange(AVAILABILITY_TYPES.NOT_FOR_SALE)}
            />
          }
          label="Not for Sale"
        />

        <FormControlLabel
          control={
            <Radio
              checked={currentType === AVAILABILITY_TYPES.FOR_SALE_COSIMO}
              onChange={handleAvailabilityChange(AVAILABILITY_TYPES.FOR_SALE_COSIMO)}
            />
          }
          label="For Sale on Cosimo"
        />

        <FormControlLabel
          control={
            <Radio
              checked={currentType === AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE}
              onChange={handleAvailabilityChange(AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE)}
            />
          }
          label="For Sale Elsewhere"
        />

        <FormControlLabel
          control={
            <Radio
              checked={currentType === AVAILABILITY_TYPES.SOLD}
              onChange={handleAvailabilityChange(AVAILABILITY_TYPES.SOLD)}
            />
          }
          label="Sold"
        />

        {/* Add error message display */}
        {(formik.touched?.artworkSize?.[index]?.availability_type || formik.submitCount > 0) &&
          formik.errors?.artworkSize?.[index]?.availability_type && (
            <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
              {formik.errors.artworkSize[index].availability_type}
            </Typography>
          )}

        {/* Enquiry checkbox - only enabled for FSC and FSE */}
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(size.openForEnquiries || size.open_for_enquiries)}
              onChange={handleEnquiryChange}
              disabled={isEnquiryDisabled}
            />
          }
          label="Open for Enquiries"
          className={isEnquiryDisabled ? 'disabled-option' : ''}
        />

        {currentType === AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE && (
          <TextFieldInputLabel
            placeholder="External Sale Link *"
            name={`artworkSize[${index}].external_sale_link`}
            value={size.external_sale_link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched?.artworkSize?.[index]?.external_sale_link || formik.submitCount > 0) &&
              formik.errors?.artworkSize?.[index]?.external_sale_link
            }
            helperText={
              (formik.touched?.artworkSize?.[index]?.external_sale_link || formik.submitCount > 0) &&
              formik.errors?.artworkSize?.[index]?.external_sale_link
            }
          />
        )}
      </FormGroup>
    </div>
  );
};

export const AddArtworkSize = React.memo(
  ({
    dropdowns,
    setArtworkSizes,
    artworkSizesRef,
    fetchedData,
    setGoodToGo,
    deletedSizes,
    setDeletedSizes,
    plan,
    userDetails,
    isArtworkDrafed,
  }) => {
    const navigate = useNavigate();

    const initialValues = {
      artworkSize: [
        {
          allow_access: 1,
          height: '',
          width: '',
          len: '',
          editionType: '',
          editionSize: '',
          is_framed: '',
          availability_type: '',
          additional_availability: [],
          external_sale_link: '',
          quantityAvailable: '',
          price: '',
          salePrice: '',
          shippingSize: '',
          openForEnquiries: false,
          shipping_types: [],
          personalised_shipping: [],
        },
      ],
    };

    // Add this helper function to check if a country is already selected
    const isCountryAlreadySelected = (selectedIds, newSelectionId) => {
      // Get the country of the new selection
      const newSelection = personalizedShippingOptions.find((opt) => opt.id === parseInt(newSelectionId, 10));
      const newCountry = newSelection?.v_label_name.split(' - ')[1]; // Get country from "Size - Country - Price" format

      // Check if any existing selection has the same country
      return selectedIds.some((id) => {
        const existingOption = personalizedShippingOptions.find((opt) => opt.id === parseInt(id, 10));
        const existingCountry = existingOption?.v_label_name.split(' - ')[1];
        return existingCountry === newCountry;
      });
    };

    const validationSchema = Yup.object().shape({
      artworkSize: Yup.array().of(
        Yup.object().shape({
          height: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number().min(0.01, 'Height must be greater than 0').required('Height is required'),
          width: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number().min(0.01, 'Width must be greater than 0').required('Width is required'),
          len: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number().min(0.01, 'Length must be greater than 0').required('Length is required'),
          editionType: isArtworkDrafed
            ? Yup.string().nullable().notRequired()
            : Yup.string().required('Edition Type is required'),
          editionSize: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number().required('Edition Size is required'),
          quantityAvailable: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number()
                .min(0, 'Quantity must be greater than or equal to 0')
                .required('Quantity is required')
                .test('quantity', 'Please enter a valid quantity', (value) => Number.isInteger(value)),
          price: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number().when('availability_type', {
                is: AVAILABILITY_TYPES.NOT_FOR_SALE,
                then: () => Yup.number().nullable().notRequired(),
                otherwise: () =>
                  Yup.number().min(5, 'Price must be greater than or equal to 5').required('Price is required'),
              }),

          salePrice: Yup.number()
            .nullable()
            .when('price', (price, schema) => {
              if (isArtworkDrafed) return schema;
              return price
                ? schema.test({
                    test: (salePrice) => {
                      if (!salePrice || salePrice === 0) return true;
                      return salePrice && salePrice >= 5 && salePrice < price;
                    },
                    message: 'sale price must be less than price and greater than or equal to 5',
                  })
                : schema;
            }),
          shippingSize: isArtworkDrafed
            ? Yup.string().nullable().notRequired()
            : Yup.string().required('Shipping Size is required'),

          is_framed: isArtworkDrafed
            ? Yup.string().nullable().notRequired()
            : Yup.string().oneOf(['0', '1'], 'Please select framed status').required('Framed/Unframed is required'),

          availability_type: isArtworkDrafed
            ? Yup.number().nullable().notRequired()
            : Yup.number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .required('Availability type is required')
                .test('valid-availability', 'Invalid availability type', (value, context) => {
                  // Get openForEnquiries from parent using context instead of this
                  const {
                    parent: { openForEnquiries },
                  } = context;

                  // Allow OPEN_FOR_ENQUIRIES as valid when it's the only selection
                  if (value === AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES && openForEnquiries) {
                    return true;
                  }
                  // For other cases, ensure it's a valid type
                  return Object.values(AVAILABILITY_TYPES).includes(value);
                }),

          external_sale_link: isArtworkDrafed
            ? Yup.string().nullable().notRequired()
            : Yup.string().when('availability_type', {
                is: AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE,
                then: () => Yup.string().required('External sale link is required').url('Must be a valid URL'),
                otherwise: () => Yup.string().notRequired(),
              }),

          // shippingSize: Yup.string().required('Shipping Size is required'),
          // is_framed: Yup.string().required('Framed/Unframed is required'),
          // availability_type: Yup.number()
          //   .transform((value) => (isNaN(value) ? undefined : value))
          //   .required('Availability type is required')
          //   .test('valid-availability', 'Invalid availability type', (value, context) => {
          //     // Get openForEnquiries from parent using context instead of this
          //     const {
          //       parent: { openForEnquiries },
          //     } = context;

          //     // Allow OPEN_FOR_ENQUIRIES as valid when it's the only selection
          //     if (value === AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES && openForEnquiries) {
          //       return true;
          //     }
          //     // For other cases, ensure it's a valid type
          //     return Object.values(AVAILABILITY_TYPES).includes(value);
          //   }),
          // external_sale_link: Yup.string().when('availability_type', {
          //   is: AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE,
          //   then: () => Yup.string().required('External sale link is required').url('Must be a valid URL'),
          //   otherwise: () => Yup.string().notRequired(),
          // }),
          additional_availability: Yup.array(),
          openForEnquiries: Yup.boolean(),
          sale_else_where_link: Yup.string(),
          personalised_shipping: isArtworkDrafed
            ? Yup.array().notRequired()
            : Yup.array().test({
                name: 'personalised-shipping-required',
                message: 'At least one shipping option is required when using personalised shipping',
                test(value, context) {
                  const { shipping_types: shippingTypes } = context.parent;
                  if (shippingTypes?.includes('3')) {
                    return value && value.length > 0;
                  }
                  return true;
                },
              }),
          shipping_types: isArtworkDrafed
            ? Yup.array().notRequired()
            : Yup.array()
                .min(1, 'At least one shipping method is required')
                .required('At least one shipping method is required'),
        }),
      ),
    });

    const calculatePrice = useCallback((price) => {
      if (price <= 0) {
        return '0.00';
      }

      let shippingFeePercentage;
      let minimumShippingFee;

      switch (true) {
        case price < 100:
          shippingFeePercentage = 0.15;
          minimumShippingFee = 1.99;
          break;
        case price < 250:
          shippingFeePercentage = 0.12;
          minimumShippingFee = 1.99;
          break;
        case price < 1000:
          shippingFeePercentage = 0.08;
          minimumShippingFee = 1.99;
          break;
        default:
          shippingFeePercentage = 0.05;
          minimumShippingFee = 1.99;
      }

      const shippingFee = price * shippingFeePercentage + minimumShippingFee;

      const finalShippingFee = shippingFee > 126.99 ? 126.99 : shippingFee < 11.65 ? 11.65 : shippingFee;

      // const estimatedPrice = (price + finalShippingFee).toFixed(2);

      return finalShippingFee.toFixed(2);
    }, []);

    const handleRemoveSize = (size, index) => {
      size.id && setDeletedSizes([...deletedSizes, size.id]);
      const newSizes = formik?.values?.artworkSize?.filter((item, i) => item.id !== size.id || i !== index);
      formik?.setFieldValue('artworkSize', newSizes);
      // scroll to previous size
      const element = document.getElementById(`size-${index - 1}`);
      element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    // // Handle shipping method changes

    const isPaidArtist = plan?.plan_type !== 'free'; // Add this check

    // Modify handleShippingMethodChange to prevent personalised selection for free users
    const handleShippingMethodChange = (method, index) => (event) => {
      const isChecked = event.target.checked;

      // Prevent free users from selecting pro shipping methods
      if (method === 'cosimoPro' && !isPaidArtist) {
        notify('This feature is only available for paid artists', 'error');
        return;
      }

      const newShippingTypes = new Set(formik.values.artworkSize[index].shipping_types || []);

      // Define shipping type mapping
      const shippingMapping = {
        inPersonCollection: '1',
        cosimoPro: '2',
        personalised: '3',
      };

      if (!shippingMapping[method]) return; // Exit if method is not recognized

      if (isChecked) {
        newShippingTypes.add(shippingMapping[method]);

        // Enforce exclusive selection rules
        if (method === 'cosimoPro') newShippingTypes.delete('3');
        if (method === 'personalised') newShippingTypes.delete('2');
      } else {
        newShippingTypes.delete(shippingMapping[method]);

        // Clear personalised shipping when unchecking
        if (method === 'personalised') {
          formik.setFieldValue(`artworkSize[${index}].personalised_shipping`, []);
        }
      }

      // Convert Set back to array and update formik
      formik.setFieldValue(`artworkSize[${index}].shipping_types`, Array.from(newShippingTypes));
    };

    const formik = useFormik({
      initialValues,
      validationSchema,

      onSubmit: (values, { setSubmitting }) => {
        // Check if any FOR_SALE_ELSEWHERE items are missing links
        const hasInvalidFSE = values.artworkSize.some(
          (size) => size.availability_type === AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE && !size.external_sale_link,
        );

        if (hasInvalidFSE) {
          setSubmitting(false);
          return; // Prevent form submission
        }

        // Transform artworkSize to include both availability and shipping details
        const transformedSizes = values.artworkSize.map((size) => {
          const baseSize = {
            ...size,
            availability_type:
              size.openForEnquiries && !size.availability_type
                ? [AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES]
                : [size.availability_type],
            sale_else_where_link:
              size.availability_type === AVAILABILITY_TYPES.FOR_SALE_ELSEWHERE ? size.external_sale_link : '',
            shipping_types: size.shipping_types || [], // Ensure shipping_types is included
            personalised_shipping: size.personalised_shipping || [], // Include personalised shipping IDs
          };

          // Add Open for Enquiries as additional type when enabled with other types
          if (size.openForEnquiries && size.availability_type !== AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES) {
            baseSize.availability_type.push(AVAILABILITY_TYPES.OPEN_FOR_ENQUIRIES);
          }

          return baseSize;
        });

        setGoodToGo((prev) => ({ ...prev, sizes: true }));
        setArtworkSizes(transformedSizes);
      },

      validateOnBlur: true,
      validateOnChange: true,
    });

    const error = document?.querySelector('.error_text');

    useEffect(() => {
      error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setGoodToGo((prev) => ({ ...prev, sizes: false }));
    }, [error, setGoodToGo]);

    // First add the helper function
    const replaceNullWithEmptyString = (obj) => {
      // Handle null or undefined input
      if (!obj) return '';

      // Handle arrays
      if (Array.isArray(obj)) {
        return obj.map((item) => replaceNullWithEmptyString(item));
      }

      // Handle non-object types
      if (typeof obj !== 'object') return obj;

      // Handle objects
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = value === null ? '' : typeof value === 'object' ? replaceNullWithEmptyString(value) : value;
        return acc;
      }, {});
    };

    // Then modify the useEffect where you handle fetchedData
    useEffect(() => {
      if (fetchedData?.artworkSize?.length > 0) {
        // First replace all null values with empty strings
        const cleanedArtworkSize = replaceNullWithEmptyString(fetchedData.artworkSize);

        // Then ensure personalised_shipping is an array
        const updatedArtworkSize = cleanedArtworkSize.map((size) => ({
          ...size,
          personalised_shipping: size.personalised_shipping || [],
        }));

        formik.setFieldValue('artworkSize', updatedArtworkSize);
        setArtworkSizes(updatedArtworkSize);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedData]);

    const artworkSizeFields = formik?.values?.artworkSize;
    const countryId = userDetails?.user?.v_country === 201;

    // Add state for shipping options
    const [personalizedShippingOptions, setPersonalizedShippingOptions] = useState([]);

    // Add function to fetch shipping options
    const getPersonaliseShipping = async () => {
      try {
        const res = await request({
          url: '/artist/personalisedShippingPrice',
          method: 'GET',
          token: userDetails?.token,
        });

        // Get list of countries for mapping
        const countryList = await request({
          url: '/dropdowns?type[20]=countries',
          method: 'GET',
          token: userDetails?.token,
        });

        // Transform the data to include both name and country
        const formattedOptions =
          res?.data?.map((item) => {
            const country = countryList?.data?.countries?.find((c) => c.id === item.shipping_country);
            return {
              id: item.id,
              v_label_name: `${item.shipping_name} - ${country?.v_title || ''} - £${item.shipping_price}`,
            };
          }) || [];

        setPersonalizedShippingOptions(formattedOptions);
      } catch (err) {
        notify(err?.message, 'error');
      }
    };

    // Add useEffect to fetch shipping options
    useEffect(() => {
      getPersonaliseShipping();
    }, []);

    // Modify the MultipleSelectBox onChange handler
    const handlePersonalisedShippingChange = (event, index) => {
      const selectedIds = event.target.value;
      const currentValues = formik.values.artworkSize[index].personalised_shipping || [];

      // If adding a new selection
      if (selectedIds.length > currentValues.length) {
        const newId = selectedIds.find((id) => !currentValues.includes(id));
        if (isCountryAlreadySelected(currentValues, newId)) {
          notify('You cannot select multiple shipping options for the same country', 'error');
          return;
        }
      }

      formik.setFieldValue(`artworkSize[${index}].personalised_shipping`, selectedIds);
    };

    return (
      <form onSubmit={formik?.handleSubmit}>
        {artworkSizeFields.map((size, index) => {
          // const price = calculatePrice(size?.price);
          return size?.allow_access ? (
            <>
              <CardContainer className="addartworksize" key={`artworkSize-${index}`}>
                {artworkSizeFields.length > 1 && (
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div style={{ cursor: 'pointer' }}>
                      <CloseIcon onClick={() => handleRemoveSize(size, index)} />
                    </div>
                  </div>
                )}
                <Typography paragraph>Size, Pricing & Shipping</Typography>
                <div className="size" id={`size-${index}`}>
                  <TextFieldInputLabel
                    type="number"
                    // isRequired
                    placeholder="Height * (cm)"
                    name={`artworkSize[${index}].height`}
                    value={size?.height}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    inputProps={{ min: 0.01, step: 0.01 }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    error={
                      formik?.touched?.artworkSize?.[index]?.height &&
                      Boolean(formik?.errors?.artworkSize?.[index]?.height)
                    }
                    helperText={
                      formik?.touched?.artworkSize?.[index]?.height && formik?.errors?.artworkSize?.[index]?.height
                    }
                  />
                  <Typography paragraph>x</Typography>
                  <TextFieldInputLabel
                    type="number"
                    // isRequired
                    placeholder="Width * (cm)"
                    name={`artworkSize[${index}].width`}
                    value={size?.width}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    inputProps={{ min: 0.01, step: 0.01 }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventventDefault();
                      }
                    }}
                    error={
                      formik?.touched?.artworkSize?.[index]?.width &&
                      Boolean(formik?.errors?.artworkSize?.[index]?.width)
                    }
                    helperText={
                      formik?.touched?.artworkSize?.[index]?.width && formik?.errors?.artworkSize?.[index]?.width
                    }
                  />
                  <Typography paragraph>x</Typography>
                  <TextFieldInputLabel
                    type="number"
                    // isRequired
                    placeholder="Length * (cm)"
                    name={`artworkSize[${index}].len`}
                    value={size?.len}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    inputProps={{ min: 0.01, step: 0.01 }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventventDefault();
                      }
                    }}
                    error={
                      formik?.touched?.artworkSize?.[index]?.len && Boolean(formik?.errors?.artworkSize?.[index]?.len)
                    }
                    helperText={formik?.touched?.artworkSize?.[index]?.len && formik?.errors?.artworkSize?.[index]?.len}
                  />
                </div>
                <SelectBoxLabel
                  lableName="Edition Type ? *"
                  className="type-select"
                  // isRequired
                  options={[
                    { id: 1, v_label_name: 'One of One' },
                    { id: 2, v_label_name: 'Limited Edition' },
                    { id: 3, v_label_name: 'Unlimited Edition' },
                  ]}
                  name={`artworkSize[${index}].editionType`}
                  value={size?.editionType}
                  onChange={(e) => {
                    if (e?.target?.value === 1) {
                      formik?.setFieldValue(`artworkSize[${index}].editionSize`, 1);
                      formik?.setFieldTouched(`artworkSize[${index}].editionSize`, false);
                      formik?.setFieldValue(`artworkSize[${index}].quantityAvailable`, 1);
                      formik?.setFieldTouched(`artworkSize[${index}].quantityAvailable`, false);
                    } else if (e?.target?.value === 3) {
                      formik?.setFieldValue(`artworkSize[${index}].editionSize`, 10000);
                      formik?.setFieldTouched(`artworkSize[${index}].editionSize`, false);
                    } else {
                      formik?.setFieldValue(`artworkSize[${index}].editionSize`, '');
                      formik?.setFieldValue(`artworkSize[${index}].quantityAvailable`, '');
                    }
                    formik?.handleChange(e);
                  }}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.artworkSize?.[index]?.editionType &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.editionType)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.editionType &&
                    formik?.errors?.artworkSize?.[index]?.editionType
                  }
                  // defaultOption="Edition Type ?"
                  // defaultValue={0}
                />
                {size?.editionType === 3 ? null : (
                  <TextFieldInputLabel
                    type="number"
                    // isRequired
                    placeholder="Edition Size *"
                    name={`artworkSize[${index}].editionSize`}
                    value={size?.editionSize}
                    disabled={size?.editionType === 1}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    inputProps={{ min: 0, step: 0.01 }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventventDefault();
                      }
                    }}
                    error={
                      formik?.touched?.artworkSize?.[index]?.editionSize &&
                      Boolean(formik?.errors?.artworkSize?.[index]?.editionSize)
                    }
                    helperText={
                      formik?.touched?.artworkSize?.[index]?.editionSize &&
                      formik?.errors?.artworkSize?.[index]?.editionSize
                    }
                  />
                )}

                <SelectBoxLabel
                  lableName="Framed?"
                  className="type-select"
                  options={[
                    { id: '0', v_label_name: 'No' },
                    { id: '1', v_label_name: 'Yes' },
                  ]}
                  name={`artworkSize[${index}].is_framed`}
                  value={size?.is_framed?.toString()}
                  onChange={(e) => {
                    formik.setFieldValue(`artworkSize[${index}].is_framed`, e.target.value);
                  }}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.artworkSize?.[index]?.is_framed &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.is_framed)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.is_framed && formik?.errors?.artworkSize?.[index]?.is_framed
                  }
                />
                <AvailabilitySection index={index} formik={formik} size={size} />

                <TextFieldInputLabel
                  type="number"
                  // isRequired
                  placeholder="Quantity Available *"
                  name={`artworkSize[${index}].quantityAvailable`}
                  value={size?.quantityAvailable}
                  disabled={size?.editionType === 1 || AVAILABILITY_TYPES.SOLD === size?.availability_type}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0, step: 0 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                      event.preventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.quantityAvailable &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.quantityAvailable)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.quantityAvailable &&
                    formik?.errors?.artworkSize?.[index]?.quantityAvailable
                  }
                />
                <TextFieldInputLabel
                  type="number"
                  // isRequired
                  placeholder="Price (GBP £) *"
                  name={`artworkSize[${index}].price`}
                  value={size?.price}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0, step: 0.01 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.price && Boolean(formik?.errors?.artworkSize?.[index]?.price)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.price && formik?.errors?.artworkSize?.[index]?.price
                  }
                />
                <TextFieldInputLabel
                  type="number"
                  // required
                  placeholder="Reduced Price (If Discounted)"
                  name={`artworkSize[${index}].salePrice`}
                  value={size?.salePrice}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0, step: 0.01 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.salePrice &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.salePrice)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.salePrice && formik?.errors?.artworkSize?.[index]?.salePrice
                  }
                />
                {/* <TextFieldInput placeholder="Estimated Price" value={`Shipping Price - ${price}`} disabled /> */}
                <SelectBoxLabel
                  lableName="How Will The Artwork Be Packaged?"
                  className="type-select"
                  // isRequired
                  options={dropdowns?.sizes?.map((item) => ({ id: item?.id, v_label_name: item?.description }))}
                  name={`artworkSize[${index}].shippingSize`}
                  value={size?.shippingSize}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.artworkSize?.[index]?.shippingSize &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.shippingSize)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.shippingSize &&
                    formik?.errors?.artworkSize?.[index]?.shippingSize
                  }
                  // defaultOption="Shipping Size"
                  // defaultValue={0}
                />
                <div className="font20px" style={{ marginBottom: '10px' }}>
                  Select Shipping Method(s)
                </div>
                <FormGroup className="select_shipping">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.artworkSize[index].shipping_types?.includes('1')}
                        onChange={handleShippingMethodChange('inPersonCollection', index)}
                      />
                    }
                    label="This artwork is available for studio pick-up/in person collection"
                  />

                  <FormControlLabel
                    className={!isPaidArtist || !countryId ? 'disable_label' : ''}
                    disabled={!isPaidArtist || !countryId}
                    control={
                      <Checkbox
                        checked={formik.values.artworkSize[index].shipping_types?.includes('2')}
                        onChange={handleShippingMethodChange('cosimoPro', index)}
                        disabled={!isPaidArtist || !countryId} // Only disable Cosimo Pro based on countryId
                      />
                    }
                    label={
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ opacity: !isPaidArtist || !countryId ? 0.6 : 1 }}
                      >
                        Cosimo Pro Delivery (We'll calculate shipping for you)
                        {(!isPaidArtist || !countryId) && <ArtworkTooltip />}
                      </Box>
                    }
                  />

                  <FormControlLabel
                    // className={!isPaidArtist ? 'disable_label' : ''}

                    control={
                      <Checkbox
                        checked={formik.values.artworkSize[index].shipping_types?.includes('3')}
                        onChange={handleShippingMethodChange('personalised', index)}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        Personalised Shipping Options
                        {/* <Tooltip title="This feature is only available for paid artists">
                          <InfoOutlinedIcon style={{ cursor: 'pointer', marginLeft: '5px' }} fontSize="small" />
                        </Tooltip> */}
                      </Box>
                    }
                  />
                </FormGroup>
                {formik.values.artworkSize[index].shipping_types?.includes('3') && (
                  <MultipleSelectBox
                    lableName="Select Option *"
                    className="type-select"
                    options={personalizedShippingOptions}
                    name={`artworkSize[${index}].personalised_shipping`}
                    value={size?.personalised_shipping || []}
                    onChange={(e) => handlePersonalisedShippingChange(e, index)}
                    onBlur={formik?.handleBlur}
                    error={
                      (formik.touched?.artworkSize?.[index]?.personalised_shipping || formik.submitCount > 0) &&
                      formik.values.artworkSize[index].shipping_types?.includes('3') &&
                      (!size?.personalised_shipping?.length ||
                        Boolean(formik.errors?.artworkSize?.[index]?.personalised_shipping))
                    }
                    helperText={
                      ((formik.touched?.artworkSize?.[index]?.personalised_shipping || formik.submitCount > 0) &&
                        formik.values.artworkSize[index].shipping_types?.includes('3') &&
                        !size?.personalised_shipping?.length) ||
                      formik.errors?.artworkSize?.[index]?.personalised_shipping
                        ? 'At least one shipping option is required'
                        : ''
                    }
                  />
                )}
                {formik.touched?.artworkSize?.[index]?.shipping_types &&
                  formik.errors?.artworkSize?.[index]?.shipping_types && (
                    <Typography color="error" variant="caption" display="block">
                      {formik.errors.artworkSize[index].shipping_types}
                    </Typography>
                  )}
              </CardContainer>
              {
                // eslint-disable-next-line no-unsafe-optional-chaining
                plan?.v_list_multiple_sizes === 'Yes' && index === formik?.values?.artworkSize?.length - 1 ? (
                  <Stack direction="row" justifyContent="flex-center" className="author_size_btn">
                    <OutlineBtn
                      styleBtn="btn-class"
                      title="Add Another Size +"
                      onClick={() => {
                        formik?.setFieldValue('artworkSize', [
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          ...formik?.values?.artworkSize,
                          {
                            allow_access: 1,
                            height: '',
                            width: '',
                            len: '',
                            editionType: '',
                            editionSize: '',
                            quantityAvailable: '',
                            price: '',
                            salePrice: '',
                            shippingSize: '',
                            is_framed: '',
                            availability_type: '',
                            additional_availability: [],
                            external_sale_link: '',
                            openForEnquiries: false,
                            shipping_types: [], // Add this field
                          },
                        ]);
                      }}
                    />
                  </Stack>
                ) : (
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  index === formik?.values?.artworkSize?.length - 1 && (
                    <OutlineBtn
                      styleBtn="btn-class"
                      title="Upgrade plan to add more sizes"
                      onClick={() => {
                        sweatAlertBox(
                          'You will lose all the changes you made. Are you sure you want to proceed?',
                          null,
                          () => navigate('/my-subscription'),
                          'Proceed',
                          'Cancel',
                          // eslint-disable-next-line no-sequences
                        );
                      }}
                    />
                  )
                )
              }
            </>
          ) : (
            // <h1>upgrade access to edit size</h1>
            <UpgradePlanContainer
              mainTitle="Upgrade access to edit size"
              btnTitle="Upgrade Now"
              btnNavigation="/my-subscription"
            />
          );
        })}
        <input hidden type="submit" ref={artworkSizesRef} />
      </form>
    );
  },
);
