import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { FormControl, OutlinedInput } from '@mui/material';

// eslint-disable-next-line react/function-component-definition
export default function SearchBar({ value, setValue }) {
  // eslint-disable-next-line no-empty-pattern
  const [] = React.useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl sx={{ width: '100%' }} variant="outlined" className="searchbar_message">
      <OutlinedInput
        placeholder="Search"
        id="outlined-adornment-weight"
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="end">
            <SearchRoundedIcon sx={{ color: '#000000E5', marginRight: '10px' }} />
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
      />
    </FormControl>
  );
}
