import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { images } from '../../assets';
import { updateUser } from '../../redux/actions/user';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import DarkBtn from '../universal/Buttons/DarkBtn';
import SimpleSelectBox from '../universal/InputTypeText/SelectBox';
import ImageCard from '../universal/imageCard';
import WhiteBtn from '../universal/Buttons/whiteBtn';
import { globalWindow } from '../../core/constant';
import CustomDatePicker from '../CustomDatePicker';

const AboutYourself = () => {
  const [dropdowns, setDropdowns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [location, setLocation] = useState();
  const [dob, setDob] = useState('');
  // const [styles, setStyles] = useState(0);
  // const [mediums, setMediums] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [user, setUser] = useState({
    profilePicture: '',
  });
  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profilePictureRef = useRef();

  const params = new URLSearchParams(globalWindow?.location?.search);
  const showSubscription = params.get('showSubscription');

  const getDropdowns = async () => {
    try {
      const res = await AxiosAll('get', 'dropdowns?type[20]=countries');

      const data = res?.data?.data;
      setDropdowns(data);
      setCountries(data?.countries || []);
    } catch (err) {
      console.error('Error fetching dropdowns:', err);
    }
  };
  const getCityByCountry = async () => {
    try {
      const res = await AxiosAll('get', `dropdowns?type[1]=cities&country_id=${countryId}`);

      const data = res?.data?.data;
      setCities(data?.cities || []);
    } catch (err) {
      console.error('Error fetching dropdowns:', err);
    }
  };

  useEffect(() => {
    getCityByCountry();
  }, [countryId]);
  useEffect(() => {
    getDropdowns();
  }, []);
  const updateUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data,
        token: userDetails?.token,
      });
      dispatch(updateUser(res.data));
      notify(res?.message, 'success');
    } catch (err) {
      console.log(err);
    }
  };

  // Calculate maximum date (13 years ago from today)
  const getMaxDate = () => {
    const today = new Date();
    return new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
  };

  const handleDobChange = (date) => {
    const selectedDate = new Date(date);
    const maxDate = getMaxDate();

    if (selectedDate <= maxDate) {
      setDob(date);
      setError((prev) => ({ ...prev, dob: '' }));
    } else {
      setError((prev) => ({ ...prev, dob: 'You must be at least 13 years old' }));
    }
  };

  const submitPersonalizeInfo = async () => {
    if (!countryId || !location || !dob) {
      setError({
        country: !countryId ? 'Country is required' : '',
        location: !location ? 'City is required' : '',
        // styles: !styles ? 'Style is required' : '',
        // mediums: !mediums ? 'Mediums is required' : '',
        dob: !dob ? 'Date of Birth is required' : '',
      });
      return;
    }
    const formData = new FormData();
    if (user?.profilePicture instanceof File) {
      formData.append('v_image', user.profilePicture);
      updateUserProfile(formData);
    }
    setIsLoading(true);
    const body = {
      v_country: countryId,
      v_location: location,
      // v_style: styles,
      // v_medium: mediums,
      date_of_birth: dob,
      user_type: 'seller',
    };

    try {
      const res = await AxiosAll('post', 'signupNextStep', body, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        dispatch(updateUser({ ...userDetails?.user, is_completed_seller: 1, ...res?.data?.data }));
        navigate(showSubscription === '1' ? '/my-subscription' : '/dashboard');
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfilePicURL = () => {
    if (user.profilePicture instanceof File) {
      return URL.createObjectURL(user.profilePicture);
    }
    return user.profilePicture;
  };

  const handleRemoveProfilePic = () => {
    if (user.profilePicture) {
      profilePictureRef.current.value = '';
      setUser((prev) => ({ ...prev, profilePicture: '' }));
    }
  };

  const handleSkip = async () => {
    const res = await AxiosAll(
      'post',
      'signupNextStep',
      {
        skip: 1,
      },
      userDetails?.token,
    );

    if (res?.data?.statusCode === 200) {
      notify(res?.data?.message, 'success');
      navigate(showSubscription === '1' ? '/my-subscription' : '/dashboard');
    } else {
      notify(res?.data?.message, 'error');
    }
  };
  // Add useCallback to the imports at the top

  const handleDateInput = useCallback((inputDate) => {
    // Allow only digits and slashes
    if (!/^[\d/]*$/.test(inputDate)) return null;

    // Remove all slashes for raw digits
    const raw = inputDate.replace(/\D/g, '');

    // Auto-format to DD/MM/YYYY
    let formattedDate = '';
    if (raw.length > 0) {
      formattedDate += raw.substring(0, 2);
    }
    if (raw.length >= 3) {
      formattedDate += `/${raw.substring(2, 4)}`;
    }
    if (raw.length >= 5) {
      formattedDate += `/${raw.substring(4, 8)}`;
    }

    // Prevent excessive digits
    const parts = formattedDate.split('/');
    if ((parts[0] && parts[0].length > 2) || (parts[1] && parts[1].length > 2) || (parts[2] && parts[2].length > 4)) {
      return null;
    }

    // Validate full date if 10 characters
    if (formattedDate.length === 10) {
      const [day, month, year] = formattedDate.split('/');
      const date = new Date(`${year}-${month}-${day}`);
      const maxDate = getMaxDate();

      const isValidDate =
        date.getDate() === parseInt(day, 10) &&
        date.getMonth() === parseInt(month, 10) - 1 &&
        date.getFullYear() === parseInt(year, 10) &&
        date <= maxDate &&
        parseInt(day, 10) >= 1 &&
        parseInt(day, 10) <= 31 &&
        parseInt(month, 10) >= 1 &&
        parseInt(month, 10) <= 12;

      return {
        formattedDate,
        isValid: isValidDate,
      };
    }

    return {
      formattedDate,
      isValid: true,
    };
  }, []); // Empty dependency array since it doesn't depend on any external values

  const handleDateValidation = (result) => {
    if (!result) return;

    if (result.formattedDate.length === 10 && !result.isValid) {
      setError((prev) => ({
        ...prev,
        dob: 'Please enter a valid date. You must be at least 13 years old',
      }));
    } else {
      setDob(result.formattedDate);
      setError((prev) => ({ ...prev, dob: '' }));
    }
  };

  return (
    <div className="aboutyourself">
      <p className="font20px well-text">We’ll use this to create your bio!</p>
      <div className="signuptab aboutsection">
        <div className="font18px nearest-text">Country</div>
        <SimpleSelectBox
          options={countries.map((country) => ({
            id: country.id,
            name: country.v_title,
          }))}
          value={countryId}
          onChange={(e) => {
            setCountryId(e.target.value);
            setLocation('');
            setError({ ...error, country: '' });
          }}
          defaultOption="Pick From List"
          defaultValue={0}
        />
        {error?.country && <p className="error_text">{error?.country}</p>}

        <div className="font18px nearest-text">City/Region</div>
        <SimpleSelectBox
          options={cities.map((city) => ({
            id: city.id,
            name: city.v_title,
          }))}
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
            setError({ ...error, location: '' });
          }}
          defaultOption="Pick From List"
          defaultValue={0}
        />
        {error?.location && <p className="error_text">{error?.location}</p>}

        <div className="font18px nearest-text">Date of Birth</div>

        <input
          type="text"
          placeholder="DD/MM/YYYY"
          value={dob}
          onChange={(e) => handleDateValidation(handleDateInput(e.target.value))}
          maxLength={10}
          className="form-control dateofbirth_input"
        />

        {error?.dob && <p className="error_text">{error?.dob}</p>}

        {/* <div className="font18px nearest-text">Style</div> 
        <SimpleSelectBox
          options={dropdowns?.styles?.map((sty) => {
            return { id: sty?.id, name: sty?.v_name };
          })}
          value={styles}
          onChange={(e) => {
            setStyles(e.target.value);
            if (e.target.value) {
              setError({ ...error, styles: '' });
            }
          }}
          defaultOption="Pick From List"
          defaultValue={0}
        />
        {error?.styles && <p className="error_text">{error?.styles}</p>}
        <div className="font18px nearest-text">Preferred Medium</div>
        <SimpleSelectBox
          options={dropdowns?.mediums?.map((md) => {
            return { id: md?.id, name: md?.v_name };
          })}
          value={mediums}
          onChange={(e) => {
            setMediums(e.target.value);
            if (e.target.value) {
              setError({ ...error, mediums: '' });
            }
          }}
          defaultOption="Pick From List"
          defaultValue={0}
        />
        {error?.mediums && <p className="error_text">{error?.mediums}</p>}
        */}
        <div className="profilesection">
          <div className="profilepic">
            <ImageCard
              image={user?.profilePicture ? handleProfilePicURL() : images.avtarPlaceholder}
              alt="profile pic"
            />
            <input
              hidden
              type="file"
              name="profilePicture"
              ref={profilePictureRef}
              accept="image/x-png ,image/jpeg"
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  profilePicture: e.target.files[0],
                }));
              }}
            />
            {!user?.profilePicture && (
              <div className="changebtn">
                <WhiteBtn
                  title="Add Your Profile Picture"
                  onClick={() => {
                    profilePictureRef.current.click();
                  }}
                />
              </div>
            )}
            {user?.profilePicture instanceof File && <WhiteBtn title="Remove" onClick={handleRemoveProfilePic} />}
          </div>
        </div>

        <DarkBtn title="List Your Work" disabled={loading} onClick={submitPersonalizeInfo} />

        <div className="font20px skiptext">
          <span role="button" tabIndex={0} style={{ cursor: 'pointer' }} onClick={handleSkip}>
            Skip For Now
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutYourself;
