import { Container } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Card, Link } from '@mui/material';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { EmptyChannel } from '../../components/AllMessages/EmptyChannel';
import MsgChannel from '../../components/AllMessages/MsgChannel';
import MsgPreview from '../../components/AllMessages/MsgPreview';
import { SingleMsgCardSkeleton } from '../../components/Skeleton/SingleMsgCardSkeleton';
import { CardContainer } from '../../components/universal/CardContainer';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { globalWindow } from '../../core/constant';
import SearchBar from '../../components/InputTypeText/SearchBar';

const AllMessage = () => {
  const borderStyle = {
    border: '1px solid #EC425B',
  };
  const [allChatsData, setAllChatsData] = useState([]);
  const [search, setSearch] = useState('');
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [sellerMsg, setSellerMsg] = useState([]);
  const [isSellerMsgLoading, setIsSellerMsgLoading] = useState(false);
  const [activeMsgId, setActiveMsgId] = useState(null);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const [showEmptyChannel, setShowEmptyChannel] = useState(false);

  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(globalWindow?.location?.search);
  const msgId = queryParams.get('msgId');

  const getSellerMessages = useCallback(
    async (id) => {
      try {
        setIsSellerMsgLoading(true);
        const res = await AxiosAll('get', `sellerMessages?i_chat_id=${id}`, null, userDetails?.token);
        if (res?.data?.statusCode === 200) {
          setSellerMsg(res?.data?.data);
          setIsSellerMsgLoading(false);
        }
      } catch (error) {
        setIsSellerMsgLoading(false);
      }
    },
    [userDetails?.token],
  );
  const senderType = sellerMsg?.chat?.user?.e_type;
  const updateLatestMessage = (lastMsg) => {
    const updatedData = allChatsData?.map((item) => {
      if (item?.id === lastMsg?.i_chat_id) {
        return {
          ...item,
          last_message: {
            ...item.last_message,
            created_at: lastMsg?.created_at,
            updated_at: lastMsg?.updated_at,
            l_message: lastMsg?.l_message,
            v_subject_title: lastMsg?.v_subject_title,
          },
        };
      }
      return item;
    });
    setAllChatsData(updatedData);
    setActiveMsgId(lastMsg?.i_chat_id);
  };

  const getAllChats = async () => {
    try {
      setIsChatLoading(true);
      const res = await AxiosAll('get', `chats?query=${search}&loggedAs=seller`, null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        setAllChatsData(res?.data?.data);
        if (res?.data?.data.length === 0) {
          setShowEmptyChannel(search === '');
        }
        // setActiveMsgId(res?.data?.data[0]?.id);
        setIsChatLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsChatLoading(false);
    }
  };

  const selectHandler = (id) => {
    if (isMobile) {
      navigate(`/messages/${id}`);
    } else {
      setActiveMsgId(id);
    }
  };

  useEffect(() => {
    if (msgId) {
      const msgIdNumber = Number(msgId);
      setActiveMsgId(msgIdNumber);
      navigate(`/messages`, { replace: true });
    } else if (allChatsData?.length > 0 && !isMobile && !activeMsgId && !msgId) {
      setActiveMsgId(allChatsData[0]?.id);
    }
  }, [activeMsgId, allChatsData, isMobile, msgId]);

  useEffect(() => {
    getAllChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (activeMsgId) {
      getSellerMessages(activeMsgId);
    }
  }, [activeMsgId, getSellerMessages]);

  useEffect(() => {
    const child = document.getElementById('msg-preview-child');
    if (child) {
      child.scrollTo({
        top: child.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [sellerMsg]);

  useEffect(() => {
    // if there enough messages to scroll then enable scroll
    const child = document.getElementById('msg-channel-child');
    if (child) {
      if (child.scrollHeight > child.clientHeight) {
        setScrollEnabled(true);
      } else {
        setScrollEnabled(false);
      }
    }
  }, [allChatsData]);

  return (
    <div className="allmessage">
      <div className="searchbtninput">
        <div className="font20px mobilehide" style={{ marginBottom: '20px' }}>
          Your Messages
        </div>
        <div className="font20px mobileshow">Your Messages</div>
      </div>
      <div className="message-section">
        <div className="message_flex_section">
          <div>
            <Card className="message_gray_background search_bar_section">
              <SearchBar value={search} setValue={setSearch} />
            </Card>
            <Card className="message_gray_background">
              <div
                className="scroll-dir"
                style={{ overflowY: scrollEnabled ? 'scroll' : 'hidden' }}
                id="msg-channel-child"
              >
                {/* need to add condition for overflow-y: auto; when there is no MsgChannel */}
                {isChatLoading ? (
                  <>
                    <SingleMsgCardSkeleton />
                    <SingleMsgCardSkeleton />
                    <SingleMsgCardSkeleton />
                  </>
                ) : allChatsData?.length > 0 ? (
                  <MsgChannel
                    data={allChatsData}
                    activeMsgId={activeMsgId}
                    border={borderStyle}
                    selectHandler={selectHandler}
                    setActiveMsgId={setActiveMsgId}
                  />
                ) : (
                  <EmptyChannel show={showEmptyChannel} />
                )}
              </div>
            </Card>
          </div>
          {!isMobile && sellerMsg?.messages?.length > 0 ? (
            <Card className="message_gray_background send_mesage-side">
              <div className="send-message">
                <div style={{ position: 'relative' }}>
                  <ErrorSharpIcon className="error_icon" />
                  <Card className="verify_user_card">
                    <div className="font12px">
                      {senderType === 'guest'
                        ? 'This user does not have a registered Cosimo account, meaning their inquiry was submitted via the contact form without registration. Please exercise caution when responding, as Cosimo cannot verify their identity or track further communication. Be mindful of potential red flags such as suspicious requests, unsolicited offers, links to unknown websites, requests for personal or financial information, and vague or overly generic inquiries. We recommend keeping conversations on-platform for added security and support. Cosimo is not liable for any issues, disputes, or losses arising from communications conducted outside of our platform. If you are unsure about the validity of this inquiry, please contact our support team for assistance.'
                        : 'While we take steps to verify users, we always recommend staying cautious and mindful of best practices when responding. Look out for things like unexpected requests, links to unfamiliar websites, or requests for sensitive information. Keeping your conversations on-platform helps ensure a secure and supported experience. If you have any questions or concerns about this inquiry, our support team is here to help.'}
                    </div>
                  </Card>
                </div>
                <MsgPreview
                  updateLatestMessage={updateLatestMessage}
                  isLoading={isSellerMsgLoading}
                  data={sellerMsg}
                  senderType={senderType}
                  getSellerMessages={getSellerMessages}
                />
              </div>
            </Card>
          ) : (
            !isMobile && (
              <div className="Empty-msg">
                <img src="/icons/empty-notification.svg" alt="empty-notification" />
                <h3>currently you have no messages</h3>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AllMessage;
