import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { images } from '../../assets';

export const SingleBagdeView = ({ handler, data }) => {
  const BagdeCard = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    border: 1px solid #000;
    border-radius: 12px;
    padding: 12px;
    align-items: center;
  `;

  return (
    <BagdeCard>
      <ArrowBackIcon onClick={handler} />
      <img
        src={data?.receiver?.v_image || images.imagePlaceholder}
        width={42}
        height={42}
        alt=""
        style={{ borderRadius: '50%' }}
      />
      <h3 className="font16px">{data?.receiver?.full_name}</h3>
    </BagdeCard>
  );
};
