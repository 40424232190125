import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { SubcriptionDetails } from '../../components/subscription/SubcriptionDetails';
import { SubcriptionCard } from '../../components/subscription/subcriptionCard';
import { ToggleButtons } from '../../components/universal/SubcriptionToggle';

import AxiosAll from '../../services/axiosAll';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import notify from '../../utils/notify';
import { Loader } from '../../components/universal/Loader';
import { setSubscription } from '../../redux/actions/subscription';
import { openWindow } from '../../hooks/popupFrame';
import { globalWindow } from '../../core/constant';

export const MySubscription = () => {
  const btnTitle = 'Continue';
  const policyTitle = 'Student or Recent Graduate? ';
  const policydetail = 'You May Be Eligible For 50% Off Our Paid Plans';
  const policyLink = 'Find Out More Here';

  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [subscriptionMonthyPlans, setsubscriptionMonthyPlans] = useState({});
  const [subscriptionYearlyPlans, setsubscriptionYearlyPlans] = useState({});
  const [activePlan, setactivePlan] = useState({});
  const [activeSubscription, setactiveSubscription] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [IsCardAdd, setIsCardAdd] = useState(false);
  const [havePaidPlan, sethavePaidPlan] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [FreePLanINOnCurrenttype, setFreePLanINOnCurrenttype] = useState({});
  const [loadingtext, setLoadingtext] = useState('Subscription Plans are Loading....');

  const [plansType, setplansType] = useState('yearly');

  const getSubscriptions = async () => {
    setLoadingtext('Subscription Plans are Loading....');
    setLoadingPlans(true);
    try {
      const res = await AxiosAll('get', 'artist/subscriptionPlans', '', userDetails.token);
      const data = res?.data?.data;
      setsubscriptionMonthyPlans(data?.monthly || []);
      setsubscriptionYearlyPlans(data?.yearly || []);
      setactivePlan(data?.customer_current_subscription?.active_plan);
      setactiveSubscription(data?.customer_current_subscription?.active_subscription);
      setIsCardAdd(res?.data?.data?.customer_payment_method_count > 0);
      setLoading(false);
      setLoadingPlans(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoadingPlans(false);
    }
  };

  useEffect(() => {
    !loading &&
      setFreePLanINOnCurrenttype(
        getObjectWithZeroAmount(plansType === 'monthly' ? subscriptionMonthyPlans : subscriptionYearlyPlans),
      );
    !loading && sethavePaidPlan(activePlan?.plan_type !== 'free');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansType, subscriptionMonthyPlans, subscriptionYearlyPlans]);

  function getObjectWithZeroAmount(data) {
    const filtered = data && data?.filter((item) => item.v_amount === 0);
    if (filtered?.length > 0) {
      return filtered[0];
    }
    return null;
  }
  function isAlreadyPurshased(data) {
    const filtered = data.filter((item) => item.active_subscription !== null && item.v_amount !== 0);
    if (filtered.length > 0) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentSubscription = async () => {
    const res = await AxiosAll('GET', 'artist/artist_current_active_subscription_plan_detail', null, userDetails.token);
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
    }
  };

  const PurchaseSubscription = async (id, type) => {
    setLoadingtext('adding Subscription Plans for you ...');
    setLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        type === 'upgrade' ? 'artist/customerSubscriptionUpgradeOrDowngrade' : 'artist/customerSubscriptions',
        { plan_id: id, redirect_url: globalWindow?.location?.origin },
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        if (res?.data?.data['3dStatus'] && res?.data?.data['3DSecureURL']) {
          window?.open(res?.data?.data['3DSecureURL'], '_self');
          setLoadingtext('Confirming Authorization Status... ');
          setLoading(false);
          // getCurrentSubscription();
          // navigate('/paymentStatus?status=success');
        } else {
          handelPopupLoad();
        }
      } else {
        notify(res?.data?.message, 'error');
        !IsCardAdd && navigate(`/checkout?subscription-plan=${id}&method=false`);
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoading(false);
    }
  };
  const handelPopupLoad = () => {
    getSubscriptions();
    setLoading(false);
    getCurrentSubscription();
    navigate('/my-account/subscription-history');
  };

  const CancelSubscription = async (id, data) => {
    setLoadingtext('Cancelling Your Subscription Plans...');
    setLoading(true);
    if (data?.cancelType === 'immediately') {
      CancelNow(id, data);
    } else {
      CancelLater(id, data);
    }
  };

  const CancelNow = async (id, data) => {
    setLoadingtext('Cancelling Your Subscription Plans...');
    setLoading(true);
    try {
      const payload = {
        feedback: data?.reason,
      };
      const res = await AxiosAll(
        'delete',
        `artist/customerSubscriptions/${activeSubscription?.id}`,
        payload,
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setLoading(false);
        navigate('/my-account/subscription-history');
      } else {
        notify(res?.data?.message, 'error');
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoading(false);
    }
  };
  const Location = useLocation();
  const CancelLater = async (id, data) => {
    setLoadingtext('Cancelling Your Subscription Plans...');
    setLoading(true);
    try {
      const payload = {
        feedback: data?.reason,
        cancel_at_period_end: 1,
      };
      const res = await AxiosAll(
        'put',
        `artist/customerSubscriptions/${activeSubscription?.id}`,
        payload,
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setTimeout(() => {
          handelPopupLoad();
        }, 10000);
      } else {
        notify(res?.data?.message, 'error');
        setLoading(false);
        getSubscriptions();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setLoading(false);
    }
  };

  return (
    <Container
      className={
        Location?.pathname === '/membership'
          ? 'mysubscription all-screen-container  '
          : 'mysubscription my_subscription_new'
      }
    >
      <Loader loading={loading || loadingPlans} message={loadingtext} />
      {loadingPlans ? (
        'Loading....'
      ) : (
        <>
          <div className="btn-toggle-text-section">
            <Typography variant="h6" className="font20pxtypo become_text">
              Become a Cosimo Pro Member Today!
            </Typography>

            <ToggleButtons setplansType={setplansType} />
          </div>
          <div className="plans">
            {(plansType === 'monthly' ? subscriptionMonthyPlans : subscriptionYearlyPlans)?.map((plans, index) => {
              return (
                <SubcriptionCard
                  planId={plans?.id}
                  planType={plans.plan_type}
                  key={plans?.id}
                  title={plans?.v_display_name || plans?.v_name}
                  mostPopular={plans?.most_popular}
                  btnTitle={btnTitle}
                  currency={plans?.v_currency}
                  actualPrice={plans?.v_amount}
                  paraDetail={plans?.l_description}
                  stripPrice={plans?.v_sale_amount}
                  offerDescription={plans?.l_offer_description}
                  type={plans?.v_recurring_interval}
                  isCurrentlyActive={activePlan?.id === plans?.id ? activeSubscription : '' || false}
                  isCancelRequested={activeSubscription}
                  currentPlan={activePlan?.id === plans?.id && activePlan?.plan_type === plans?.plan_type}
                  PurchaseSubscription={PurchaseSubscription}
                  CancelSubscription={CancelSubscription}
                  IsCardAdd={IsCardAdd}
                  alreadyHaveActivePlan={havePaidPlan}
                  isOffer={plans?.i_is_special_offer}
                  isAcademyPlan={plans.i_is_academy_plan}
                />
              );
            })}
          </div>
        </>
      )}{' '}
    </Container>
  );
};
