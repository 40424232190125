import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { Header } from '../components/universal/header';
import ContactUs from '../pages/contact-us';
import CookiePolicy from '../pages/cookie-policy';
import FAQ from '../pages/faqs';
import ForgotPassword from '../pages/forgot-password';
import Login from '../pages/login';
import PrivacyPolicy from '../pages/privacy-policy';
import ResetPassword from '../pages/reset-password';
import ReturnPolicy from '../pages/return-policy';
import Signup from '../pages/signup';
import SiteMap from '../pages/sitemap';
import Terms from '../pages/terms-of-use';
import ViewCertificate from '../pages/view-certificate';
import { MemberShip } from '../pages/member-ship';
import { request } from '../services/request';

const PublicRoutes = (props) => {
  const location = useLocation();
  const [headerData, setHeaderData] = useState({});

  useEffect(() => {
    getHeaderData();
  }, [location?.pathname]);
  const getHeaderData = async () => {
    const res = await request({
      url: '/dropdowns',
      method: 'GET',
      params: {
        type: ['categories'],
      },
    });
    const data = res?.data;
    setHeaderData(data);
    return data;
  };

  return (
    <>
      <Header headerData={headerData} />
      <Routes>
        <Route path="*" element={<Navigate to="/login" replace />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="sitemap" element={<SiteMap />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="certificate/:id" element={<ViewCertificate />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="return-policy" element={<ReturnPolicy />} />
        <Route path="faqs" element={<FAQ />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="membership" element={<MemberShip />} />
      </Routes>{' '}
    </>
  );
};

export default PublicRoutes;
