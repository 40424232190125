import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { GreenBtn } from '../universal/Buttons/GreenBtn';
import TextFieldInput from '../universal/InputTypeText/TextField';

export const TrackingLinkPopup = ({ message, btnTitle, handleDispatch, bottomMessage }) => {
  const formik = useFormik({
    initialValues: {
      trackingLink: '',
    },
    validationSchema: Yup.object({
      trackingLink: Yup.string().url('Please enter a valid URL').nullable(), // Makes the field optional
    }),
  });

  const isFormValid = () => {
    // If there's any content and there are errors, form is invalid
    if (formik.values.trackingLink && formik.errors.trackingLink) {
      return false;
    }
    // If field is empty, form is valid (since it's nullable)
    if (!formik.values.trackingLink) {
      return true;
    }
    // Otherwise check if form is valid
    return formik.isValid;
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    formik.setTouched({ trackingLink: true });

    if (!isFormValid()) {
      return;
    }

    handleDispatch(e, null, null, '0', formik.values.trackingLink);
  };

  return (
    <div className="print-box">
      <div>
        <Typography paragraph className="top-heading" dangerouslySetInnerHTML={{ __html: message }} />
        <Typography paragraph className="description" dangerouslySetInnerHTML={{ __html: bottomMessage }} />
        <div className="dispatch-order">
          <Typography paragraph>Tracking Link:</Typography>
          <TextFieldInput
            type="text"
            className="textfield"
            name="trackingLink"
            value={formik.values.trackingLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter Tracking Link"
            error={formik.touched.trackingLink && Boolean(formik.errors.trackingLink)}
            helperText={
              formik.touched.trackingLink && formik.errors.trackingLink
                ? formik.errors.trackingLink
                : 'Please enter tracking Link'
            }
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <GreenBtn
            title={btnTitle}
            loadingText={btnTitle}
            onClick={(e) => handleButtonClick(e)}
            loading={!isFormValid()}
          />
        </div>
      </div>
    </div>
  );
};
