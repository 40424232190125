import { Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { images } from '../../assets';
import PortfolioProgress from '../../components/CircularProgressbar';
import { EmailUpdate } from '../../components/dialogs/EmailUpdate';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import { ChartCard } from '../../components/universal/ChartCard';
import DashboardCharts from '../../components/universal/DashboardChart';
import SimpleSelectBox from '../../components/universal/InputTypeText/SelectBox';
import ArtNotifications from '../../components/universal/Notification/Notification';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import { deleteSubscription } from '../../redux/actions/subscription';
import { deleteUser } from '../../redux/actions/user';
import AxiosAll from '../../services/axiosAll';
import Share from '../../components/universal/Share';
import { notifyMessages } from '../../core/constant';
import notify from '../../utils/notify';

export const nextStep = (data, skip) => {
  switch (true) {
    case data?.account_detail === 0 && skip !== 'account_detail':
      return '/personalize';
    case data?.tell_us_about_youself === 0 && skip !== 'tell_us_about_youself':
      return '/my-account/bio';
    case data?.list_your_artwork === 0 && skip !== 'list_your_artwork':
      return '/portfolio/artworks';
    case data?.organise_shipping === 0 && skip !== 'organise_shipping':
      return '/sales/shipping';
    case data?.accept_payments === 0 && skip !== 'accept_payments':
      return '/sales/payments';
    case data?.seller_accepted_any_order === 0 && skip !== 'seller_accepted_any_order':
      return '/sales/orders';
    case data?.seller_dispached_any_order === 0 && skip !== 'seller_dispached_any_order':
      return '/sales/orders';
    default:
      return '';
  }
};

const calculatePercentages = (dataObject) => {
  // Calculate portfolio percentage
  const portfolioKeys = Object?.keys(dataObject?.portfolio);
  const portfolioTotal = portfolioKeys?.length;
  const portfolioCompleted = portfolioKeys?.filter((key) => dataObject?.portfolio[key] === 1)?.length;
  const portfolioPercentage = Math?.round((portfolioCompleted / portfolioTotal) * 100);

  // Calculate sales percentage
  const salesKeys = Object?.keys(dataObject?.sales);
  const salesTotal = salesKeys?.length;
  const salesCompleted = salesKeys?.filter((key) => dataObject?.sales[key] === 1)?.length;
  const salesPercentage = Math?.round((salesCompleted / salesTotal) * 100);

  // Calculate academy percentage
  const lessonsAssigned = dataObject.academy?.no_of_lessons_assigned || 0;
  const lessonsCompleted = dataObject.academy?.no_of_lessons_completed || 0;
  const academyPercentage = lessonsAssigned > 0 ? Math.round((lessonsCompleted / lessonsAssigned) * 100) : 0;

  return {
    ...dataObject,
    portfolio: {
      ...dataObject?.portfolio,
      percentage: portfolioPercentage,
    },
    sales: {
      ...dataObject?.sales,
      percentage: salesPercentage,
    },
    academy: {
      ...dataObject?.academy,
      percentage: academyPercentage,
    },
  };
};
const Dashboard = () => {
  const [image, setImage] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };
  const { width } = useWindowSize();
  const [artwork, setArtwork] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [AccountStatuspoint, setAccountStatuspoint] = useState({});
  const [OfferData, setOfferData] = useState({});
  const [chartYear, setChartYear] = useState(0);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [limit, setLimit] = useState(12);
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isShowNotifyPagination = notifications?.data?.length > 0 && notifications?.last_page > 1;
  const chartMonth = useMemo(() => chartData?.range?.map((item) => item?.month), [chartData]);
  const chartValue = useMemo(() => chartData?.range?.map((item) => item?.value), [chartData]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onPageChange = (e, pg) => {
    setPage(pg);
    const child = document.getElementById('notification-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    const child = document.getElementById('notification-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getRoute = (data, skip, steps) => {
    for (const [key, route] of Object.entries(steps)) {
      if (data?.[key] === 0 && skip !== key) {
        return route;
      }
    }
    return '';
  };
  const nextStepPortfolio = (data, skip) => {
    // Define steps in order of priority
    const orderedSteps = [
      { key: 'account_detail', route: '/personalize' },
      { key: 'seller_profile_image', route: '/my-account/settings' },
      { key: 'seller_banner_image', route: '/portfolio/portfolio-settings' },
      { key: 'seller_short_bio', route: '/portfolio/artist-statement' },
      { key: 'list_your_artwork', route: '/portfolio/artworks/add-artwork' },
      { key: 'seller_collection_added', route: '/portfolio/collections/add-collection' },
      { key: 'seller_cv_added', route: '/portfolio/cv-builder' },
      { key: 'seller_long_statement', route: '/portfolio/artist-statement' },
    ];

    // Convert ordered steps to the format expected by getRoute
    const steps = orderedSteps.reduce((acc, step) => {
      acc[step.key] = step.route;
      return acc;
    }, {});

    // Find the first incomplete step in order
    for (const step of orderedSteps) {
      if (data?.[step.key] === 0 && skip !== step.key) {
        navigate(step.route);
        return;
      }
    }

    // Fallback to getRoute if no ordered step is found
    const route = getRoute(data, skip, steps);
    if (route) navigate(route);
  };

  const nextStepAcademy = (data) => {
    if (data?.percentage < 100) {
      navigate('/academy/video-library');
    }
  };

  const nextStepSales = (data, skip) => {
    // Define steps in order of priority
    const orderedSteps = [
      { key: 'accept_payments', route: '/sales/payments' },
      { key: 'list_your_artwork', route: '/portfolio/artworks/add-artwork' },
      { key: 'organise_shipping', route: '/sales/shipping' },
      { key: 'complete_sale', route: '/sales/orders' },
    ];

    // Check in specific order
    for (const step of orderedSteps) {
      if (data?.[step.key] === 0 && skip !== step.key) {
        navigate(step.route);
        return;
      }
    }

    // Convert to format expected by getRoute for fallback
    const steps = orderedSteps.reduce((acc, step) => {
      acc[step.key] = step.route;
      return acc;
    }, {});

    const route = getRoute(data, skip, steps);
    if (route) navigate(route);
  };
  const getArtwork = async () => {
    const res = await AxiosAll('GET', 'artist/popularArtwork?limit=3', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      setArtwork(res?.data?.data);
    }
  };
  const getOffer = async () => {
    setloading(true);
    const res = await AxiosAll('GET', 'artist/mySpecialOffer', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      setOfferData(res?.data?.data);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const getChartData = useCallback(async () => {
    try {
      setIsChartLoading(true);
      const res = await AxiosAll(
        'GET',
        chartYear === 0 ? 'artist/viewsChart' : `artist/viewsChart?year=${chartYear}`,
        null,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        setChartData(data);
        setIsChartLoading(false);
      }
    } catch (error) {
      setIsChartLoading(false);
      console.log(error);
    }
  }, [chartYear, userDetails?.token]);

  const getAccountStatus = async () => {
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      const dataObject = {
        portfolio: {
          account_detail: data?.account_detail || 0,
          list_your_artwork: data?.list_your_artwork || 0,
          seller_short_bio_and_banner_image: (data?.seller_short_bio || 0) && (data?.seller_banner_image || 0),
          seller_cv_added: data?.seller_cv_added || 0,
          seller_long_statement: data?.seller_long_statement || 0,
          seller_collection_added: data?.seller_collection_added || 0,
          seller_profile_image: data?.seller_profile_image || 0,
          seller_short_bio: data?.seller_short_bio || 0,
          seller_banner_image: data?.seller_banner_image || 0,
        },
        sales: {
          list_your_artwork: data?.list_your_artwork || 0,
          organise_shipping: data?.organise_shipping || 0,
          accept_payments: data?.accept_payments || 0,
          complete_sale: (data?.seller_accepted_any_order || 0) && (data?.seller_dispached_any_order || 0),
        },
        academy: {
          no_of_lessons_assigned: data?.no_of_lessons_assigned || 0,
          no_of_lessons_completed: data?.no_of_lessons_completed || 0,
        },
      };
      setAccountStatuspoint(calculatePercentages(dataObject));
    }
  };

  const getNotifications = useCallback(async () => {
    try {
      const res = await AxiosAll(
        'GET',
        `artLoverNotifications?limit=${limit}&page=${page}&user_type=seller`,
        null,
        userDetails?.token,
      );
      setNotifications(res?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
  }, [limit, page, userDetails?.token]);

  const readNotification = async (id) => {
    try {
      const res = await AxiosAll('POST', `artist/readNotification/${id}`, null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        getNotifications();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (plan?.v_analytics_tools === 'Basic' || plan?.v_analytics_tools === 'Advanced') {
      getArtwork();
    }
    getAccountStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan?.v_analytics_tools]);

  useEffect(() => {
    if (plan?.v_analytics_tools === 'Advanced') {
      getChartData();
    }
  }, [getChartData, plan?.v_analytics_tools]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    getOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      queryParams?.get('artist')?.replace('art', '') &&
      queryParams?.get('artist')?.replace('art', '') != userDetails?.user?.id
    ) {
      logOut();
    }
  }, []);
  const logOut = () => {
    navigate('/login');
    dispatch(deleteUser());
    dispatch(deleteSubscription());
    const impersonating = localStorage.getItem('impersonating');
    if (impersonating) {
      localStorage.removeItem('impersonating');
    }
  };

  const checkProfileStatus = (data) => {
    if (
      data?.seller_profile_image === 1 &&
      data?.account_detail === 1 &&
      data?.seller_short_bio_and_banner_image === 1
    ) {
      return (
        <>
          <div className="font14px"> Your portfolio is live!</div>
          <Share
            url={`${process.env.REACT_APP_USER_APP_URL}artist/${
              userDetails?.user?.v_artist_name || `${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}`
            }/${userDetails?.user?.id}`}
            title="Share Your Page"
            className="share_btn"
            onClick={(handleOpen) => {
              if (!userDetails?.user?.id) {
                notify(notifyMessages?.completeProfileMsg, 'error');
                return;
              }
              handleOpen();
            }}
          />
        </>
      );
    }
    return <div className="font14px"> Your profile is currently private. Complete your setup to go live.</div>;
  };

  return (
    <div className="sell-dashboard">
      {userDetails.user?.e_login_type === 'apple' && userDetails.user?.email_apple_verified !== 1 ? (
        <EmailUpdate emailAddress={userDetails.user?.v_email} />
      ) : null}
      <div className="card-container gray_theme_update mainheadart">
        <h2 className="hello_text font40px">Hello {userDetails?.user?.v_fname}!</h2>
        <div className="portfolio_section">{checkProfileStatus(AccountStatuspoint?.portfolio)}</div>
      </div>
      {loading ? null : OfferData?.monthly || OfferData?.yearly ? (
        <CardContainer className="special-offer ">
          <div className="offer-image gray_theme_update">
            <img src={images.celebrateImg} alt="" />
            <p className="font20px color000">
              Don’t miss this special offer... <br /> Upgrade Today and Save £
              {(
                ((OfferData?.monthly && OfferData?.monthly[0]?.v_amount) ||
                  (OfferData?.yearly && OfferData?.yearly[0]?.v_amount) ||
                  0) -
                ((OfferData?.monthly && OfferData?.monthly[0]?.v_sale_amount) ||
                  (OfferData?.yearly && OfferData?.yearly[0]?.v_sale_amount) ||
                  0)
              )?.toFixed(2)}
              !
            </p>
          </div>
          <DarkBtn title="Redeem Now" onClick={() => navigate('/special-offers')} />
        </CardContainer>
      ) : null}

      <CardContainer
        className="step-bar gray_theme_update"
        borderRadius={20}
        paddingX={isMobile ? 16 : 25}
        paddingY={36}
        bottomMargin={isMobile ? 25 : 40}
      >
        <div className="font20px glance_text">Your Profile at a Glance</div>
        <div className="font14px snapshot_para">Here’s a snapshot of your progress so far...</div>
        <div className="flex-row">
          <PortfolioProgress
            portfolio
            para="Complete Set Up"
            heading="Complete Your Portfolio"
            onPress={() => {
              nextStepPortfolio(AccountStatuspoint.portfolio);
            }}
            title="Portfolio"
            percentage={AccountStatuspoint?.portfolio?.percentage || 0}
          />

          <PortfolioProgress
            sales
            para="Complete Set Up"
            title="Sales"
            heading="Set Up Sales with Cosimo"
            onPress={() => nextStepSales(AccountStatuspoint?.sales)}
            percentage={AccountStatuspoint?.sales?.percentage || 0}
          />
          <PortfolioProgress
            para="Explore More"
            heading="Academy"
            title="Academy"
            onPress={() => {
              nextStepAcademy(AccountStatuspoint?.academy);
            }}
            percentage={AccountStatuspoint?.academy?.percentage || 0}
          />
        </div>
      </CardContainer>

      <ArtNotifications
        showMore={isShowNotifyPagination}
        readNotification={readNotification}
        notifications={notifications}
        onShowMore={() => navigate('/dashboard/my-notifications')}
      />

      {plan?.v_analytics_tools === 'Advanced' ? (
        <>
          <div className="seller-graph">
            <div>
              <Typography variant="h5">Your Analytics</Typography>
              <p className="font20px" style={{ margin: 0 }}>
                Track how many people are seeing your page
              </p>
            </div>
            <SimpleSelectBox
              name="pageViewsDropdown"
              options={chartData?.years?.map((item) => {
                return { id: item, name: item };
              })}
              onChange={(e) => setChartYear(e.target.value)}
              value={chartYear || new Date().getFullYear()}
              // defaultOption="Select"
              // defaultValue={0}
            />
          </div>
          {isChartLoading ? (
            <ChartCard borderRadius={20} paddingX={isMobile ? 16 : 41} paddingY={36} bottomMargin="2rem">
              <Skeleton variant="rectangular" width="100%" height={320} />
            </ChartCard>
          ) : (
            <ChartCard borderRadius={20} paddingX={isMobile ? 16 : 41} paddingY={36} bottomMargin="2rem">
              <DashboardCharts
                name="Views per month"
                data={chartValue}
                categories={chartMonth}
                type="line"
                className="testor"
              />
            </ChartCard>
          )}
        </>
      ) : (
        <UpgradePlanContainer
          btnNavigation="/my-subscription"
          btnTitle="Upgrade your plan"
          mainTitle="Upgrade to access page views"
        />
      )}
    </div>
  );
};

export default Dashboard;
